import React, { Component } from "react"

import { connect } from "react-redux"


  // Redux where we import state
  const mapStateToProps = state => {
      return {
        alertPopup: state.alertPopup,
        crl_edit_input: state.crl_edit_input,
        alertOption: state.alertOption,
        domainValidationError: state.domainValidationError
      };
    };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    showAlert: (data) => {
      return { type: "UPDATE_ALERT", alertPopup: data }
    },
    updateAlertConfirm: (data) => {
      return { type: "UPDATE_ALERT_CONFIRM", alertConfirm: data }
    },
    updateCrlEditInput: crl => {
      return { type: 'CRL_EDIT_INPUT', crl_edit_input : crl}
    },
    updateAlertOption: option => {
      return { type: 'UPDATE_ALERT_OPTION', alertOption : option}
    },
    updateDomainError: data => {
      return { type: "NOT_VALID_DOMAIN_ERROR", domainValidationError: data } 
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(

    class Alert extends Component {

      closeAlert = () => {
        this.props.showAlert(false)
        this.props.updateAlertConfirm(false)
      }

      confirmAlert = () => {
        let action = this.props.alertPopup.action
        const crl_input = this.props.crl_edit_input
        const alertOption = this.props.alertOption
        let show = true

        if (alertOption === 'enable') {
          if (!crl_input) {
            show = false
            this.props.updateDomainError(true)
          } 
        }

        if (show) { 
          this.props.showAlert(false)
          this.props.updateAlertConfirm(action)
        }
      }

      handleFormRadio = (val) => {
        this.props.updateAlertOption(val)
      }


      renderForm = () => {
        const action = this.props.alertPopup.action
        const crl_input = this.props.crl_edit_input
        const alertOption = this.props.alertOption
        const error = this.props.domainValidationError
        let res = ''
        let css = 'appearance-none border-2 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 py-2 px-2 rounded w-full mt-0 mb-2'
        if (error) {
          css = css + 'bg-red-200 border-red-500'
        } else {
          css = css + 'bg-gray-200 border-gray-200'
        }
        switch(action) { 

          case 'enable_crl': 
            res = ( 
              <div className="text-left text-xs text-base sm:text-base lg:text-lg py-2 bg-palatte-2 w-full">
                <div className="w-full py-0 px-0 mt-0 mb-0"><input type="radio" id="auto" className="form-radio h-4 w-4 text-green-500 ml-2 my-2" name="enable_option" value="random" checked={alertOption == "random" ? 1 : 0 } onChange={(e) => {}} onClick={(e) => this.handleFormRadio(e.target.value)} /> <label className="text-left md:text-center text-gray-600 text-sm" for="auto">I want to enable crl input with auto generated url</label></div>
                <div className="w-full py-0 px-0 mt-0 mb-0"><input type="radio" id="custom" className="form-radio h-4 w-4 text-green-500 ml-2 my-2" name="enable_option" value="enable" checked={alertOption == "enable" ? 1 : 0 } onChange={(e) => {}} onClick={(e) => this.handleFormRadio(e.target.value)} /> <label className="text-left md:text-center text-gray-600 text-sm" for="custom">I want to use custom crl input</label></div>
                {alertOption == "enable" && <input className={css} type="text" placeholder="Type your crl url here" value={crl_input} onChange={(e) => this.handleCrlInput(e)} />}
              </div> 
            )
          break;

          case 'delete_crl': 
            res = ( 
              <div className="text-left text-xs text-base sm:text-base lg:text-lg py-2 bg-palatte-2 w-full">
                <div className="w-full py-0 px-0 mt-0 mb-0"><input type="radio" id="auto" className="form-radio h-4 w-4 text-green-500 ml-2 my-2" name="disable_option" value="random" checked={alertOption == "random" ? 1 : 0 } onChange={(e) => {}} onClick={(e) => this.handleFormRadio(e.target.value)} /> <label className="text-left md:text-center text-gray-600 text-sm" for="auto">I want to enable crl input with auto generated url</label></div>
                <div className="w-full py-0 px-0 mt-0 mb-0"><input type="radio" id="custom" className="form-radio h-4 w-4 text-green-500 ml-2 my-2" name="disable_option" value="enable" checked={alertOption == "enable" ? 1 : 0 } onChange={(e) => {}} onClick={(e) => this.handleFormRadio(e.target.value)} /> <label className="text-left md:text-center text-gray-600 text-sm" for="custom" >I want to use custom crl input</label></div>
                <div className="w-full py-0 px-0 mt-0 mb-0"><input type="radio" id="disable" className="form-radio h-4 w-4 text-green-500 ml-2 my-2" name="disable_option" value="disable" checked={alertOption == "disable" ? 1 : 0 } onChange={(e) => {}} onClick={(e) => this.handleFormRadio(e.target.value)} /> <label className="text-left md:text-center text-gray-600 text-sm" for="disable">I want to disable crl input</label></div>
                {alertOption == "enable" && <input className={css} type="text" placeholder="Type your crl url here" value={crl_input} onChange={(e) => this.handleCrlInput(e)} />}
              </div> 
            )
          break;

        }
        return res
      }

      handleCrlInput = (e) => {
        e.preventDefault()
        let crl = e.target.value
        //let regexp = /^$|^[a-z,A-Z,0-9,\.\- \_]+$/
        //crl = (regexp.test(crl) && crl.length <= 64) ? crl : false
        //if (crl !== false) {
          this.props.updateCrlEditInput(crl)
        //}
      }

      render() { 
        const alert = this.props.alertPopup
        const form = this.renderForm()
        return (
          <div className="w-full">
          {alert &&  <div className="text-center w-12/12 md:w-8/12 lg:w-5/12 px-8 py-12 absolute m-auto sm:left-2 md:left-0 right-0 z-index-full">
          
          <div className="bg-white rounded shadow-lg border-gray-900 z-index-full">
            <div className="px-3 py-2 text-white bg-palette-5 rounded-tl rounded-tr">
              <p className="text-right w-full text-lg font-bold alert-close"><button onClick={this.closeAlert}><i className="fa fa-circle-xmark cursor-pointer fa-xl"></i></button></p>
              <p className="text-center w-full text-lg font-bold">{alert.header ? alert.header : 'Confirm'}</p>
              
            </div>
            <div className="text-center px-1 md:px-8 py-2 md:py-12 z-index-full">
              {alert.text && <p className="text-left md:text-center text-gray-600 font-bold">{alert.text}</p>}
              {form}
            </div>
            <hr/>
            <div className="text-right px-1 py-1 bg-gray-300 z-index-full">
              <button className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 sm:px-4 rounded mr-1" onClick={this.confirmAlert} role="confirm">Confirm</button>
              <button className="text-xs sm:text-sm transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 sm:px-4 rounded mr-4 my-1" onClick={this.closeAlert} role="decline">Decline</button>
            </div>
          </div>
          
          </div>}
          </div>
        )
             
      }
    
    })
