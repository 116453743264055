import React, { Component } from "react"
import { connect } from "react-redux"
import image5 from "../../images/info.png"
import image3 from "../../images/iconO.png"
import image4 from "../../images/iconX.png"
import { FaArrowRight } from "react-icons/fa"
import Info from "../Info"
import Pagination from "../Pagination"
import image6 from "../../images/noData.jpeg"
import {getHelperIsExpired, getHelpersExpiredData, getHelpersPathCaClick, getHelpersCalculateTags, getHelperDownloadClick} from '../../helpers.js'

var moment = require('moment');


// Redux where we import state
const mapStateToProps = state => {
    return {
      caSelectedTeam: state.caSelectedTeam,
      selectedNestedSubCa: state.selectedNestedSubCa,
      showNestedCa: state.showNestedCa,
      selectedCa: state.selectedCa,
      buttonWindowOnDashboardPage: state.buttonWindowOnDashboardPage,
      idFromSelectedCa: state.idFromSelectedCa,
      tab: state.tab,
      messageWindow: state.messageWindow,
      errorMessage: state.errorMessage,
      showErrorMessageWindow: state.showErrorMessageWindow,
      pathCa: state.pathCa,
      subCaEEId: state.subCaEEId,
      tagEditForm: state.tagEditForm, //show tag edit handler
      inputTags: state.inputTags, //array of tags
      tagName: state.tagName, //count of tags (because of the old database for multiple tags functionality)
      tagsBuf: state.tagsBuf,
      checkboxes: state.checkboxes,
      crl_edit_form: state.crl_edit_form,
      crl_edit_input: state.crl_edit_input,
      default_crl_distribution_point_input: state.default_crl_distribution_point_input,
      spinner: state.spinner,
      alertConfirm: state.alertConfirm,
      pagination: state.pagination,
      page: state.page,
      team: state.team,
      perPage: state.perPage
    };
};

// Redux where we dispatch state
const mapDispatchToProps = {
    updateSelectedNestedSubCa: data => {
        return {type: "SELECTED_NESTED_SUB_CA", selectedNestedSubCa: data}
    },
    updateShowNestedCa: data => {
        return {type: "SHOW_NESTED_CA", showNestedCa: data}
    },
    updateIdFromSelectedCa: data => {
        return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: data }
    },
    updateSelectedCa: filltered_array => {
        return { type: "UPDATE_SELECTED_CA", selectedCa: filltered_array[0] }
    },
    updateCurrentTab: currentTab => {
        return { type: "UPDATE_TAB", tab: currentTab }
    },
    updateButtonWindowOnDashboardPage: data => {
        return { type: "UPDATE_BUTTON_WINDOW_ON_DASHBOARD_PAGE", buttonWindowOnDashboardPage: data }
    },
    updatePathCa: data => {
      return {type: "PATH_CA", pathCa: data}
    },
    cleanPathCa: data => { 
      return {type: "CLEAN_PATH_CA", pathCa: data}
    },
    updateSubCaEEId: data => {
        return { type: "SUB_CA_EE_ID", subCaEEId: data}
    },
    updateTagArray: (data) => { //needed to show the tag edit form with the tags array
      return { type: "UPDATE_TAG_ARRAY", inputTags: data}
    },
    updateTagNumResults: (data) => { //needed to update the tags amount
      return { type: "UPDATE_TAG_RESULTS_NUMBER", tagName: data}
    },
    updateTagsBuf: (data) => {
      return { type: "UPDATE_TAGS_BUF", tagsBuf: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateCheckboxes: data => {
        return { type: "UPDATE_CHECKBOXES", checkboxes: data }
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    },
    updatePagination: pagination => {
      return { type: "UPDATE_PAGINATION", pagination: pagination}
    },
    changePage: page => {
        return { type: "UPDATE_PAGE_NUMBER", page: page }
    },
    restartIdFromSelectedCa: id => {
      return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: id }
    },
    updateCrlEditForm: data => {
      return { type: "CRL_EDIT_FORM", crl_edit_form: data}
    },
  }


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Cas extends Component {

    componentDidMount() {
        let subCaArr = this.props.selectedNestedSubCa;
        if (subCaArr && typeof(subCaArr[0]) != 'undefined') {
            this.props.updateSelectedCa([subCaArr[0]])
            this.props.updateCheckboxes([subCaArr[0].id])
            this.calculateTags(subCaArr[0])
        }
        this.props.updateTagsBuf([])
        this.props.updateCrlEditForm(false)
    }

    fetchSubCas = (request_page, perPage=false, type=false, id=false) => {
        this.props.changeSpinner(true)
        let url = "/admin/cas?page="+request_page
        if (type) {
            url +="&q[ca_type_eq]="+type
        }

        if (id) {
            url += '&q[signed_by_eq]='+id
        }

        if (!perPage) {
            perPage = this.props.perPage
        }

        url += '&per_page='+perPage
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
        })
        .then(res => res.json())
        .then(date => {

            if (typeof(date.pagy) != 'undefined' && typeof(date.cas) != 'undefined') {
            if (id) {
                this.props.restartIdFromSelectedCa(id)
            } 
            
            this.props.updatePagination(date.pagy)
            this.props.changePage(request_page)
            this.props.updateSelectedNestedSubCa(date.cas)
            this.props.updateShowNestedCa(!this.props.showNestedCa)
            this.props.updateButtonWindowOnDashboardPage(false)
            this.props.updateSelectedCa([])
            this.props.updateCheckboxes([])
            this.props.changeSpinner(false)
            }
            
        })
    }

    downloadSelected = () => {
        this.handleDropdownsOut()
        let endEntityArray = this.props.selectedNestedSubCa
        let checkboxes = this.props.checkboxes;
        if (checkboxes.length == 0) {
            checkboxes = [this.props.selectedEndEntity[0].id]
        }
        
        checkboxes.map ( (check) => {
            this.downloadTxtFile(check)
        })
    }

    downloadTxtFile = (cert_id) => {
      if (this.props.selectedCa.length === 0 ){
        this.props.updateNotification({status: 'notify', text: 'If you trying to download root/sub ca you will need to select your ca first in table bellow. Or if you don\'t have any ca please go on create ca first.'})        
      } else {
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/admin/cas/"+cert_id, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf
              }
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.error) {
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'error', text: res.message})
                } else {
                    getHelperDownloadClick(res, '_CA')
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'success', text: 'End Entity downloaded'})
                }
            })
      }
    }

    handleCheckbox = (val) => {
        if (val) {
            
            let checkboxes = this.props.checkboxes
            let first_el = checkboxes[0]
            let checked = checkboxes.filter( ee => ee == val)
            if (checked.length > 0) {
                checked = true
            } else {
                checked = false
            }
            
            let filtered = checkboxes.filter( ee => ee != val)
            if (!checked) {
                filtered.push(val)
            }
            if (filtered.length == 0) {
                this.props.updateNotification({status: 'notify', text: 'At least one certificate should be selected'})
                filtered.push(first_el)
            }
            this.handleRadioButton(filtered[0])
            this.props.updateCheckboxes(filtered) 
            
        }
    }

    handleRadioButton = (selected_ca) => {
        let ca_array = this.props.selectedNestedSubCa
        let filtered_array = ca_array.filter(ca => ca.id == selected_ca)
        this.props.updateSelectedCa(filtered_array)
        this.calculateTags(filtered_array[0])
    }

    calculateTags = (selected_ca) => {
        let tags = getHelpersCalculateTags(selected_ca)
        this.props.updateTagArray(tags) //tags array
        this.props.updateTagNumResults(tags.length) //amount of tags
    }

    handleEETopButton = () => {
        let id = this.props.selectedCa.id
        this.props.updateSubCaEEId(id)
        this.props.history.push("/certificate_manager")
    }

     // This function pickup value and call mapDispatch redux metod  
    handleTabs = (e) => {
        e.preventDefault()
        let currentTab = e.target.value
        this.props.updateCurrentTab(currentTab)
    }

    //This function handle option button 
    handleOptionButton = (event) => {
        event.preventDefault()
        let data = !this.props.buttonWindowOnDashboardPage
        this.props.updateButtonWindowOnDashboardPage(data)
    }

    //This function is handle onBlur and close dropdown
    handleDropdownsOut = () => {
        if (this.props.buttonWindowOnDashboardPage) {
            this.props.updateButtonWindowOnDashboardPage(false)
        }
    }

  
    activateDeactivateStatus = (cert, status) => {
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/admin/cas/"+cert.id, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
          body: JSON.stringify(
            {
             "ca": { 
               account_status: status
             }
          })
        })
        .then(res => {
          if (res.status != 200) {
              this.props.updateNotification({status: 'error', text: 'Certificate status change failed'})
              this.props.changeSpinner(false)
          }
          return res.json()
        })
        .then(res => {
            if (res.error) {
                this.props.updateNotification({status: 'error', text: res.message})
                this.props.changeSpinner(false)
            } else {
                this.props.updateButtonWindowOnDashboardPage(false)
                cert.account_status = status
                this.props.updateSelectedCa([cert])
                this.props.changeSpinner(false)
                this.props.updateNotification({status: 'success', text: 'Certificate status changed'})
            }
        }
        )
      }
  
      // This function show error on page is CA is not selected and also call activate/deactivate fetch function
      handleActivateOption = () => {
        if (this.props.selectedCa.length === 0 ){
            this.props.updateNotification({status: 'notify', text: 'If you trying to active/deactive root/sub ca you will need to select your ca first in table bellow. Or if you don\'t have any ca please go on create ca first.'})
        } else {
            let checkboxes = this.props.checkboxes
            let all_certs = this.props.selectedNestedSubCa
            checkboxes.map( (ca_id, index) => {
                let cert = all_certs.filter(ee => ee.id === ca_id)
                let status = this.props.selectedCa.account_status
                if ( status === true ) {
                    this.activateDeactivateStatus(cert[0], false) // to deactivate
                } else if (status === false) {
                    this.activateDeactivateStatus(cert[0], true) // to activate
                } else {
                    this.props.updateNotification({status: 'notify', text: 'Selected CA is expired. Please create new CA'})
                }
            })
        }
      }

    is_expired(ca) {
        let res = getHelperIsExpired(ca)
        return res
    }

    handleSubCaClick = (root_ca_id) => {
      let ca_array = this.props.selectedNestedSubCa
      let filtered = ca_array.find( ca => ca.ca_api_id === root_ca_id)
      this.props.updatePathCa(filtered)
      this.props.updateIdFromSelectedCa(root_ca_id)
      this.fetchSubCas(1, false, 'subca', root_ca_id)
    }

    handlePathClick = (id ) => {
        let pathCa = this.props.pathCa
        let ind = 99999999999999
        let filtered = getHelpersPathCaClick(pathCa, id)
        this.props.cleanPathCa(filtered)
        this.props.updateIdFromSelectedCa(id)
        this.fetchSubCas(1, false, 'subca', id) 
    }

    fetchCallback = (page, perPage) => {
        let id = this.props.idFromSelectedCa
        let type = 'subca'
        if (id === 1) {
            type = 'rootca'
        }
        this.fetchSubCas(page, perPage, type, id)    
    }
    
        
    render() {  
            const count = this.props.tagName
            const checkboxes = this.props.checkboxes
            const spinner = this.props.spinner
            const idFromSelectedCa = this.props.idFromSelectedCa
            const pathCa = this.props.pathCa
            const messageWindow = this.props.messageWindow
            const selectedCa = this.props.selectedCa
            const buttonWindowOnDashboardPage = this.props.buttonWindowOnDashboardPage
            const selectedNestedSubCa = this.props.selectedNestedSubCa
            const caSelectedTeam = this.props.caSelectedTeam
            const pagination = this.props.pagination
            const page = this.props.page

            let multiple = false;
            if (checkboxes.length > 1) {
                multiple = true;
            }
            
            return (        
                <div className="pt-10 sm:px-2 lg:px-8 pb-4 sm:py-10 h-75 text-center sm:text-left bg-palette-2 w-full h-full" onClick={this.handleDropdownsOut}>
                       
                        {spinner === false &&
                    <div>
                            <div className="flex flex-row mb-4">
                            {pathCa && pathCa.length > 1 && pathCa.map((pathca, index) => (
                                <div key={index} className="flex mx-1 w-full">
                                    {index !== 0 && < FaArrowRight className="pt-2 mx-3"/>}
                                    <p className="cursor-pointer italic w-full text-center md:text-left md:px-8" onClick={() => this.handlePathClick(pathca.ca_api_id)}>{pathca.common_name}</p>
                                </div>
                            )
                            )}
                            </div>
                  
                            {multiple && <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 my-6 mx-10 shadow-md border-palette-5" role="alert"> 
                                    <div className="flex"> 
                                        <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p className="font-bold text-palette-5" >Info</p>
                                                <p className="text-sm">select one checkbox only to see it's detailed information and use more actions for the selected certificate</p>
                                            </div>
                                        </div>
                            </div>}
                            { messageWindow !== false && // This will show hover message on screen if user hover info icon
                                
                                 <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md border-palette-5" role="alert"> 
                                    <div className="flex"> 
                                        <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p className="font-bold text-palette-5" >Info</p>
                                                <p className="text-sm">SSL Private CA—This service is for enterprise customers building a public key infrastructure (PKI) inside the Private PKI app and intended for private use within an organization. With SSL Private CA, you can create your own certificate authority (CA) hierarchy and issue certificates with it for authenticating users, computers, applications, services, servers, and other devices. Certificates issued by a private CA cannot be used on the internet. For more information, see the Private PKI guide.</p>
                                            </div>
                                        </div>
                                  </div>
                            }
                        <div>
                            <div className="relative text-xs sm:text-sm flex w-full"> 
                                <div className='mb-6 ml-4'>
                                {selectedCa && selectedCa.length !== 0 && <button role="options_btn" onClick={this.handleOptionButton} className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mt-4 mx-4">
                                  Options
                                </button>}
                                {idFromSelectedCa && idFromSelectedCa != 1 && <a href="/administration"><button className="transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mt-4 mx-4">Back to Root CAs list</button></a>}
                                </div>
                            </div>
                            <div className="flex w-full">
                            {buttonWindowOnDashboardPage !== false &&
                            <div className={!multiple ? "z-index dropdown-options dropdown-options-subca-admin bg-palette-4 ml-60 mt-14 rounded" : "z-index dropdown-options dropdown-options-multiple bg-palette-4 ml-60 mt-14 rounded"}>
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 hover:bg-palette-5">
                                <button role="activate_btn" className={selectedCa.account_status === true ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === true} onMouseDown={this.handleActivateOption}  >Activate selected CA</button>
                              </div>}
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 hover:bg-palette-5">  
                                <button role="deactivate_btn" className={selectedCa.account_status === false ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === false} onMouseDown={this.handleActivateOption} >Deactivate selected CA</button>
                              </div>}
                              <div className="bg-palette-4 hover:bg-palette-5">  
                                <button role="download_options_btn" className="mx-3 my-1 text-white font-bold focus:outline-none" onMouseDown={this.downloadSelected} >Download selected CA</button>
                              </div>  
                            </div>
                            }
                          </div>
                    {selectedNestedSubCa.length === 0
                    ? 
                    <div className="justify-center, items-center">
                      <img src={image6} className="h-2/5 w-3/5 m-auto "/> 
                      <p className="text-center my-4 mx-4 text-xl italic">You don't have any Ca/Sub Ca created.</p>
                    </div>
                    :
                    <div>
                     <div className=" hidden lg:block table h-300 mt-2 w-full px-0 md:px-2 lg:px-10">
                            <div className="flex flex-col">
                            <div className="flex-grow overflow-auto">
                              <table className="relative w-full border">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tl">Selected</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center"></th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Subject DN</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Type</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tr">Status</th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y ">
                                  {selectedNestedSubCa.map((ca, index) => (
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/}>

                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.id)}>
                                        <input className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ca.id) ? 1 : 0}  value={ca.id} onClick={(e) => this.handleCheckbox(ca.id)} onChange={ (e) => {} } />
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        <button className="px-5 py-1 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button>
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.id)}>
                                        {ca.subject_dn}
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.id)}>{ca.ca_type}</td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.id)}>{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr>))}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>

                    <div className="visible lg:hidden w-full mt-2 mr-2 px-2">
                            <div className="flex">
                            <div className="flex-grow-2 overflow-auto overflow-x-hidden lg:overflow-x-auto">
                              <table className="relative w-full">
 
                                <tbody className="">
                                  {selectedNestedSubCa.map((ca, index) => (
                                  <div className="mx-3 my-4 border-2 rounded-tl rounded-bl border-palette-5 mobile-row">
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Selected:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <input className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ca.id) ? 1 : 0}  value={ca.id} onClick={(e) => this.handleCheckbox(ca.id)} onChange={ (e) => {} } />
                                    </td>
                                  </tr>

                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Sub CA’s:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <button className="px-5 py-1 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button>
                                    </td>
                                  </tr>
                                  
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">SubjectDN:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        {ca.common_name && <span>CN={ca.common_name}</span>} 
                                        {ca.organization && <span>, O={ca.organization}</span>} 
                                        {ca.organization_unit && <span>, OU={ca.organization_unit}</span>}
                                    </td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.id)}>
                                  <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Type:</td>
                                  <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">{ca.ca_type}</td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Status:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle w-full">{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr></div>))}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>
                     </div>}
                    <Pagination fetchCallback={this.fetchCallback} />

                    {selectedNestedSubCa && selectedNestedSubCa.length > 0 && selectedCa && selectedCa.length !== 0 && !multiple && <Info adminFetch="true" />}
                        </div>
                    </div>}
                    </div>
                    

        )
    }
})