import React, { Component } from "react"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

import image5 from "../../images/info.png"
import image6 from "../../images/noData.jpeg"
import EndEntities from "./EndEntities"
import Cas from "./Cas"
import {getHelperIsExpired} from '../../helpers.js'

var moment = require('moment');


// Redux where we import state
  const mapStateToProps = state => {
    return {
      cas: state.cas,
      spinner: state.spinner,
      adminOption: state.adminOption,
      showNestedCa: state.showNestedCa,
      perPage: state.perPage
    };
  };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateCas: data => {
      return { type: "UPDATE_CAS", cas: data }
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    },
    updateAdminOption: data => {
      return {type: "ADMIN_OPTION", adminOption: data}
    },
    updateEeSelectedTeam: data => {
        return { type: "EE_SELECTED_TEAM", eeSelectedTeam: data}
    },
    updatePagination: pagination => {
      return { type: "UPDATE_PAGINATION", pagination: pagination}
    },
    restartIdFromSelectedCa: id => {
      return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: id }
    },
    updateSelectedNestedSubCa: data => {
      return {type: "SELECTED_NESTED_SUB_CA", selectedNestedSubCa: data}
    },
    updateButtonWindowOnDashboardPage: data => {
      return { type: "UPDATE_BUTTON_WINDOW_ON_DASHBOARD_PAGE", buttonWindowOnDashboardPage: data }
    },
    changePage: page => {
        return { type: "UPDATE_PAGE_NUMBER", page: page }
    },
    updateShowNestedCa: data => {
      return {type: "SHOW_NESTED_CA", showNestedCa: data}
    },
    updateEESelect: data => {
        return { type: "EE_SELECT", eeSelect: data}
    },
    updateEESelectType: data => {
        return { type: "EE_SELECT_TYPE", eeSelectType: data}
    },
    cleanPathCa: data => { 
      return {type: "CLEAN_PATH_CA", pathCa: data}
    },
    updateArrayWithSubCas: data => {
        return { type: "ARRAY_WITH_SUB_CAS", arrayWithSubCas: data }
    },
  }
  
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class Administration extends Component {


  componentDidUpdate() {
        
  }
  // This is Fetch method where we doing GET request with Rails Server 
  componentDidMount() {
    this.props.updateAdminOption('cas')
    this.fetchCas(1)
    console.log('history')
    console.log(this.props.history)
    this.props.cleanPathCa([])
  }

  fetchCas = (page) => {
    this.props.changeSpinner(true)
    let perPage = this.props.perPage
    let url = "/admin/cas?q[ca_type_eq]=rootca&page="+page+'&per_page='+perPage
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf
      },
    })
    .then(res => res.json())
    .then(date => {
        if (typeof(date.pagy) != 'undefined' && typeof(date.cas) != 'undefined') {
        this.props.updatePagination(date.pagy)
        this.props.changePage(page)
        this.props.restartIdFromSelectedCa(1)
        this.props.updateSelectedNestedSubCa(date.cas)
        this.props.updateShowNestedCa(!this.props.showNestedCa)
        this.props.updateButtonWindowOnDashboardPage(false)
        }
        //this.props.history.push("/subca")
        this.props.changeSpinner(false)
    })
  }

  is_expired(ca) {
    let res = getHelperIsExpired(ca)
    return res
  }

  fetchSubCas = () => {
      console.log('subcas fetch')
            this.props.changeSpinner(true)
            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            fetch("/cas?q[ca_type_eq]=subca&page=1&per_page=9999999999", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "X-CSRF-Token": csrf
                },
            }).then(res => res.json())
            .then(date => {
                if (typeof(date.cas) != 'undefined') {
                let subCaArray = date.cas.filter(ca => ca.ca_type == "SUBCA" && !this.is_expired(ca) && ca.account_status)
                this.props.updateArrayWithSubCas(subCaArray)
                }
                this.props.changeSpinner(false)
            })
  }

  fetchEE = (page) => {
    console.log('ee fetch')
    this.props.changeSpinner(true)
    let perPage = this.props.perPage
    let url = "/admin/end_entities?page="+page+'&per_page='+perPage
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf
      },
    })
    .then(res => res.json())
    .then(date => {
        if (typeof(date.pagy) != 'undefined' && typeof(date.entities) != 'undefined') {
        this.props.updateEeSelectedTeam(date.entities)
        this.props.updatePagination(date.pagy)
        }
        this.fetchSubCas()
    })
  }

  handleCas = (option) => {
    switch(option) {
      case 'cas':
        this.fetchCas(1)
      break;
      case 'end_entities':
        this.props.updateEESelect('all')
        this.props.updateEESelectType('all')
        this.fetchEE(1)
      break;
    }
    this.props.updateAdminOption(option)
  }

    render() { 
            const spinner = this.props.spinner
            const cas = this.props.cas 
            const pagination = this.props.pagination
            const page = this.props.page
           
            return (
              <div className=" bg-palette-2 h-screen" onClick={this.handleDropdownsOut}>
                <div className="flex"> 
                  {spinner === true //rendering spinner until get CA fetch is done
                  ?
                  <div className="justify-center items-center m-auto pt-20">
                    <div className="animate-spin rounded-full h-36 w-36 border-b-2 border-gray-900"></div>
                  </div>
                  :
                  <div className="pt-2 mb-10 sm:px-2 lg:px-8 pb-4 h-8 sm:py-10 text-center sm:text-left bg-palette-2 w-full">
                    <div>
                      <div> 
                        <p className="w-8/12 mb-4 float-left text-xl sm:text-3xl md:ml-1 font-sans text-gray-700 text-left ml-3">Administration </p>
                        <select role="team_select" className="relative float-left md:float-right bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 rounded w-12/12 w-full md:w-3/12 ml-1 mr-1" defaultValue={this.props.adminOption} onChange={(e) => this.handleCas(e.target.value)}>
                            <option key="1" className="pr-6" value="cas">Certificate Authorities</option>
                            <option key="2" className="pr-6" value="end_entities">Certificates</option>
                        </select>              
                      </div>
                        
                    </div>

                    <hr className="ml-4 w-full mt-10 mb-2"/>
                    
                  </div>}
                </div>
                {this.props.adminOption === 'end_entities' && !spinner && <EndEntities/>}
                {this.props.adminOption === 'cas' && !spinner && <Cas history={this.props.history}/>}
              </div>


            )
    }

})
