import React, { Component } from "react"

import { connect } from "react-redux"


  // Redux where we import state
  const mapStateToProps = state => {
      return {
        stepNumber: state.stepNumber,
        tagName: state.tagName,
        tagNameValue: state.tagNameValue,
        inputTags: state.inputTags,
        maxTags: state.maxTags,
        tagNameBuf: state.tagNameBuf,
        tagValBuf: state.tagValBuf,
        idFromSelectedCa: state.idFromSelectedCa, //for tag_edit functionality
        tagsBuf: state.tagsBuf,
        tagEditCaId: state.tagEditCaId,
        caSelectedTeam: state.caSelectedTeam,
        selectedCa: state.selectedCa,
        selectedNestedSubCa: state.selectedNestedSubCa,
      };
  };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateTagName: (newEmail) => {
      return { type: "UPDATE_TAG_NAME", tagName: newEmail}
    },
    updateTagNameValue: (newValue) => {
      return { type: "UPDATE_TAG_NAME_VALUE", tagNameValue: newValue}
    },
    updateTagArray: (data) => {
      return { type: "UPDATE_TAG_ARRAY", inputTags: data}
    },
    updateTagResults: (data) => {
      return { type: "UPDATE_TAG_RESULTS", tagNameValue: data}
    },
    updateTagNumResults: (data) => {
      return { type: "UPDATE_TAG_RESULTS_NUMBER", tagName: data}
    },
    updateTagsBuf: (data) => {
      return { type: "UPDATE_TAGS_BUF", tagsBuf: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateCaSelectedTeam: data => {
      return {type: "CA_SELECTED_TEAM", caSelectedTeam: data}
    },
    updateSelectedNestedSubCa: data => {
      return {type: "SELECTED_NESTED_SUB_CA", selectedNestedSubCa: data}
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step1 extends Component {

  // This function pickup value from TagName input field and setup redux state
  handleChangeName = (e) => {
    e.preventDefault();
    let name = e.target.value
    this.props.updateTagName(name)
  }
 
  // This function pickup value from TagName input field and setup redux state
  handleChangeValue = (e) => {
    e.preventDefault();
    let newValue = e.target.value
    this.props.updateTagNameValue(newValue)
  }

  addTag = () => {
    let tags = this.props.inputTags
    let name = this.props.tagNameBuf
    let value = this.props.tagValBuf
    let max = this.props.maxTags
    if (name && value) {
      var obj = {name:name, value:value}
      tags.push(obj)
      if (tags.length <= max) {
        this.props.updateTagArray(tags)
        this.buildRes(tags)
        this.props.updateTagNameValue('')
        this.props.updateTagName('')
      }
    }
  }

  removeTag = (e) => {
    let index = e.target.value
    let arr = this.props.inputTags
    let filtered = arr.filter(ee => ee != arr[index])
    this.props.updateTagArray(filtered)
    this.buildRes(filtered)
  }

  buildRes(tags) {
    let res = [];
    tags.map( (ee, index) => {
      res.push({name: ee.name, value: ee.value})
    })
    let count = res.length
    res = JSON.stringify(res)
    this.props.updateTagResults(res)
    this.props.updateTagNumResults(count)

    if (this.props.stepNumber === 'tag_edit') {
      this.saveUpdatedTags(res)
    }
  }

 saveUpdatedTags = async (tags) => {
    let bodyJson = JSON.stringify(
          {
            "ca":
          {   
            "tag_name": this.props.tagName, //amount of tags
            "tag_name_value": tags, //json stringify with tags
          }
        })

    if (this.props.stepNumber === 'tag_edit') {
      let buf = this.props.tagsBuf
      let filtered = buf.filter( ee => ee.id != this.props.tagEditCaId)
      filtered.push({id: this.props.tagEditCaId, tags: tags})
      this.props.updateTagsBuf(filtered)

      let url = "/cas/"+this.props.tagEditCaId
      if (typeof(this.props.fromAdmin) != 'undefined' && this.props.fromAdmin === 'true') {
        url = "/admin/cas/"+this.props.tagEditCaId
      }
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf
        },
        body: bodyJson,
      })
      .then(res => res.json(res))
      .then(res => {
        let cas = this.props.caSelectedTeam
        let selected_ca = this.props.selectedCa
        if (selected_ca.signed_by !== 1) {
          cas = this.props.selectedNestedSubCa
        } 
        let ind = false
        let filtered = cas.filter( (ca, index) => ca.ca_api_id === selected_ca.ca_api_id ? ind = index : null)
        if (ind !== false) {
          selected_ca.tag_name = this.props.tagName
          selected_ca.tag_name_value = tags
          cas[ind] = selected_ca
          if (selected_ca.signed_by === 1) {
            this.props.updateCaSelectedTeam(cas)
          } else {
            this.props.updateSelectedNestedSubCa(cas)
          }
        }
        this.props.updateNotification({status: 'success', text: 'Certificate tags are updated'})
      }).catch(error => {
        console.log(error)
        this.props.updateNotification({status: 'error', text: 'Certificate tags are not updated'})
      })
    }
  }
  
  

        render() { 
            const stepNumber = this.props.stepNumber
            if (stepNumber !== 5 && stepNumber !== 'tag_edit') { // Prop: The current step
                return null
            }

            const tags = this.props.inputTags
            const max = this.props.maxTags
            const results = this.props.tagNameValue
            const count = this.props.tagName
            const tagNameBuf = this.props.tagNameBuf
            const tagValBuf = this.props.tagValBuf
            
            return (
              <div className="w-full">
                  <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Add tags</p>
                      <hr className="ml-4"/>
                      <p className="text-base sm:text-xl text-gray-600 italic mt-3 px-4 text-justify md:text-base">To help you manage your certificate authorities you can optionally assign your own metadata to each resource in the form of values. Learn more.</p>
                  </div>
                  <div className="w-full mt-10">
                  <div className="md:flex md:items-center mb-6" >
                      <div className="md:w-1/4"> 
                         <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Name 
                         </label>
                      </div>
                      <div className="md:w-3/4 px-2">
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                            name="tag_name"
                            type="text"
                            label="Tag Name"
                            placeholder="Name"
                            value={tagNameBuf} // Prop: The email input data
                            onChange={this.handleChangeName} // Prop: Puts data into state
                        />
                      </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/4">
                      <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Value 
                      </label>
                    </div>
                    <div className="md:w-3/4 px-2">
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                            name="tag_value"
                            type="text"
                            placeholder="Value"
                            value={tagValBuf} // Prop: The email input data
                            onChange={this.handleChangeValue} // Prop: Puts data into state
                            
                        />
                    </div>      
                  </div> 
                  {tags.length < max ? <div className="text-center w-full"><button role="add_new_tag" onClick={this.addTag} className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-palette-2 font-bold py-2 px-4 rounded my-4">Add Tag</button></div> : null}
                  </div>
                  {tags && tags.length > 0 && <div className="px-2">
                    <table className="custom-table-view">
                    <tbody>
                    {tags.map( (el, index) => 
                      <tr key={index}><td>{el.name}</td><td>{el.value}</td><td><button role="remove_tag" className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-1 px-1 sm:px-1 rounded mx-1 my-1" onClick={this.removeTag} value={index}><i className="fa fa-trash"></i> Remove</button></td></tr>
                    )}
                    </tbody>
                    </table>
                    {count && <p>{count} added</p>}
                  </div>}
              </div>            
                
            )
            }
        }
      
      )
      

