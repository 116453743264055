import React, { Component } from "react"

import { connect } from "react-redux"


// This is data for drop down input
const options = [
   { value:"US", label: "United States"},
   { value:"AF", label: "Afghanistan" },
   { value:"AX", label: "Aland Islands" },
   { value:"AL", label: "Albania"},
   { value:"DZ", label: "Algeria"},
   { value:"AS", label: "American Samoa"},
   { value:"AD", label: "Andorra"},
   { value:"AO", label: "Angola"},
   { value:"AI", label: "Anguilla"},
   { value:"AQ", label: "Antarctica" },
   { value:"AG", label: "Antigua & Barbuda"},
   { value:"AR", label: "Argentina"},
   { value:"AM", label: "Armenia"},
   { value:"AW", label: "Aruba"},
   { value:"AU", label: "Australia"},
   { value:"AT", label: "Austria"},
   { value:"AZ", label: "Azerbaijan"},
   { value:"BS", label: "Bahamas"},
   { value:"BH", label: "Bahrain"},
   { value:"BD", label: "Bangladesh"},
   { value:"BB", label: "Barbados"},
   { value:"BE", label: "Belgium"},
   { value:"BZ", label: "Belize"},
   { value:"BJ", label: "Benin"},
   { value:"BM", label: "Bermuda"},
   { value:"BT", label: "Bhutan"},
   { value:"BO", label: "Bolivia, Plurinational State of"},
   { value:"BQ", label: "Bonaire, Sint Eustatius and Saba"},
   { value:"BA", label: "Bosnia & Herzegovina"},
   { value:"BW", label: "Botswana"},
   { value:"BV", label: "Bouvet Island"},
   { value:"BN", label:"Brunei Darussalam"},
   { value:"BG",label: "Bulgaria"},
   { value:"BF",label: "Burkina Faso"},
   { value:"IO",label: "British Indian Ocean Ter"},
   { value:"BI",label: "Burundi"},
   { value:"BR",label: "Brazil"},
   { value:"KH",label: "Cambodia"},
   { value:"CM",label: "Cameroon"},
   { value:"CA",label: "Canada"},
   { value:"CV",label: "Cape Verde"},
   { value:"KY",label: "Cayman Islands"},
   { value:"CF",label: "Central African Republic"},
   { value:"TD",label: "Chad"},
   { value:"CL",label: "Chile"},
   { value:"CN",label: "China"},
   { value:"CX",label: "Christmas Island"},
   { value:"CC",label: "Cocos (Keeling) Island"},
   { value:"CO",label: "Colombia"},
   { value:"KM",label: "Comoros"},
   { value:"CG", label: "Congo"},
   { value:"CD", label: "Congo, Democratic Republic of the"},
   { value:"CK", label: "Cook Islands"},
   { value:"CR", label: "Costa Rica"},
   { value:"HR", label: "Croatia"},
   { value:"CW", label: "Curacao"},
   { value:"CY", label: "Cyprus"},
   { value:"CZ", label: "Czech Republic"},
   { value:"DK", label: "Denmark"},
   { value:"DJ", label: "Djibouti"},
   { value:"DM", label: "Dominica"},
   { value:"DO", label: "Dominican Republic"},
   { value:"EC", label: "Ecuador"},
   { value:"EG", label: "Egypt"},
   { value:"SV", label: "El Salvador"},
   { value:"GQ", label: "Equatorial Guinea"},
   { value:"ER", label: "Eritrea"},
   { value:"EE", label: "Estonia"},
   { value:"ET", label: "Ethiopia"},
   { value:"FK", label: "Falkland Islands (Malvinas)"},
   { value:"FO", label: "Faroe Islands"},
   { value:"FJ", label: "Fiji"},
   { value:"FI", label: "Finland"},
   { value:"FR", label: "France"},
   { value:"GF", label: "French Guiana"},
   { value:"PF", label: "French Polynesia"},
   { value:"TF", label: "French Southern Ter"},
   { value:"GA", label: "Gabon"},
   { value:"GM", label: "Gambia"},
   { value:"GE", label: "Georgia"},
   { value:"DE", label: "Germany"},
   { value:"GH", label: "Ghana"},
   { value:"GI", label: "Gibraltar"},
   { value:"GR", label: "Greece"},
   { value:"GL", label: "Greenland"},
   { value:"GD", label: "Grenada"},
   { value:"GP", label: "Guadeloupe"},
   { value:"GU", label: "Guam"},
   { value:"GT", label: "Guatemala"},
   { value:"GG", label: "Guernsey"},
   { value:"GN", label: "Guinea"},
   { value:"GW", label: "Guinea-Bissau"},
   { value:"GY", label: "Guyana"},
   { value:"HT", label: "Haiti"},
   { value:"HM", label: "Heard Island and Mcdonald Islands"},
   { value:"VA", label: "Holy See (Vatican City State)"},
   { value:"HN", label: "Honduras"},
   { value:"HK", label: "Hong Kong"},
   { value:"HU", label: "Hungary"},
   { value:"IS", label: "Iceland"},
   { value:"ID", label: "Indonesia"},
   { value:"IN", label: "India"},
   { value:"IQ", label: "Iraq"},
   { value:"IE", label: "Ireland"},
   { value:"IM", label: "Isle of Man"},
   { value:"IL", label: "Israel"},
   { value:"IT", label: "Italy"},
   { value:"JM", label: "Jamaica"},
   { value:"JE", label: "Jersey"},
   { value:"JP", label: "Japan"},
   { value:"JO", label: "Jordan"},
   { value:"KZ", label: "Kazakhstan"},
   { value:"KE", label: "Kenya"},
   { value:"KI", label: "Kiribati"},
   { value:"KR", label: "Korea, Republic of"},
   { value:"KW", label: "Kuwait"},
   { value:"KG", label: "Kyrgyzstan"},
   { value:"LA", label: "Lao People’s Democratic Republic"},
   { value:"LV", label: "Latvia"},
   { value:"LB", label: "Lebanon"},
   { value:"LS", label: "Lesotho"},
   { value:"LR", label: "Liberia"},
   { value:"LY", label: "Libya"},
   { value:"LI", label: "Liechtenstein"},
   { value:"LT", label: "Lithuania"},
   { value:"LU", label: "Luxembourg"},
   { value:"MO", label: "Macau"},
   { value:"MK", label: "North Macedonia"},
   { value:"MG", label: "Madagascar"},
   { value:"MY", label: "Malaysia"},
   { value:"MW", label: "Malawi"},
   { value:"MV", label: "Maldives"},
   { value:"ML", label: "Mali"},
   { value:"MT", label: "Malta"},
   { value:"MH", label: "Marshall Islands"},
   { value:"MQ", label: "Martinique"},
   { value:"MR", label: "Mauritania"},
   { value:"MU", label: "Mauritius"},
   { value:"YT", label: "Mayotte"},
   { value:"MX", label: "Mexico"},
   { value:"FM", label: "Micronesia (Federated States of)"},
   { value:"MD", label: "Moldova, Republic of"},
   { value:"MC", label: "Monaco"},
   { value:"MN", label: "Mongolia"},
   { value:"ME", label: "Montenegro"},
   { value:"MS", label: "Montserrat"},
   { value:"MA", label: "Morocco"},
   { value:"MZ", label: "Mozambique"},
   { value:"MM", label: "Myanmar"},
   { value:"NA", label: "Nambia"},
   { value:"NR", label: "Nauru"},
   { value:"NP", label: "Nepal"},
   { value:"NL", label: "Netherlands"},
   { value:"NC", label: "New Caledonia"},
   { value:"NZ", label: "New Zealand"},
   { value:"NI", label: "Nicaragua"},
   { value:"NE", label: "Niger"},
   { value:"NG", label: "Nigeria"},
   { value:"NU", label: "Niue"},
   { value:"NF", label: "Norfolk Island"},
   { value:"MP", label: "Northern Mariana Islands"},
   { value:"NO", label: "Norway"},
   { value:"OM", label: "Oman"},
   { value:"PK", label: "Pakistan"},
   { value:"PW", label: "Palau"},
   { value:"PS", label: "Palestine, State of"},
   { value:"PA", label: "Panama"},
   { value:"PG", label: "Papua New Guinea"},
   { value:"PY", label: "Paraguay"},
   { value:"PE", label: "Peru"},
   { value:"PH", label: "Philippines"},
   { value:"PN", label: "Pitcairn"},
   { value:"PL", label: "Poland"},
   { value:"PT", label: "Portugal"},
   { value:"PR", label: "Puerto Rico"},
   { value:"QA", label: "Qatar"},
   { value:"RE", label: "Reunion"},
   { value:"RO", label: "Romania"},
   { value:"RU", label: "Russia Federation"},
   { value:"RW", label: "Rwanda"},
   { value:"BL", label: "Saint Barthelemy"},
   { value:"SH", label: "Saint Helena, Ascension and Tristan da Cunha"},
   { value:"KN", label: "Saint Kitts and Nevis"},
   { value:"LC", label: "Saint Lucia"},
   { value:"MF", label: "Saint Martin (French part)"},
   { value:"PM", label: "Saint Pierre and Miquelon"},
   { value:"VC", label: "Saint Vincent and the Grenadines"},
   { value:"WS", label: "Samoa"},
   { value:"SM", label: "San Marino"},
   { value:"SA", label: "Saudi Arabia"},
   { value:"SN", label: "Senegal"},
   { value:"RS", label: "Serbia"},
   { value:"SC", label: "Seychelles"},
   { value:"SL", label: "Sierra Leone"},
   { value:"SG", label: "Singapore"},
   { value:"SX", label: "Sint Maarten (Dutch part)"},
   { value:"SK", label: "Slovakia"},
   { value:"SI", label: "Slovenia"},
   { value:"SB", label: "Solomon Islands"},
   { value:"SO", label: "Somalia"},
   { value:"ZA", label: "South Africa"},
   { value:"GS", label: "South Georgia and the South Sandwich Islands"},
   { value:"ES", label: "Spain"},
   { value:"LK", label: "Sri Lanka"},
   { value:"SS", label: "South Sudan"},
   { value:"SR", label: "Suriname"},
   { value:"SJ", label: "Svalbard and Jan Mayen"},
   { value:"SE", label: "Sweden"},
   { value:"CH", label: "Switzerland"},
   { value:"ST", label: "São Tomé and Príncipe"},
   { value:"TW", label: "Taiwan"},
   { value:"TJ", label: "Tajikistan"},
   { value:"TZ", label: "Tanzania, United Republic of"},
   { value:"TH", label: "Thailand"},
   { value:"TG", label: "Togo"},
   { value:"TK", label: "Tokelau"},
   { value:"TO", label: "Tonga"},
   { value:"TT", label: "Trinidad & Tobago"},
   { value:"TN", label: "Tunisia"},
   { value:"TR", label: "Turkey"},
   { value:"TM", label: "Turkmenistan"},
   { value:"TC", label: "Turks and Caicos Islands"},
   { value:"TV", label: "Tuvalu"},
   { value:"UG", label: "Uganda"},
   { value:"GB", label: "United Kingdom"},
   { value:"UA", label: "Ukraine"},
   { value:"AE", label: "United Arab Emirates"},
   { value:"UM", label: "United States Minor Outlying Islands"},
   { value:"UY", label: "Uruguay"},
   { value:"UZ", label: "Uzbekistan"},
   { value:"VU", label: "Vanuatu"},
   { value:"VE", label: "Venezuela"},
   { value:"VN", label: "Vietnam"},
   { value:"VG", label: "Virgin Islands, British"},
   { value:"VI", label: "Virgin Islands, U.S."},
   { value:"WF", label: "Wallis and Futuna"},
   { value:"EH", label: "Western Sahara"},
   { value:"YE", label: "Yemen"},
   { value:"ZM", label: "Zambia"},
   { value:"ZW", label: "Zimbabwe"},
]

// Redux where we import state
const mapStateToProps = state => {
    return {
      selectedCa: state.selectedCa,
      stepNumber: state.stepNumber,
      organization: state.organization,
      organizationUnit: state.organizationUnit,
      countryName: state.countryName,
      stateName: state.stateName,
      localityName: state.localityName,
      commonName: state.commonName,
      validFor: state.validFor,
      validFor1: state.validFor1,
      inputValidity: state.inputValidity,
      viewCaType: state.viewCaType,
      emptyCommonNameError: state.emptyCommonNameError,
      default_crl_distribution_point_input: state.default_crl_distribution_point_input,
    };
  };

// Redux where we dispatch state
const mapDispatchToProps = {
    updateOrganization: (organization) => {
        return { type: "SET_ORGANIZATION", organization: organization }
    },
    updateOrganizationUnit: (organizationUnitValue) => {
        return { type: "SET_ORGANIZATION_UNIT", organizationUnit: organizationUnitValue}
    },
    updateCountryName: (countryName) => {
        return { type: "SET_COUNTRY_NAME", countryName: countryName}
    },    
    updateStateName: (stateName) => {
        return { type: "SET_STATE_NAME", stateName: stateName }
    },
    updateLocalityName: (localityName) => {
        return { type: "SET_LOCALITY_NAME", localityName: localityName }
    },
    updateCommonName: (commonName) => {
        return { type: "SET_COMMON_NAME", commonName: commonName }
    },
    updateValidity: (validity) => {
        return { type: "UPDATE_VALID_FOR", validFor: validity  }
    },
    updateInputValidity: (input) => {
      return { type: "UPDATE_INPUT_VALIDITY", inputValidity: input  }
    },
    updateValidity1: (validity) => {
        return { type: "UPDATE_VALID_FOR_NEW", validFor1: validity  }
    },
    updateCommonNameError: data => {
      return { type: "EMPTY_COMMON_NAME_ERROR", emptyCommonNameError: data}
    },
    updateDefaultCrlDistributionPointInput: selectedValue => {
      return { type: "DEFAULT_CRL_DISTRIBUTION_POINT_INPUT", default_crl_distribution_point_input: selectedValue}
    },
    

}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step2 extends Component {

    componentDidMount() {
        //prefill data from a selected root CA:
        let root = this.props.selectedCa
        //console.log(root)
        if (root) {
            if (typeof(root.organization) != 'undefined' && root.organization) {
                this.props.updateOrganization(root.organization)
            }

            if (typeof(root.organization_unit) != 'undefined' && root.organization_unit) {
                this.props.updateOrganizationUnit(root.organization_unit)
            }

            if (typeof(root.country_name) != 'undefined' && root.country_name) {
                this.props.updateCountryName(root.country_name)
            }

            if (typeof(root.locality_name) != 'undefined' && root.locality_name) {
                this.props.updateLocalityName(root.locality_name)
            }

            if (typeof(root.state_name) != 'undefined' && root.state_name) {
                this.props.updateStateName(root.state_name)
            }

            if (typeof(root.valid_for) != 'undefined') {
                let max = parseInt(root.valid_for)
                let amount = max.toString()
                this.props.updateValidity(amount)
                this.props.updateInputValidity('days')
            }
        }
    }

// This function pickup value and call mapDispatch redux metod
handleOrganization = (event) => {
    event.preventDefault();
    let organization = event.target.value
    this.props.updateOrganization(organization)
}

// This function pickup value and call mapDispatch redux metod
handleOrganizationUnit = (event) => {
    event.preventDefault();
    let organizationUnitValue = event.target.value
    this.props.updateOrganizationUnit(organizationUnitValue)   
}

// This function pickup value and call mapDispatch redux metod
handleCountryName = (event) => {
    event.preventDefault();
    let countryName = event.target.value
    let country = options.find(e => e.label === countryName)
    this.props.updateCountryName(country.value)
}

// This function pickup value and call mapDispatch redux metod
handleStateName = (event) => {
    event.preventDefault();
    let stateName = event.target.value
    this.props.updateStateName(stateName)  
}

// This function pickup value and call mapDispatch redux metod
handleLocalityName = (event) => {
    event.preventDefault();
    let localityName = event.target.value 
    this.props.updateLocalityName(localityName)  
}

// This function pickup value and call mapDispatch redux metod
handleCommonName = (event) => {
    event.preventDefault();
    let commonName = event.target.value 
    this.props.updateCommonName(commonName)
    let crlName = `crl.ppki.ssl.com/${commonName}`
    let crlNameRegex = crlName.replace(/\s/g, '')
    this.props.updateDefaultCrlDistributionPointInput(crlNameRegex)
    this.props.updateCommonNameError(false)
    
}

// This function pickup value and call mapDispatch redux metod
handleValidFor = (event) => {
  event.preventDefault();
  let validity = event.target.value
  this.props.updateValidity1(validity)
  if (this.props.inputValidity === "year") {
    this.props.updateValidity(validity * 365)
  } else {
    this.props.updateValidity(validity)
  } 
}

// This function pickup value and call mapDispatch redux metod
handleInputValidity = (event) => {
    let input = event.target.value
    let amount = this.props.validFor1;
    let root = this.props.selectedCa
    let max = false;
    if (typeof(root.valid_for) != 'undefined') {
        max = parseInt(root.valid_for)
    }
  
    if (amount && typeof(amount) != 'undefined') {
        amount = parseFloat(amount)
        if ( input === "day") {
            amount = amount*365;
            amount = Math.round(amount);
        } else {
            amount = amount/365;
            amount = amount.toFixed(2);
        }

        if (max && amount > max) {
            amount = max
        }

        amount = amount.toString()
        this.props.updateValidity(amount)
        this.props.updateValidity1(amount)
    } 
  
    this.props.updateInputValidity(input)
}

getSubjectDN = () => {
    const ca = this.props.selectedCa
    let res = ''
    
    ca.common_name ? res += 'CN='+ca.common_name : null
    ca.organization ? res += ', O='+ca.organization : null
    ca.organization_unit ? res += ', OU='+ca.organization_unit : null 
        
    return res
}
  

        render() {  

            const stepNumber = this.props.stepNumber
            if (stepNumber !== 2) { // Prop: The current step
                return null
            }

            const selectedCa = this.props.selectedCa
            const viewCaType = this.props.viewCaType
            const organization = this.props.organization
            const organizationUnit = this.props.organizationUnit
            const localityName = this.props.localityName
            const stateName = this.props.stateName
            const commonName = this.props.commonName
            const countryName = this.props.countryName
            const emptyCommonNameError = this.props.emptyCommonNameError
            const inputValidity = this.props.inputValidity
            const validFor1 = this.props.validFor1
            const header = viewCaType === 'ROOTCA' ? 'Configure the certificate authority (CA)' : 'Configure the certificate authority (SubCA)'
            const root_ca_info = viewCaType !== 'ROOTCA' ? 'Root CA subject DN:' + this.getSubjectDN() : null
            
            return (
                <div className="flex flex-col mb-0 sm:mb-10">
                  <p className="text-3xl ml-2">{header}</p>
                  <p className="text-xl ml-2">{root_ca_info}</p>
                  <hr className="ml-4"/>
                  <p className="text-base sm:text-xl text-gray-600 italic mt-3 px-4 text-justify md:text-base">Name your CA using the distinguished name (DN) format. The name is used as the subject in the CA certificate and as the issuer in certificates that the CA issues. These names cannot be changed later. </p>
                  <div className="max-w-5xl mt-10">
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Organization (O)
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                            name="organization"
                            type="text"
                            placeholder="Example LLC"
                            value={organization} 
                            onChange={this.handleOrganization} // Invoke handleOrganization function onChange 
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Organization Unit (OU)
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input 
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                            name="organizationUnit"
                            type="text"
                            placeholder="HR, Engineering, Finance"
                            value={organizationUnit} 
                            onChange={this.handleOrganizationUnit} // Invoke handleOrganizationUnit function onChange
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                        Country Name
                        <span className="text-xl text-red-400"> *</span>
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <select role="country" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" defaultValue={countryName} onChange={this.handleCountryName}>
                            {options.map((country, i) => (
                                <option key={country.value}>{country.label}</option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Locality name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input 
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" 
                            name="localityName"
                            type="text"
                            placeholder="Houston"
                            value={localityName} 
                            onChange={this.handleLocalityName} // Invoke handleStateName function onChange
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                            State or province name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input 
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" 
                            name="state"
                            type="text"
                            placeholder="Texas"
                            value={stateName} 
                            onChange={this.handleStateName} // Invoke handleStateName function onChange
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Common Name (CN)
                        <span className="text-xl text-red-400"> *</span>
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input 
                            className={emptyCommonNameError === true ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-red-5" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"}
                            name="commonName"
                            type="text"
                            placeholder={emptyCommonNameError === true ? "Common Name cannot be empty!" : "Example CA 1"}
                            value={commonName} 
                            onChange={this.handleCommonName} // Invoke handleStateName function onChange
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6 px-2">
                      <div className="md:w-1/3">
                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Validity
                        </label>
                      </div>
                      <div className="w-full md:w-1/3 mr-5 md:mr-12 lg:mr-16">
                      <input
                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" 
                                min="1"
                                max={inputValidity === "year" ? "100" : "36500"} 
                                name="validDays"
                                type="number"
                                placeholder="7"
                                value={validFor1} // Prop: The email input data
                                onChange={this.handleValidFor} // Prop: Puts data into state
                            />
                      </div>      
                      <div className="w-full md:w-1/4 my-2">
                      <select role="timeframe" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" defaultValue="year" onChange={this.handleInputValidity} >
                        <option value="year">Years</option>
                        <option value="day">Days</option>
                      </select>
                      </div>
                    </div>
                  </div>
                </div>
            )
            }
        }
      
      )
      
