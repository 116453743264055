import React, { Component } from "react"
import PasswordMask from 'react-password-mask';
import { connect } from "react-redux"
//const forge = require('node-forge');

const pkijs = require('pkijs');
const asn1js = require("asn1js");
const pvutils = require("pvutils")

const mapStateToProps = state => {
    return {
        pfxPassword: state.pfxPassword,
        passwordModal: state.passwordModal,
        chainCertificate: state.chainCertificate,
        privateKey: state.privateKey,
    }
}


const mapDispatchToProps = {
    updatePfxPassword: data => {
        return { type: "PFX_PASSWORD", pfxPassword: data }
    },
    updatePasswordModal: data => {
        return { type: "PASSWORD_MODAL", passwordModal: data }
    },
    updateModalSpinner: data => {
        return { type: "SPINNER_MODAL", spinnerModal: data }
    },
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class DownloadPfxModal extends Component {
        

    handleModalDownload = () => {
        this.props.updatePasswordModal(false)
        this.props.updateModalSpinner(true)
        let certificate = this.props.chainCertificate;
        let privateKey = this.props.privateKey;

        certificate = certificate.replace(/\n/g,'')
        certificate = certificate.split('-----')

        privateKey = privateKey.replace(/\n/g,'')
        privateKey =  privateKey.split('-----')

        console.log(this.props.pfxPassword)
        this.openSSLLike(this.props.pfxPassword, certificate[2], privateKey[2]).then( pfx => {
            const element = document.createElement("a");
            const file = new Blob([pfx], {type: 'application/x-pkcs12'});
            element.href = URL.createObjectURL(file);
            element.download = 'pfx.p12';
            document.body.appendChild(element);
            element.click();
            this.props.updateModalSpinner(false)
        })
    }

 openSSLLike = async(password, cert, key) => {
  
  let privateKeyBASE64 = key
  let certificateBASE64 = cert

  //#region Initial variables
  const keyLocalIDBuffer = new ArrayBuffer(4);
  const keyLocalIDView = new Uint8Array(keyLocalIDBuffer);

  pkijs.getRandomValues(keyLocalIDView);

  const certLocalIDBuffer = new ArrayBuffer(4);
  const certLocalIDView = new Uint8Array(certLocalIDBuffer);

  pkijs.getRandomValues(certLocalIDView);

  //#region "KeyUsage" attribute
  const bitArray = new ArrayBuffer(1);
  const bitView = new Uint8Array(bitArray);

  bitView[0] |= 0x80;

  const keyUsage = new asn1js.BitString({
    valueHex: bitArray,
    unusedBits: 7
  });
  //#endregion

  const passwordConverted = pvutils.stringToArrayBuffer(password);
  //#endregion

  //#region Create simplified structires for certificate and private key
  const certRaw = pvutils.stringToArrayBuffer(pvutils.fromBase64(certificateBASE64));
  const certSimpl = pkijs.Certificate.fromBER(certRaw);

  const pkcs8Raw = pvutils.stringToArrayBuffer(pvutils.fromBase64(privateKeyBASE64));
  const pkcs8Simpl = pkijs.PrivateKeyInfo.fromBER(pkcs8Raw);

  //#region Add "keyUsage" attribute
  pkcs8Simpl.attributes = [
    new pkijs.Attribute({
      type: "2.5.29.15",
      values: [
        keyUsage
      ]
    })
  ];
  //#endregion
  //#endregion

  //#region Put initial values for PKCS#12 structures
  const pkcs12 = new pkijs.PFX({
    parsedValue: {
      integrityMode: 0, // Password-Based Integrity Mode
      authenticatedSafe: new pkijs.AuthenticatedSafe({
        parsedValue: {
          safeContents: [
            {
              privacyMode: 0, // "No-privacy" Protection Mode
              value: new pkijs.SafeContents({
                safeBags: [
                  new pkijs.SafeBag({
                    bagId: "1.2.840.113549.1.12.10.1.2",
                    bagValue: new pkijs.PKCS8ShroudedKeyBag({
                      parsedValue: pkcs8Simpl
                    }),
                    bagAttributes: [
                      new pkijs.Attribute({
                        type: "1.2.840.113549.1.9.20", // friendlyName
                        values: [
                          new asn1js.BmpString({ value: "PKCS8ShroudedKeyBag from PKIjs" })
                        ]
                      }),
                      new pkijs.Attribute({
                        type: "1.2.840.113549.1.9.21", // localKeyID
                        values: [
                          new asn1js.OctetString({ valueHex: keyLocalIDBuffer })
                        ]
                      }),
                      new pkijs.Attribute({
                        type: "1.3.6.1.4.1.311.17.1", // pkcs12KeyProviderNameAttr
                        values: [
                          new asn1js.BmpString({ value: "http://www.pkijs.org" })
                        ]
                      })
                    ]
                  })
                ]
              })
            },
            {
              privacyMode: 1, // Password-Based Privacy Protection Mode
              value: new pkijs.SafeContents({
                safeBags: [
                  new pkijs.SafeBag({
                    bagId: "1.2.840.113549.1.12.10.1.3",
                    bagValue: new pkijs.CertBag({
                      parsedValue: certSimpl
                    }),
                    bagAttributes: [
                      new pkijs.Attribute({
                        type: "1.2.840.113549.1.9.20", // friendlyName
                        values: [
                          new asn1js.BmpString({ value: "CertBag from PKIjs" })
                        ]
                      }),
                      new pkijs.Attribute({
                        type: "1.2.840.113549.1.9.21", // localKeyID
                        values: [
                          new asn1js.OctetString({ valueHex: certLocalIDBuffer })
                        ]
                      }),
                      new pkijs.Attribute({
                        type: "1.3.6.1.4.1.311.17.1", // pkcs12KeyProviderNameAttr
                        values: [
                          new asn1js.BmpString({ value: "http://www.pkijs.org" })
                        ]
                      })
                    ]
                  })
                ]
              })
            }
          ]
        }
      })
    }
  });
  //#endregion

  // Encode internal values for "PKCS8ShroudedKeyBag"
  if (!(pkcs12.parsedValue && pkcs12.parsedValue.authenticatedSafe)) {
    throw new Error("pkcs12.parsedValue.authenticatedSafe is empty");
  }
  await pkcs12.parsedValue.authenticatedSafe.parsedValue.safeContents[0].value.safeBags[0].bagValue.makeInternalValues({
    password: passwordConverted,
    contentEncryptionAlgorithm: {
      name: "AES-CBC", // OpenSSL can handle AES-CBC only
      length: 128
    },
    hmacHashAlgorithm: "SHA-1", // OpenSSL can handle SHA-1 only
    iterationCount: 100000
  });

  // Encode internal values for all "SafeContents" firts (create all "Privacy Protection" envelopes)
  await pkcs12.parsedValue.authenticatedSafe.makeInternalValues({
    safeContents: [
      {
        // Empty parameters for first SafeContent since "No Privacy" protection mode there
      },
      {
        password: passwordConverted,
        contentEncryptionAlgorithm: {
          name: "AES-CBC", // OpenSSL can handle AES-CBC only
          length: 128
        },
        hmacHashAlgorithm: "SHA-1", // OpenSSL can handle SHA-1 only
        iterationCount: 100000
      }
    ]
  });

  // Encode internal values for "Integrity Protection" envelope
  await pkcs12.makeInternalValues({
    password: passwordConverted,
    iterations: 100000,
    pbkdf2HashAlgorithm: "SHA-256", // OpenSSL can not handle usage of PBKDF2, only PBKDF1
    hmacHashAlgorithm: "SHA-256"
  });
  //#endregion

  return pkcs12.toSchema().toBER(false);
}

    handleModalDownloadClose = () => {
        this.props.updatePasswordModal(false)
    }

    handlePfxPassword = (e) => {
        e.preventDefault()
        this.props.updatePfxPassword(e.target.value)
    }
    

        render() {  

            const pfxPassword = this.props.pfxPassword
            return (
                <div className="md:flex m-auto mb-6">
                        <div className="">
                          <label className="block font-bold md:text-right mt-2 mb-1 md:mb-0 pr-4 pl-4" >
                              Password1
                          </label>
                        </div>
                        <div className="w-60 sm:w-96">
                            <PasswordMask
                                id="password"
                                name="password"
                                placeholder="Enter password"
                                onChange={this.handlePfxPassword}
                                value={pfxPassword}
                            />
                        </div>
                        <div className="flex">
                            <button onClick={this.handleModalDownload} type="button" className="mt-3 w-full justify-center border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" >
                                    Download PFX
                            </button>
                            <button onClick={this.handleModalDownloadClose} type="button" className="mt-3 w-full justify-center border border-gray-300 shadow-sm px-2 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                    Done 
                            </button>
                        </div>
                      </div>
            )}
        }
      )