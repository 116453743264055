export const loadState = (type, default_value) => {
    try {
        const serializedState = localStorage.getItem(type);
        if (serializedState === null) {
            return default_value;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        //console.log(err, "ERROR")
    }
}


export const saveState = (state, type) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(type, serializedState);
    } catch (err) {
        // ignore write errors.
        //console.log(err, "ERROR 1")
    }
}