import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../stylesheets/application.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { Provider } from 'react-redux';


import store from './src/store/index';




var multipleEvents = function(event) {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Route path="/" component={App} />
      </Router>
    </Provider>,
    document.getElementById("react-container")
  )
}

window.addEventListener('turbo:load', multipleEvents, false);
window.addEventListener('DOMContentLoaded', multipleEvents, false);