import React, { Component } from "react"
import { Router, Route, Switch} from 'react-router-dom'
import { connect } from "react-redux"

import Dashboard from "./src/components/cas/Dashboard"
import Administration from "./src/components/admin/Administration"
import Form from "./src/components/cas/Form"
import CertificateManager from "./src/components/end_entities/CertificateManager"
import Form2 from "./src/components/end_entities/Form2"
import history from "./src/history/history"
import CaTable from "./src/components/cas/CaTable"
import Notify from "./src/components/Notify"
import Alert from './src/components/Alert'

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  };
};




export default connect(
  mapStateToProps,
)(
  class App extends Component {


  render() {
    return (
      <Switch history={history} >

        <div className="bg-palette-2 w-full">
            <Notify/>
            <Alert/>
            <Route path="/" exact component={Dashboard} />
            <Route path="/cas/new" component={Form} />
            <Route path="/certificate_manager" component={CertificateManager}/>
            <Route path="/end_entities/new" component={Form2} />
            <Route path="/subca" component={CaTable} />
            <Route path="/administration" exact component={Administration} />
        </div>
      </Switch>
  )
}
  })


