import React, { Component } from 'react';
import { connect } from 'react-redux';
import CertStep1 from './Form2/CertStep1';
import CertStep2 from './Form2/CertStep2';
import CertStep3 from './Form2/CertStep3';
import CertStep4 from './Form2/CertStep4';
import CertStep5 from './Form2/CertStep5';
import CertStep6 from './Form2/CertStep6';

const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        importedCSR: state.importedCSR,
        subjectDN: state.subjectDN,
        eeOrganization: state.eeOrganization,
        certDayDuration: state.certDayDuration,
        emptyEEOrganizationNameError: state.emptyEEOrganizationNameError,
        emptyEESubjectDnNameError: state.emptyEESubjectDnNameError,
        emptyEEDaysError: state.emptyEEDaysError,
        allowNextStepForm2: state.allowNextStepForm2,
        updateDomainError: state.updateDomainError,
        inputDomains: state.inputDomains,
        caFormType: state.caFormType,
        form2Validation: state.form2Validation,
        csrTypeError: state.csrTypeError
    };
}

const mapDispatchToProps = {
      updateCurrentStepForm2: currentStep => {
            return { type: "CURRENT_COMPONENT_INFO_FORM_2", stepNumberForm2: currentStep}
      },
      updateImportedPubKey: data => {
          return { type: "IMPORTED_PUB_KEY", importedPubKey: data}
      },
      updateCsrError: data => {
          return { type: "CSR_ERROR", csrError: data }
      },
      updateEEOrganizationNameError: data => {
          return { type: "EMPTY_EE_ORGANIZATION_NAME_ERROR", emptyEEOrganizationNameError: data  }
      },
      updateEESubjectDnNameError: data => {
          return { type: "EMPTY_EE_SUBJECT_DN_NAME_ERROR", emptyEESubjectDnNameError: data }
      },
      updateEEemptyDaysError: data => {
          return { type: "EMPTY_EE_DAYS_ERROR", emptyEEDaysError: data }
      },
      updateDomainError: data => {
          return { type: "NOT_VALID_DOMAIN_ERROR", domainValidationError: data } 
      },
      updateSubjectDn: (input) => {
        return { type: "SUBJECT_DN", subjectDN: input  }
      },
      updateEeOrganization: (input) => {
          return { type: "EE_ORGANIZATION", eeOrganization: input }
      },
      updateEeCountry: (input) => {
          return { type: "EE_COUNTRY", eeCountry: input }
      }, 
      updateEeLocality: (input) => {
          return { type: "EE_LOCALITY", eeLocality: input }
      },
      updateEeState: (input) => {
          return { type: "EE_STATE", eeState: input }
      },
      updatePostalCode: data => {
          return { type: "UPDATE_POSTAL_CODE", eePostalCode: data }
      },
      updateUnit: data => {
          return { type: "UPDATE_UNIT", eeUnit: data }
      },
      updateStreet: data => {
          return { type: "UPDATE_STREET", eeStreet: data }
      },
      updateEmail: data => {
          return { type: "UPDATE_EMAIL", eeEmail: data }
      },
      updateCertificateProfile: data => {
          return { type: "UPDATE_CERTIFICATE_PROFILE", eeCertProfile: data }
      },
      updateDomainName: input => {
          return { type: "UPDATE_DOMAIN_NAME", domainName: input }
      },
      updateDomainBuf: input => {
          return { type: "UPDATE_DOMAIN_BUFFER", domainBuf: input }
      },
      updateDomains: input => {
          return { type: "UPDATE_DOMAIN_ARRAY", inputDomains: input }
      },
      updateNextStepAllowForm2: allow => {
          return { type: "ALLOW_NEXT_STEP_FORM2", allowNextStep: allow }
      },
      updateCertDayDuration: days => {
        return { type: "UPDATE_CERT_DAY_DURATION", certDayDuration: days }
      },
      updateCertDateDuration: total => {
          return { type: "UPDATE_CERT_DATE_DURATION", certDateDuration: total }
      },
      updateCertYearDuration: years => {
          return { type: "UPDATE_CERT_YEAR_DURATION", certYearDuration: years }
      },
      updateCsrError: data => {
          return { type: "CSR_ERROR", csrError: data }
      },
      updateImportedCSR: data => {
          return { type: "IMPORTED_CSR", importedCSR: data }
      },  
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class Form2 extends Component {

    componentDidMount() {
      this.props.updateCsrError(false)
      this.props.updateImportedPubKey(false)
      this.props.updateImportedCSR(false)
      this.props.updateSubjectDn('')
      this.props.updateEeOrganization('')
      this.props.updateEeCountry({ value:"US", label: "United States"})
      this.props.updateEeLocality('')
      this.props.updateEeState('')
      this.props.updatePostalCode('')
      this.props.updateUnit('')
      this.props.updateStreet('')
      this.props.updateEmail('')
      this.props.updateCurrentStepForm2(1)
      this.props.updateDomainName('')
      this.props.updateDomainBuf('')
      this.props.updateDomains([])
      this.props.updateNextStepAllowForm2(true)
      this.props.updateCertDayDuration('')
      this.props.updateCertDateDuration('')
      this.props.updateCertYearDuration('')
      this.props.updateCertificateProfile('')
    }

    handleOnclick = (event) => {
      event.preventDefault()
      let step = 1
      this.props.updateCurrentStepForm2(step)
    }

    handleOnclick1 = (event) => {
      event.preventDefault()
      let step = 2
      this.props.updateCurrentStepForm2(step)
    }

    handleOnclick2 = (event) => {
      event.preventDefault()
      let step = 3
      this.props.updateCurrentStepForm2(step)
    }

    handleOnclick3 = (event) => {
      event.preventDefault()
      let step = 4
      this.props.updateCurrentStepForm2(step)
    }

    handleOnclick5 = (event) => {
      event.preventDefault()
      let step = 5
      this.props.updateCurrentStepForm2(step)
    }

    validationIpDomain = (input) => {
        let validation = /^(^\*\.|[a-z,0-9,\-])+\.([a-z\-]+\.[a-z\-]+\.[a-z]+|[a-z\-]+\.[a-z]+|[a-z]+)$/.test(input);
        if (validation) {
            validation = false;
            let chekmore = /^[^\-]+/.test(input);
            if (chekmore) {
                if (input.length <= 253) { 
                    validation = 'domain';
                }
            }
        }

        if (!validation) {
            let regexp = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/ 
            let checkmore = regexp.test(input)
            if (checkmore) {
                validation = 'ip'
            }
        }
        return validation
    }


    next = () => {
        let step1valid = true;
        let validation = this.props.form2Validation
        let errors = []

        Object.keys(validation).map( (field, i) => {          
          if (!validation[field]) {
            step1valid = false
            errors[field] = true
          }
        })
        
        let currentStep = this.props.stepNumberForm2



        if (this.props.caFormType == 'oauth' && currentStep == 2) {
          currentStep = 3;
        }

        if (this.props.subjectDN === "") {
          this.props.updateEESubjectDnNameError(true)
          this.props.updateCurrentStepForm2(1)
        } else if (this.props.certDayDuration <= 0 && currentStep == 4) {
          this.props.updateEEemptyDaysError(true);
        } else if ((this.props.caFormType != 'oauth'&& !this.props.allowNextStepForm2 && currentStep == 3) || (this.props.caFormType != 'oauth' && this.props.inputDomains.length < 1 && currentStep == 3)) {
          this.props.updateDomainError(true)
        } else if (currentStep == 1 && !step1valid) {
          this.props.updateEEOrganizationNameError(errors) //the array of errors for the step 1
          this.props.updateCurrentStepForm2(1)
        }  else {
            this.props.updateEEemptyDaysError(false);
            this.props.updateDomainError(false)
            this.props.updateEESubjectDnNameError(false)
            this.props.updateEEOrganizationNameError(false)
            
        // If the current step is 1,2,3,4,6 or 7, then add one on "next" button click
        currentStep = currentStep >= 4? 5: currentStep + 1
        this.props.updateCurrentStepForm2(currentStep)
        
      }
    }

    prev = () => {
        const currentStep = this.props.stepNumberForm2
        if (this.props.caFormType == 'oauth' && currentStep == 4) {
          currentStep = 3;
        }
        // If the current step is 2,3,4,5,6 or 7, then subtract one on "previous" button click
        currentStep = currentStep <= 1? 1: currentStep - 1
        this.props.updateCurrentStepForm2(currentStep)
      }

    get nextButton(){
        const currentStep = this.props.stepNumberForm2
        if(currentStep < 5) {
            return (
                <button role="next_button"
                    className="bg-black hover:bg-palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1"
                    type="button" onClick={this.next}>
                Next
                </button>
            )
        }
        return null;
    }

    get previousButton(){

        const currentStep = this.props.stepNumberForm2;
        // If the current step is not 1, then render the "previous" button
        if(currentStep !==1 && currentStep !== 5){
          return (
            <button role="prev_button"
              className="bg-black hover:palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1"  
              type="button" onClick={this.prev}>
            Prev
            </button>
          )
        }
        // ...else return nothing
        return null;
      }
    
    render() {
        const stepNumberForm2 = this.props.stepNumberForm2
        const caFormType = this.props.caFormType
        return (
            <div>
        <div className="flex bg-gray-100"> 
          <div className="hidden md:block flex-row px-2 sm:px-6 border-r-2 border-palette-5 text-center sm:text-left w-full md:w-1/3 lg:w-1/6 bg-gray-100"> 
            <h1 className="text-xl sm:text-3xl py-4 sm:py-10 ml-10 text-gray-700 sidebar-header">Request cert</h1>
              <p className={stepNumberForm2 === 1 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={this.handleOnclick}>Step 1: Select</p>
              <p className={stepNumberForm2 === 2 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={this.handleOnclick1}>Step 2: Select CA</p> 
              {caFormType != 'oauth' && <p className={this.props.stepNumberForm2 === 3 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={this.handleOnclick2}>Step 3: Add domain names</p>}
              <p className={stepNumberForm2 === 4 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={this.handleOnclick3}>{this.props.caFormType == 'oauth' ? <span>Step 3</span> : <span>Step 4</span>}: Expiration Date</p> 
              <p className={stepNumberForm2 === 5 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2 cursor-not-allowed" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4 cursor-not-allowed"}>{this.props.caFormType == 'oauth' ? <span>Step 4</span> : <span>Step 5</span>}: Review</p>
          </div> 
          <hr />
          <div className="px-0 sm:px-8 py-4 sm:py-10 h-75 h-screen text-center sm:text-left sm:w-full bg-gray-100 form2-height"> 
            <div>
              <CertStep1 validation={this.validationIpDomain}/> 
              <CertStep2/>
              {caFormType != 'oauth' && <CertStep3 validation={this.validationIpDomain} />}
              <CertStep4/> 
              <CertStep5/>
              <CertStep6/>     
            </div>
            <div className="py-2 flex items-center justify-center">
                {this.previousButton}
                {this.nextButton}
              </div>
          </div> 
        </div>
        
       
        </div>
        );
    }
}

  )
