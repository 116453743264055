import React, { Component } from "react"

import Step1 from "./Form1/Step1"
import Step2 from "./Form1/Step2"
import Step3 from "./Form1/Step3"
//import Step4 from "./Form1/Step4"
import Step5 from "./Form1/Step5"
import Step6 from "./Form1/Step6"
import Step7 from "./Form1/Step7"




import { connect } from "react-redux"

  

  const mapStateToProps = state => {
    return {
      stepNumber: state.stepNumber,
      idFromSelectedCa: state.idFromSelectedCa,
      organization: state.organization,
      commonName: state.commonName,
      emptyCommonNameError: state.emptyCommonNameError,
      emptyOrganizationNameError: state.emptyOrganizationNameError,
      viewCaType: state.viewCaType,
    };
  };

  const mapDispatchToProps = {
    updateCurrentStep: currentStep => {
      return { type: "CURRENT_COMPONENT_INFO", stepNumber: currentStep}
    },
    updateRevocationWidow: (revocationWindow) => {
      return { type: "UPDATE_REVOCATION_WINDOW", revocationList: revocationWindow}
    },
    updateCurrentWindow: infoWindow => {
      return { type: "INFO_ABOUT_WINDOW", keyAlgorithmWindow: infoWindow}
    },
    updateOrganizationNameError: data => {
      return { type: "EMPTY_ORGANIZATION_NAME_ERROR", emptyOrganizationNameError: data}
    },
    updateCommonNameError: data => {
      return { type: "EMPTY_COMMON_NAME_ERROR", emptyCommonNameError: data}
    },
    updateTagName: (newEmail) => {
      return { type: "UPDATE_TAG_NAME", tagName: newEmail}
    },
    updateTagNameValue: (newValue) => {
      return { type: "UPDATE_TAG_NAME_VALUE", tagNameValue: newValue}
    },
    updateTagArray: (data) => {
      return { type: "UPDATE_TAG_ARRAY", inputTags: data}
    },
    updateTagResults: (data) => {
      return { type: "UPDATE_TAG_RESULTS", tagNameValue: data}
    },
    updateTagNumResults: (data) => {
      return { type: "UPDATE_TAG_RESULTS_NUMBER", tagName: data}
    },
    updateTagsBuf: (data) => {
      return { type: "UPDATE_TAGS_BUF", tagsBuf: data}
    }
    
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class Form extends Component {

    componentDidMount() {
      //clear tags:
      this.props.updateTagArray([])
      this.props.updateTagResults('')
      this.props.updateTagNumResults('')
      this.props.updateCurrentStep(2)
      this.props.updateTagName('')
      this.props.updateTagNameValue('')
      this.props.updateTagsBuf([])
    }

     // This function pickup value and call mapDispatch redux metod and return current step on STEP 1
    handleOnclick = (event, step) => {
      event.preventDefault()
      this.props.updateCurrentStep(step)
   }

  


    next = () => {
      // If the current step is 1,2,3,4,6 or 7, then add one on "next" button click
      if (this.props.commonName === ""){
        this.props.updateCommonNameError(true)
      } else {
      this.props.updateOrganizationNameError(false)
      this.props.updateCommonNameError(false)
      let currentStep = this.props.stepNumber
      currentStep = currentStep >= 6? 7: currentStep + 1
      if (currentStep == 4) {
        currentStep = 5;
      }
      this.props.updateCurrentStep(currentStep)
      let revocationWindow = false
      this.props.updateRevocationWidow(revocationWindow)
      let infoWindow = false
      this.props.updateCurrentWindow(infoWindow)
      }
    }
      
    prev = () => {
      let currentStep = this.props.stepNumber
      // If the current step is 2,3,4,5,6 or 7, then subtract one on "previous" button click
      currentStep = currentStep <= 2 ? 2 : currentStep - 1
      if (currentStep == 4) {
        currentStep = 3;
      }
      this.props.updateCurrentStep(currentStep)
    }


    get previousButton(){

      const currentStep = this.props.stepNumber;
      // If the current step is not 1, then render the "previous" button
      if(currentStep !== 2 && currentStep !== 7){
        return (
          <button role="prev"
            className="bg-black text-white border-none font-bold py-2 px-4 rounded mr-1"  
            type="button" onClick={this.prev}>
          Prev
          </button>
        )
      }
      // ...else return nothing
      return null;
    }
    
    get nextButton(){
      const currentStep = this.props.stepNumber;
      // If the current step is not 3, then render the "next" button
      if(currentStep <7){
        return (
          <button role="next"
          className="bg-black text-white font-bold py-2 px-4 rounded " 
            type="button" onClick={this.next}>
          Next
          </button>  
                
        )
      }
      // ...else render nothing
      return null;
    }



    render() { 
      const header = this.props.viewCaType === 'ROOTCA' ? 'Create CA' : 'Create a SubCa'
      const stepNumber = this.props.stepNumber
      return (
        <div>

        <div className="flex"> 
          <div className="hidden md:block flex-row px-2 sm:px-6 border-r-2 border-palette-5 text-center sm:text-left w-full md:w-1/3 lg:w-1/6 bg-gray-100"> 
            <h1 className="text-xl sm:text-3xl py-4 sm:py-10 ml-10 text-gray-700 sidebar-header">{header}</h1>
              <p className={stepNumber === 2 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={(e) => this.handleOnclick(e, 2)}>Step 1: Configure CA subject name</p> 
              <p className={stepNumber === 3 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={(e) => this.handleOnclick(e, 3)}>Step 2: Configure CA key algorithm</p> 
              {/*<p className={stepNumber === 4 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={this.handleOnclick3}>Step 3: Configure revocation</p>*/}
              <p className={stepNumber === 5 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={(e) => this.handleOnclick(e, 5)}>Step 4: Add Tags</p>  
              <p className={stepNumber === 6 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-pointer border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 no-underline hover:underline cursor-pointer pl-4"} onClick={(e) => this.handleOnclick(e, 6)}>Step 5: Configure CA</p>
              <p className={stepNumber === 7 ? "font-bold pb-2 text-palette-5 text-sm sm:text-sm cursor-not-allowed border-l-4 border-palette-5 pl-2" : " pb-2 text-sm sm:text-sm text-center sm:text-left text-gray-600 cursor-not-allowed pl-4"} >Step 6: Review</p>
          </div> 
          <hr />
          <div className="px-0 sm:px-8 py-4 sm:py-10 h-75 h-screen text-center sm:text-left sm:w-full bg-gray-100 {this.props.stepNumber === 7 ? form-step7-height }"> 
            <div>
              <Step2/>
              <Step3/>
              {/*<Step4/>*/} 
              <Step5/> 
              <Step6/>
              <Step7/>      
            </div>
            <div className="py-2 flex items-center justify-center">
                {this.previousButton}
                {this.nextButton}
              </div>
          </div> 
        </div>
        
       
        </div>
      )
      }
  }

)
