import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom"

import image1 from "../../images/cert1.png"
import image2 from "../../images/cert2.png"
import image5 from "../../images/info.png"
import Pagination from "../Pagination"
import { FaEdit, FaThumbsDown } from "react-icons/fa";
import { func } from 'prop-types';
import image6 from "../../images/noData.jpeg"
import {getHelperDownloadClick} from '../../helpers.js'

var moment = require('moment');

// Redux where we import state
const mapStateToProps = state => {
    return {
        mainNavBar: state.mainNavBar,
        certDuration: state.certDuration,
        endEntityArray: state.endEntityArray,
        selectedEndEntity: state.selectedEndEntity,
        cmPageTabs: state.cmPageTabs,
        EeName: state.EeName,
        messageWindow: state.messageWindow,
        eeSelectedTeam: state.eeSelectedTeam,
        dropdownEeTeam: state.dropdownEeTeam,
        team: state.team,
        cas: state.cas,
        arrayWithSubCas: state.arrayWithSubCas,
        subCaEEId: state.subCaEEId,
        stepNumberForm2: state.stepNumberForm2,
        subjectDN: state.subjectDN,
        certificateManagerSpinner: state.certificateManagerSpinner,
        optionButtonCertificateManagerPage: state.optionButtonCertificateManagerPage,
        optionButtonDropDownManagerPage: state.optionButtonDropDownManagerPage,
        requestButtonDropDownManagerPage: state.requestButtonDropDownManagerPage,
        eeSelect: state.eeSelect,
        stateCASelection: state.stateCASelection,
        eeSelectType: state.eeSelectType,
        checkboxes: state.checkboxes,
        alertPopup: state.alertPopup,
        alertConfirm: state.alertConfirm,
        pagination: state.pagination,
        page: state.page,
        perPage: state.perPage,
        certificateManagerSpinner: state.certificateManagerSpinner
    };
}

 // Redux where we dispatch state
const mapDispatchToProps = {
    updateMainNavBar: data => {
        return { type: "UPDATE_MAIN_NAV_BAR", mainNavBar: data }
    },
    updateCheckboxes: data => {
        return { type: "UPDATE_CHECKBOXES", checkboxes: data }
    },
    updateStateCASelection: data => {
        return { type: "UPDATE_STATE_SELECTION", stateCASelection: data }
    },
    updateMainNavBar: data => {
        return { type: "UPDATE_MAIN_NAV_BAR", mainNavBar: data }
    },
    updateCertDuration: days => {
        return { type: "UPDATE_CERT_DURATION", certDuration: days }
    },
    updateEndEntityArray: res => {
        return { type: "UPDATE_END_ENTITY_ARRAY", endEntityArray: res }
    },
    updateSelectedEndEntity: res => {
        return { type: "UPDATE_SELECTED_END_ENTITY", selectedEndEntity: res }
    },
    updateCmPageTabs: status => {
        return { type: "UPDATE_CM_PAGE_TAB", cmPageTabs: status }
    },
    updateEeName: name => {
        return { type: "UPDATE_EE_NAME", EeName: name }
    },
    updateMessageWindow: hover => {
        return { type: "UPDATE_MESSAGE_WINDOW", messageWindow: hover }
    },
    updateEeSelectedTeam: data => {
        return { type: "EE_SELECTED_TEAM", eeSelectedTeam: data}
    },
    updateDropdownEeTeam: data => {
        return { type: "DROPDOWN_EE_TEAM", dropdownEeTeam: data}
    },
    updateTeam: data => {
        return { type: "TEAM", team: data}
    },
    updateCas: data => {
        return { type: "UPDATE_CAS", cas: data }
    },
    updateSubCaEEId: data => {
        return { type: "SUB_CA_EE_ID", subCaEEId: data}
    },
    updateCurrentStepForm2: currentStep => {
        return { type: "CURRENT_COMPONENT_INFO_FORM_2", stepNumberForm2: currentStep}
    },
    updateSubjectDn: (input) => {
        return { type: "SUBJECT_DN", subjectDN: input  }
    },
    updateSelectedCaObject: selectedObject => {
        return { type: "UPDATE_SELECTED_CA_OBJECT", selectedCaObject: selectedObject}
    },
    updateCertificateManagerSpinner: data => {
        return { type: "CERTIFICATE_MANAGER_SPINNER", certificateManagerSpinner: data}
    },
    updateOptionButtonCertificateManagerPage: data => {
        return { type: "OPTION_BUTTON_CERTIFICATE_MANAGER_PAGE", optionButtonCertificateManagerPage: data  }
    },
    updateOptionButtonDropDownManagerPage: data => {
        return { type: "OPTION_BUTTON_DROPDOWN_MANAGER_PAGE", optionButtonDropDownManagerPage: data  }
    },
    updateRequestButtonDropDownManagerPage: data => {
        return { type: "REQUEST_BUTTON_DROPDOWN_MANAGER_PAGE", requestButtonDropDownManagerPage: data  }
    },
    updateEESelect: data => {
        return { type: "EE_SELECT", eeSelect: data}
    },
    updateEESelectType: data => {
        return { type: "EE_SELECT_TYPE", eeSelectType: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    showAlert: (data) => {
      return { type: "UPDATE_ALERT", alertPopup: data }
    },
    updateAlertConfirm: (data) => {
      return { type: "UPDATE_ALERT_CONFIRM", alertConfirm: data }
    },
    updateCaFormType: data => {
        return { type: "UPDATE_CA_FORM_TYPE", caFormType: data }
    },
    updateEndEntityProfile: data => {
        return { type: "UPDATE_END_ENTITY_PROFILE", eeEndEntityProfile: data }
    },
    updatePagination: pagination => {
      return { type: "UPDATE_PAGINATION", pagination: pagination}
    },
    changePage: page => {
        return { type: "UPDATE_PAGE_NUMBER", page: page }
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class EndEntities extends Component {

    //Here we are updating state for nav bar
    componentDidMount() {
        this.props.updateCertificateManagerSpinner(true)
        this.props.updateMainNavBar('certificateManager')
        this.props.updateRequestButtonDropDownManagerPage(false)
        this.props.updateOptionButtonDropDownManagerPage(false)
        this.props.updateCmPageTabs(['info'])
        this.props.showAlert(false)
        this.props.updateAlertConfirm(false)
        this.detectSelected(this.props.eeSelectedTeam)
    }

    fetchEeCerts = (page, perPage, filter_type=false, filter_status=false, subca=false) => {
        this.props.changeSpinner(true)

        if (!filter_type) {
            filter_type = this.props.eeSelectType
        }

        if (!filter_status) {
            filter_status = this.props.eeSelect
        }

        if (!subca) {
            subca = this.props.subCaEEId
        }
        
        let url = "/admin/end_entities?page="+page

        if (filter_status && filter_status != 'all') {
            url += '&q[in_use_eq]='+filter_status
            this.props.updateEESelect(filter_status)
        }

        if (filter_type && filter_type != 'all') {
            url += '&q[certificate_type_eq]='+filter_type  
            this.props.updateEESelectType(filter_type) 
        }

        if (subca && subca !== 'all') {
            url += '&q[ca_id_eq]='+subca
        }

        if (!perPage) {
            perPage = this.props.perPage
        }

        url += '&per_page='+perPage
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
        })
        .then(res => res.json())
        .then(res => {
            if (typeof(res.pagy) != 'undefined' && typeof(res.entities) != 'undefined') {
            this.props.updateEndEntityArray(res)
            this.props.updateEeSelectedTeam(res.entities)
            this.props.updatePagination(res.pagy)
            this.props.changePage(page)
            this.detectSelected(res.entities)
            this.props.changeSpinner(false)
            }
        })   
    }

    componentDidUpdate() {
        let alertConfirm = this.props.alertConfirm
        if (alertConfirm) {
            switch (alertConfirm) {
                case 'revoke':
                    this.props.updateOptionButtonDropDownManagerPage(false)
                    let checkboxes = this.props.checkboxes;
                    if (checkboxes.length == 0) {
                        checkboxes = [this.props.selectedEndEntity[0].id]
                    }

                    checkboxes.map ( (check) => {
                        this.submitRevoke(check)
                    })
                break;
            }
        }       
    }

    detectSelected = (entities) => {
        if (entities.length > 0) {
            this.handleRadioButton(entities[0].id)
            this.props.updateSelectedEndEntity([entities[0]])
            this.props.updateCheckboxes([entities[0].id])
        }
    }

    //This function will download ee certificate chain
    downloadTxtFile = (cert_id) => {
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/admin/end_entities/"+cert_id, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf
              }
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.error) {
                    this.props.updateCertificateManagerSpinner(false)
                    this.props.updateNotification({status: 'error', text: res.message})
                } else {
                    getHelperDownloadClick(res, '_EE')
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'success', text: 'End Entity downloaded'})
                }
            })
    }

    
    handleNameIcon = (value) => {
        let status = "nameUpdate"
        let tabs = this.props.cmPageTabs;
        let filtered_tabs = tabs.filter( ee => ee != status)
        filtered_tabs.push(status)
        this.props.updateCmPageTabs(filtered_tabs)
        this.setCheckbox(value)
    }


    handleCheckbox = (val) => {
        if (val) {
            let checkboxes = this.props.checkboxes
            let first_el = checkboxes[0]
            let checked = checkboxes.filter( ee => ee == val)
            if (checked.length > 0) {
                checked = true
            } else {
                checked = false
            }
            let filtered = checkboxes.filter( ee => ee != val)
            if (!checked) {
                filtered.push(val)
            }

            if (filtered.length == 0) {
                this.props.updateNotification({status: 'notify', text: 'At least one certificate should be selected'})
                filtered.push(first_el)
            }
            this.handleRadioButton(filtered[0])
            this.props.updateCheckboxes(filtered) 
        }
    }

    setCheckbox = (val) => {
        if (val) {
            let checkboxes = this.props.checkboxes
            let filtered = checkboxes.filter( ee => ee != val)
            filtered.push(val)
            this.handleRadioButton(val)
            this.props.updateCheckboxes(filtered) 
        }
    }

    handleRadioButton = (selected_ee_id) => {
        this.props.updateRequestButtonDropDownManagerPage(false) 
        this.props.updateOptionButtonDropDownManagerPage(false) 
        let endEntityArray = this.props.eeSelectedTeam
        let filteredEndEntity = endEntityArray.filter(ee => ee.id == selected_ee_id)
        this.props.updateSelectedEndEntity(filteredEndEntity)
        this.props.updateOptionButtonCertificateManagerPage(true)
    }

    handleNameChange = (e) => {
        this.props.updateRequestButtonDropDownManagerPage(false) 
        this.props.updateOptionButtonDropDownManagerPage(false) 
        let name = e.target.value
        this.props.updateEeName(name)
        
    }

    handleOptionButton = () => {
        this.props.updateRequestButtonDropDownManagerPage(false)   
        this.props.updateOptionButtonDropDownManagerPage(!this.props.optionButtonDropDownManagerPage)
    }

    handleDropdownsOut = (e) => {
        if (this.props.optionButtonDropDownManagerPage) {
            this.props.updateOptionButtonDropDownManagerPage(false)      
        }
    }

    handleRevokeButton = async() => {
        this.props.showAlert({action: 'revoke', text: 'This action will revoke all the selected certificates. Are you sure?', header: 'Revoke selected certificates'})
    }

    submitRevoke = async(id) => {
        this.props.changeSpinner(true)
        this.props.updateAlertConfirm(false)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/admin/end_entities/"+id, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf
              },
              body: JSON.stringify(
                  {
                    in_use: "revoked"
                  }
              )
            })
            .then(res => {
                return res.json()
            }).then(res => {
                if (res) {
                    if (res.error) {
                        this.props.updateNotification({status: 'error', text: res.message})
                        this.props.changeSpinner(false)
                    } else {
                        let checkboxes = this.props.checkboxes
                        let subca = this.props.subCaEEId
                        this.fetchEeCerts(1, false, false, 'revoked', subca)
                        this.props.updateEESelect('revoked')
                        this.props.updateCheckboxes(checkboxes)
                        this.props.changeSpinner(false)
                        this.props.updateNotification({status: 'success', text: res.message})
                    }
                } else {
                    this.props.changeSpinner(false)
                }
        })  
    }

    submitNameChange = async() => {
        this.props.changeSpinner(true)
        let cert = this.props.selectedEndEntity[0]
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        await fetch("/admin/end_entities/"+cert.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf
        },
        body: JSON.stringify(
          {
           name: this.props.EeName
        })
      })
      .then(res => {
        if (res.status != 200) {
            this.props.updateNotification({status: 'error', text: 'Certificate name update failed'})
            //throw new TypeError("Error!")
        }
        return res.json()
    })
      .then(res => {
        cert.name = this.props.EeName
        let ee_array = this.props.eeSelectedTeam
        let index = false
        let filtered = ee_array.filter( (ee, ind) => ee.id === cert.id ? index = ind : null)
        if (index) {
            ee_array[index] = cert
            this.props.updateEeSelectedTeam(ee_array)
        }
        //hide name input field:
        this.props.updateCmPageTabs(['info'])
        this.props.updateNotification({status: 'success', text: 'Certificate name updated!'})
        this.props.changeSpinner(false)
      }).bind(this)
    }

    handleEESelect = () => {
        event.preventDefault()
        let data = event.target.value
        let team = this.props.team
        let subca = this.props.subCaEEId
        this.props.updateEESelect(data)
        this.fetchEeCerts(1, false, false, data, subca)
    }

    handleEESelectType = () => {
        event.preventDefault()
        let data = event.target.value
        let team = this.props.team
        let subca = this.props.subCaEEId
        this.props.updateEESelectType(data)
        this.fetchEeCerts(1, false, data, false, subca)
    }

    downloadSelected = () => {
        this.props.updateOptionButtonDropDownManagerPage(false)
        let endEntityArray = this.props.eeSelectedTeam
        let checkboxes = this.props.checkboxes;

        if (checkboxes.length == 0) {
            checkboxes = [this.props.selectedEndEntity[0].id]
        }
        
        checkboxes.map ( (check) => {
            let filteredEndEntity = endEntityArray.filter(ee => ee.id == check) 
            this.downloadTxtFile(filteredEndEntity[0].id)
        })
    }

    domainIpView = (data) => {
        let res = data.replace(/dNSName=/g, '\n')
        res = res.replace(/ipaddress=/g, '\n')
        return res
    }

    fetchCallback = (page, perPage) => {
        let subca = this.props.subCaEEId
        this.fetchEeCerts(page, perPage, false, false, subca)
    }

    handleSubcaSelect = (subca_id) => {
        this.props.updateSubCaEEId(subca_id)
        this.fetchEeCerts(1, false, false, false, subca_id)
    }

    render() {
        const checkboxes = this.props.checkboxes
        const spinner = this.props.certificateManagerSpinner
        const messageWindow = this.props.messageWindow
        const arrayWithSubCas = this.props.arrayWithSubCas
        const selectedEndEntity = this.props.selectedEndEntity
        const eeSelectedTeam = this.props.eeSelectedTeam 
        const optionButtonCertificateManagerPage = this.props.optionButtonCertificateManagerPage
        const team = this.props.team
        const endEntityArray = this.props.endEntityArray
        const eeSelect = this.props.eeSelect
        const eeSelectType = this.props.eeSelectType
        const subCaEEId = this.props.subCaEEId
        const cmPageTabs = this.props.cmPageTabs
        const EeName = this.props.EeName
        const optionButtonDropDownManagerPage = this.props.optionButtonDropDownManagerPage
        const requestButtonDropDownManagerPage = this.props.requestButtonDropDownManagerPage
        const cas = this.props.cas //teams array
        const pagination = this.props.pagination
        const page = this.props.page

        let multiple = false;
        if (checkboxes.length > 1) {
            multiple = true;
        }

        let showFunctionality = true 
        if (eeSelectedTeam && eeSelectedTeam.length == 0 && subCaEEId == 'all') {
            showFunctionality = false
        }

        console.log('====')
        console.log(showFunctionality)
        console.log(arrayWithSubCas)

        return (

            <div className="bg-palette-2 mb-10 sm:mb-0 h-screen w-full" onClick={this.handleDropdownsOut}>
           
                    
                <div className="flex"> 
                    <div className="px-0 sm:px-2 lg:px-8 pb-4 py-4 sm:py-10 text-center sm:text-left bg-palette-2 w-full">
                          
                            {multiple &&  <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 my-6 mx-10 shadow-md border-palette-5" role="alert"> 
                                    <div className="flex"> 
                                        <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p className="font-bold text-palette-5" >Info</p>
                                                <p className="text-sm">select one checkbox only to see it's detailed information and use more actions for the selected certificate</p>
                                            </div>
                                        </div>
                                </div>}

                     

                            {showFunctionality && <div className="lg:flex lg:flex-row" >
                                {arrayWithSubCas && arrayWithSubCas.length > 0 &&
                                    <select className="bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 rounded text-sm pt-2 mt-3 h-10 ml-1 mr-1 md:ml-7 w-12/12 w-full md:w-3/12" defaultValue={subCaEEId} onChange={(e) => this.handleSubcaSelect(e.target.value)}>
                                        <option value="all">all</option>
                                        {arrayWithSubCas.map( (subca) => {
                                            return (<option value={subca.id}>{subca.common_name}</option>)
                                        })}
                                    </select>
                                }

                                {optionButtonCertificateManagerPage !== false && eeSelectedTeam.length > 0 &&
                                    <button role="options_btn" onClick={this.handleOptionButton} className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 sm:px-4 rounded mx-4 my-4">
                                    Options
                                    </button>
                                }

                               
                            </div>}

                            <div className="flex flex-row">
                                {optionButtonDropDownManagerPage !== false &&
                                    <div className="z-index dropdown-options dropdown-options-ca-option-admin bg-palette-4 ml-96 mt-16 rounded">
                                    <div className="bg-palette-4 hover:bg-palette-5 py-2 text-center">  
                                        <button role="options_download" className="mx-3 my-1 text-white font-bold focus:outline-none" onClick={this.downloadSelected}>Download EE cert</button>
                                    </div> 
                                    <div className="bg-palette-4 hover:bg-palette-5 py-2 text-center">  
                                        <button role="options_revoke" className="mx-3 my-1 text-white font-bold focus:outline-none" onClick={this.handleRevokeButton}>Revoke EE cert</button>
                                    </div>  
                                    </div>
                                }
                            </div>
                            
                                  
                            
                           
                            
                            <div className="filter-area min-w-300px mt-4 sm:mt-10 w-full px-0 md:px-2 lg:px-10">
                                        <div className="float-right">
                                        <button role="filter_active" className={eeSelect === 'active' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="active" onClick={this.handleEESelect}>Active</button>
                                        <button role="filter_revoked" className={eeSelect === 'revoked' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="revoked" onClick={this.handleEESelect}>Revoked</button>
                                        <button role="filter_all" className={eeSelect === 'all' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="all" onClick={this.handleEESelect}>All</button>
                                        </div>
                                        <div className="float-left">
                                        <button role="filter_cert_tls" className={eeSelectType === 'TLS' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="TLS" onClick={this.handleEESelectType}>TLS</button>
                                        <button role="filter_cert_oauth" className={eeSelectType === 'Client Auth' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="Client Auth" onClick={this.handleEESelectType}>Client Auth</button>
                                        <button role="filter_cert_all" className={eeSelectType === 'all' ? "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button"} value="all" onClick={this.handleEESelectType}>All Types</button>
                                        </div>
                            </div>
                            
                            <div className="hidden lg:table h-300 mt-4 sm:mt-10 w-full px-0 md:px-2 lg:px-10">
                            <div className="flex flex-col">
                            <div className="flex-grow overflow-auto">
                              <table className="relative w-full border">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tl">Selected</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Name</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Subject DN</th>
                                    {/*<th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Domain name</th>*/}
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Type</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Status</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tr">Download</th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y ">
                                  {eeSelectedTeam.map( (ee, index) => (ee.in_use === eeSelect || eeSelect === "all") && (ee.certificate_type === eeSelectType || eeSelectType == 'all' || (ee.certificate_type === null && eeSelectType == 'TLS') ) &&
                                  <tr className={ee.in_use === "revoked" ? "bg-gray-300" : ""} key={index}>

                                    <td onClick={(e) => this.handleCheckbox(ee.id)} className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        {/*<input className="form-radio h-6 w-6" type="radio" name="key" id="2" checked={ee.id == selected} value={ee.id} onChange={ (e) => {} } onClick={(e) => this.handleRadioButton(e.target.value)} />*/}
                                        <input role="cert_checkbox" className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ee.id) ? 1 : 0}  value={ee.id} onClick={(e) => this.handleCheckbox(ee.id)} onChange={ (e) => {} } />

                                    </td>
                                   
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5"><p onClick={(e) => this.handleCheckbox(ee.id)}>{ee.name}</p>
                                        <button role="name_change" className="ml-3  text-palette-5 font-semibold  py-1 px-2 text-sm" value={cmPageTabs} onClick={() => this.handleNameIcon(ee.id)} >  
                                            <FaEdit/>
                                        </button>
                                    </td>
                                     <td onClick={(e) => this.handleCheckbox(ee.id)} className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        {ee.subject_dn}
                                    </td>
                                    {/*<td onClick={(e) => this.handleCheckbox(ee.id)} className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">{ee.domain_name && this.domainIpView(ee.domain_name)}</td>*/}
                                    <td onClick={(e) => this.handleCheckbox(ee.id)} className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">Private {ee.certificate_type ? <span>{ee.certificate_type}</span> : <span>TLS</span>}</td>
                                    <td onClick={(e) => this.handleCheckbox(ee.id)} className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">{ee.in_use}</td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        <button role="download_cert" onClick={() => this.downloadTxtFile(ee.id)} className="px-2 bg-palette-4 hover:bg-palette-5 text-white px-1 py-1 text-xs rounded">
                                            <p><i className="fa-solid fa-download"></i></p>
                                        </button>
                                    </td>
                                  </tr>)}
                                </tbody>
                              </table>
                            </div>
                            </div>
                            </div>


                            
                            <div className="visible lg:hidden w-full mt-2 mr-2 px-2 table">
                            <div className="w-full">
                            <div className="w-full overflow-y">
                              <table className="relative w-full">
 
                                <tbody>
                                  {eeSelectedTeam.map( (ee, index) => (ee.in_use === eeSelect || eeSelect === "all") && (ee.certificate_type === eeSelectType || eeSelectType == 'all' || (ee.certificate_type === null && eeSelectType == 'TLS') ) &&
                                  <div className="mx-3 my-4 border-2 rounded-tl rounded-bl border-palette-5 mobile-row">
                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center text-sm">Selected:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <input role="cert_checkbox" className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ee.id) ? 1 : 0}  value={ee.id} onClick={(e) => this.handleCheckbox(ee.id)} onChange={ (e) => {} } />
                                    </td>
                                  </tr>

                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Name:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        <p onClick={(e) => this.handleCheckbox(ee.id)}>{ee.name}</p>
                                        <button role="name_change" className="ml-3  text-palette-5 font-semibold  py-1 px-2 text-sm" value={cmPageTabs} onClick={() => this.handleNameIcon(ee.id)} >  
                                            <FaEdit/>
                                        </button>
                                    </td>
                                 </tr>

                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center text-sm">Subject DN:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        {ee.subject_dn && <span>{ee.subject_dn.replace(/\,/g, ', ')}</span>}
                                        {!ee.subject_dn && ee.common_name && <span>{ee.common_name}</span>}
                                    </td>
                                 </tr>

                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center text-sm">Domain Name:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                    {ee.domain_name && this.domainIpView(ee.domain_name)}
                                    </td>
                                 </tr>

                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Type:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                    Private {ee.certificate_type ? <span>{ee.certificate_type}</span> : <span>TLS</span>}
                                    </td>
                                 </tr>

                                  <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Status:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                    {ee.in_use}
                                    </td>
                                 </tr>

                                   <tr className={ee.in_use === 'revoked' ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ee.id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Edit:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        <button role="download_cert" onClick={() => this.downloadTxtFile(ee.id)} className="px-2 bg-palette-4 hover:bg-palette-5 text-white px-1 py-1 text-xs rounded">
                                            <p><i className="fa-solid fa-download"></i></p>
                                        </button>
                                    </td>
                                 </tr>

                                   

                                  </div>)}
                                </tbody>
                              </table>
                            </div>
                            </div>
                            </div>

                            <Pagination fetchCallback={this.fetchCallback} />

                            {cmPageTabs.length > 0  &&
                            <div className="py-1 bg-palette-2">

                                <ul className={cmPageTabs.includes("nameUpdate") ?  "text-left ml-12 text-xs sm:text-base lg:text-lg"  : "hidden"}>
                                    <p className="text-lg sm:text-xl mt-2 font-bold text-gray-700">Add/Update Name</p>
                                    <hr className="ml-4 mb-2 font-bold text-gray-600"/>
                                    <div className="md:flex md:items-center mb-6">
                                        <div className="md:w-1/3">
                                            <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                                            Name
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input 
                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600" 
                                                name="ee_name"
                                                type="text"
                                                placeholder="Add name"
                                                onChange={this.handleNameChange}
                                                value={EeName}
                                            />
                                        </div> 
                                        
                                    </div>
                                    <div className="content-center">
                                        <button role="name_save" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4" onClick={this.submitNameChange}>Update name</button>
                                    </div>
                                </ul>

                                {eeSelectedTeam.length > 0 && selectedEndEntity.length === 1 && <div className={cmPageTabs.includes("info") && !multiple ? "mt-32" : 'hidden'}>
                                    <div className="px-4 sm:px-8 max-w-5xl m-auto">
                                        <h1 className="text-center font-bold text-gray-600">Certificate Information</h1>
                                        <p className="mt-2 text-center text-sm mb-4 text-gray-600">{selectedEndEntity[0].name}</p>
                                        <ul className="border border-palette-5 rounded overflow-hidden shadow-md">
                                            {selectedEndEntity[0].subject_dn && <li key="details_1" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Subject DN:</b> {selectedEndEntity[0].common_name}</li>}
                                            {selectedEndEntity[0].issuer_dn && <li key="details_3" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Issuer DN:</b> {selectedEndEntity[0].issuer_dn}</li>}
                                            {selectedEndEntity[0].in_use && <li key="details_2" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Status:</b> {selectedEndEntity[0].in_use}</li>}
                                            {selectedEndEntity[0].certificate_type && <li key="details_5" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Type:</b> {selectedEndEntity[0].certificate_type}</li>}
                                            {selectedEndEntity[0].domain_name && <li key="details_6" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Subject Alternative Name:</b> {selectedEndEntity[0].domain_name}</li>}
                                            {selectedEndEntity[0].created_at && <li key="details_8" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Created at:</b> {moment(selectedEndEntity[0].created_at).format('MM-DD-YYYY, h:mm A')}</li>}
                                            {selectedEndEntity[0].effective_date && <li key="details_9" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Expiration date:</b> {moment(selectedEndEntity[0].expiration_date).format('MM-DD-YYYY, h:mm A')}</li>}
                                            {selectedEndEntity[0].expiration_date && <li key="details_10" className="px-4 py-2 text-gray-600 hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-palette-5 transition-all duration-300 ease-in-out"><b className="font-bold">Effective date:</b> {moment(selectedEndEntity[0].effective_date).format('MM-DD-YYYY, h:mm A')}</li>}                                            
                                        </ul>

                                    </div>
                                </div>}
                                
                          
                            </div>}
                            </div>
                        </div>
                </div>
                
                
        );
    }
}

)
