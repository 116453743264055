import React, { Component } from "react"

import { connect } from "react-redux"



// Redux where we import state
const mapStateToProps = state => {
    return {
      stepNumber: state.stepNumber,
      viewCaType: state.viewCaType,
      idFromSelectedCa: state.idFromSelectedCa
    };
  };

// Redux where we dispatch state
  const mapDispatchToProps = {
    updateCheckbox: (checked) => {
      return { type: "CA_TYPE", viewCaType: checked}
    },
    updateIdFromSelectedCa: id => {
      return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: id }
    }
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step1 extends Component {
  
    // This function pickup value and call mapDispatch redux metod
      handleCheckbox = (event) => {
      let checked = event.target.value
      this.props.updateCheckbox(checked)
    }
    


        render() {  
            const stepNumber = this.props.stepNumber
            const idFromSelectedCa = this.props.idFromSelectedCa
            if (stepNumber !== 1) {// Prop: The current step
                return null
            }
            
            return (
              <div>
                  <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Select the certificate authority (CA) type</p>
                      <hr className="ml-4"/>
                      <p className="text-base sm:text-xl text-gray-600 italic mt-3">CloudPKIs helps you create a private subordinate CA.</p>
                  </div> 
                  <div className="flex-col">
                    <div className={idFromSelectedCa !== "1" ? "hidden" : "flex  mt-6"}>
                      <div className="w-1/6 text-sm sm:text-2xl text-gray-700 mt-12 sm:mt-2 ml-5 sm:ml-3">
                        <label>
                          <input type="radio" name="ca" onChange={ (e) => {} } className="mx-2" name="ca" id="1" onChange={this.handleCheckbox} value="ROOTCA"  />
                            Root CA
                        </label>
                        </div>
                      <p className="w-5/6 text-sm sm:text-xl text-gray-700 px-4 ml-6 mr-3 text-left"> Create a root CA. Choose this option if you want to establish a new CA hierarchy.Create a root CA. Choose this option if you want to establish a new CA hierarchy.  </p>  
                    </div> 
                    <div className={idFromSelectedCa === "1" ? "hidden" : "flex  mt-6"}>
                      <div className="w-1/6 text-sm sm:text-2xl text-gray-700 mt-12 sm:mt-2 ml-3">
                      <label>
                          <input type="radio" name="ca" onChange={ (e) => {} } className="mx-2  " name="ca" id="2" onChange={this.handleCheckbox} value="SUBCA"  />
                            Subordinate CA
                      </label>
                      </div>
                      <p className="w-5/6 text-sm sm:text-xl text-gray-700 px-4 ml-6 mr-3 text-left"> Create a subordinate CA. Choose this option if you want to make a CA that is subordinate to an existing CA. You can use this option to create ossuing CAs as well as intermediate CAs.</p>
                    </div>
                  </div>      
              </div>
            )
            }
        }
      
      )
      