import React, { Component } from 'react';
import { connect } from 'react-redux';

const forge = require('node-forge');
const pkijs = require('pkijs');
const utils = require("pvtsutils");
const asn1js = require("asn1js");
// This is data for drop down input
const options = [
    { value:"US", label: "United States"},
    { value:"AF", label: "Afghanistan" },
    { value:"AX", label: "Aland Islands" },
    { value:"AL", label: "Albania"},
    { value:"DZ", label: "Algeria"},
    { value:"AS", label: "American Samoa"},
    { value:"AD", label: "Andorra"},
    { value:"AO", label: "Angola"},
    { value:"AI", label: "Anguilla"},
    { value:"AQ", label: "Antarctica" },
    { value:"AG", label: "Antigua & Barbuda"},
    { value:"AR", label: "Argentina"},
    { value:"AM", label: "Armenia"},
    { value:"AW", label: "Aruba"},
    { value:"AU", label: "Australia"},
    { value:"AT", label: "Austria"},
    { value:"AZ", label: "Azerbaijan"},
    { value:"BS", label: "Bahamas"},
    { value:"BH", label: "Bahrain"},
    { value:"BD", label: "Bangladesh"},
    { value:"BB", label: "Barbados"},
    { value:"BE", label: "Belgium"},
    { value:"BZ", label: "Belize"},
    { value:"BJ", label: "Benin"},
    { value:"BM", label: "Bermuda"},
    { value:"BT", label: "Bhutan"},
    { value:"BO", label: "Bolivia, Plurinational State of"},
    { value:"BQ", label: "Bonaire, Sint Eustatius and Saba"},
    { value:"BA", label: "Bosnia & Herzegovina"},
    { value:"BW", label: "Botswana"},
    { value:"BV", label: "Bouvet Island"},
    { value:"BN", label:"Brunei Darussalam"},
    { value:"BG",label: "Bulgaria"},
    { value:"BF",label: "Burkina Faso"},
    { value:"IO",label: "British Indian Ocean Ter"},
    { value:"BI",label: "Burundi"},
    { value:"BR",label: "Brazil"},
    { value:"KH",label: "Cambodia"},
    { value:"CM",label: "Cameroon"},
    { value:"CA",label: "Canada"},
    { value:"CV",label: "Cape Verde"},
    { value:"KY",label: "Cayman Islands"},
    { value:"CF",label: "Central African Republic"},
    { value:"TD",label: "Chad"},
    { value:"CL",label: "Chile"},
    { value:"CN",label: "China"},
    { value:"CX",label: "Christmas Island"},
    { value:"CC",label: "Cocos (Keeling) Island"},
    { value:"CO",label: "Colombia"},
    { value:"KM",label: "Comoros"},
    { value:"CG", label: "Congo"},
    { value:"CD", label: "Congo, Democratic Republic of the"},
    { value:"CK", label: "Cook Islands"},
    { value:"CR", label: "Costa Rica"},
    { value:"HR", label: "Croatia"},
    { value:"CW", label: "Curacao"},
    { value:"CY", label: "Cyprus"},
    { value:"CZ", label: "Czech Republic"},
    { value:"DK", label: "Denmark"},
    { value:"DJ", label: "Djibouti"},
    { value:"DM", label: "Dominica"},
    { value:"DO", label: "Dominican Republic"},
    { value:"EC", label: "Ecuador"},
    { value:"EG", label: "Egypt"},
    { value:"SV", label: "El Salvador"},
    { value:"GQ", label: "Equatorial Guinea"},
    { value:"ER", label: "Eritrea"},
    { value:"EE", label: "Estonia"},
    { value:"ET", label: "Ethiopia"},
    { value:"FK", label: "Falkland Islands (Malvinas)"},
    { value:"FO", label: "Faroe Islands"},
    { value:"FJ", label: "Fiji"},
    { value:"FI", label: "Finland"},
    { value:"FR", label: "France"},
    { value:"GF", label: "French Guiana"},
    { value:"PF", label: "French Polynesia"},
    { value:"TF", label: "French Southern Ter"},
    { value:"GA", label: "Gabon"},
    { value:"GM", label: "Gambia"},
    { value:"GE", label: "Georgia"},
    { value:"DE", label: "Germany"},
    { value:"GH", label: "Ghana"},
    { value:"GI", label: "Gibraltar"},
    { value:"GR", label: "Greece"},
    { value:"GL", label: "Greenland"},
    { value:"GD", label: "Grenada"},
    { value:"GP", label: "Guadeloupe"},
    { value:"GU", label: "Guam"},
    { value:"GT", label: "Guatemala"},
    { value:"GG", label: "Guernsey"},
    { value:"GN", label: "Guinea"},
    { value:"GW", label: "Guinea-Bissau"},
    { value:"GY", label: "Guyana"},
    { value:"HT", label: "Haiti"},
    { value:"HM", label: "Heard Island and Mcdonald Islands"},
    { value:"VA", label: "Holy See (Vatican City State)"},
    { value:"HN", label: "Honduras"},
    { value:"HK", label: "Hong Kong"},
    { value:"HU", label: "Hungary"},
    { value:"IS", label: "Iceland"},
    { value:"ID", label: "Indonesia"},
    { value:"IN", label: "India"},
    { value:"IQ", label: "Iraq"},
    { value:"IE", label: "Ireland"},
    { value:"IM", label: "Isle of Man"},
    { value:"IL", label: "Israel"},
    { value:"IT", label: "Italy"},
    { value:"JM", label: "Jamaica"},
    { value:"JE", label: "Jersey"},
    { value:"JP", label: "Japan"},
    { value:"JO", label: "Jordan"},
    { value:"KZ", label: "Kazakhstan"},
    { value:"KE", label: "Kenya"},
    { value:"KI", label: "Kiribati"},
    { value:"KR", label: "Korea, Republic of"},
    { value:"KW", label: "Kuwait"},
    { value:"KG", label: "Kyrgyzstan"},
    { value:"LA", label: "Lao People’s Democratic Republic"},
    { value:"LV", label: "Latvia"},
    { value:"LB", label: "Lebanon"},
    { value:"LS", label: "Lesotho"},
    { value:"LR", label: "Liberia"},
    { value:"LY", label: "Libya"},
    { value:"LI", label: "Liechtenstein"},
    { value:"LT", label: "Lithuania"},
    { value:"LU", label: "Luxembourg"},
    { value:"MO", label: "Macau"},
    { value:"MK", label: "North Macedonia"},
    { value:"MG", label: "Madagascar"},
    { value:"MY", label: "Malaysia"},
    { value:"MW", label: "Malawi"},
    { value:"MV", label: "Maldives"},
    { value:"ML", label: "Mali"},
    { value:"MT", label: "Malta"},
    { value:"MH", label: "Marshall Islands"},
    { value:"MQ", label: "Martinique"},
    { value:"MR", label: "Mauritania"},
    { value:"MU", label: "Mauritius"},
    { value:"YT", label: "Mayotte"},
    { value:"MX", label: "Mexico"},
    { value:"FM", label: "Micronesia (Federated States of)"},
    { value:"MD", label: "Moldova, Republic of"},
    { value:"MC", label: "Monaco"},
    { value:"MN", label: "Mongolia"},
    { value:"ME", label: "Montenegro"},
    { value:"MS", label: "Montserrat"},
    { value:"MA", label: "Morocco"},
    { value:"MZ", label: "Mozambique"},
    { value:"MM", label: "Myanmar"},
    { value:"NA", label: "Nambia"},
    { value:"NR", label: "Nauru"},
    { value:"NP", label: "Nepal"},
    { value:"NL", label: "Netherlands"},
    { value:"NC", label: "New Caledonia"},
    { value:"NZ", label: "New Zealand"},
    { value:"NI", label: "Nicaragua"},
    { value:"NE", label: "Niger"},
    { value:"NG", label: "Nigeria"},
    { value:"NU", label: "Niue"},
    { value:"NF", label: "Norfolk Island"},
    { value:"MP", label: "Northern Mariana Islands"},
    { value:"NO", label: "Norway"},
    { value:"OM", label: "Oman"},
    { value:"PK", label: "Pakistan"},
    { value:"PW", label: "Palau"},
    { value:"PS", label: "Palestine, State of"},
    { value:"PA", label: "Panama"},
    { value:"PG", label: "Papua New Guinea"},
    { value:"PY", label: "Paraguay"},
    { value:"PE", label: "Peru"},
    { value:"PH", label: "Philippines"},
    { value:"PN", label: "Pitcairn"},
    { value:"PL", label: "Poland"},
    { value:"PT", label: "Portugal"},
    { value:"PR", label: "Puerto Rico"},
    { value:"QA", label: "Qatar"},
    { value:"RE", label: "Reunion"},
    { value:"RO", label: "Romania"},
    { value:"RU", label: "Russia Federation"},
    { value:"RW", label: "Rwanda"},
    { value:"BL", label: "Saint Barthelemy"},
    { value:"SH", label: "Saint Helena, Ascension and Tristan da Cunha"},
    { value:"KN", label: "Saint Kitts and Nevis"},
    { value:"LC", label: "Saint Lucia"},
    { value:"MF", label: "Saint Martin (French part)"},
    { value:"PM", label: "Saint Pierre and Miquelon"},
    { value:"VC", label: "Saint Vincent and the Grenadines"},
    { value:"WS", label: "Samoa"},
    { value:"SM", label: "San Marino"},
    { value:"SA", label: "Saudi Arabia"},
    { value:"SN", label: "Senegal"},
    { value:"RS", label: "Serbia"},
    { value:"SC", label: "Seychelles"},
    { value:"SL", label: "Sierra Leone"},
    { value:"SG", label: "Singapore"},
    { value:"SX", label: "Sint Maarten (Dutch part)"},
    { value:"SK", label: "Slovakia"},
    { value:"SI", label: "Slovenia"},
    { value:"SB", label: "Solomon Islands"},
    { value:"SO", label: "Somalia"},
    { value:"ZA", label: "South Africa"},
    { value:"GS", label: "South Georgia and the South Sandwich Islands"},
    { value:"ES", label: "Spain"},
    { value:"LK", label: "Sri Lanka"},
    { value:"SS", label: "South Sudan"},
    { value:"SR", label: "Suriname"},
    { value:"SJ", label: "Svalbard and Jan Mayen"},
    { value:"SE", label: "Sweden"},
    { value:"CH", label: "Switzerland"},
    { value:"ST", label: "São Tomé and Príncipe"},
    { value:"TW", label: "Taiwan"},
    { value:"TJ", label: "Tajikistan"},
    { value:"TZ", label: "Tanzania, United Republic of"},
    { value:"TH", label: "Thailand"},
    { value:"TG", label: "Togo"},
    { value:"TK", label: "Tokelau"},
    { value:"TO", label: "Tonga"},
    { value:"TT", label: "Trinidad & Tobago"},
    { value:"TN", label: "Tunisia"},
    { value:"TR", label: "Turkey"},
    { value:"TM", label: "Turkmenistan"},
    { value:"TC", label: "Turks and Caicos Islands"},
    { value:"TV", label: "Tuvalu"},
    { value:"UG", label: "Uganda"},
    { value:"GB", label: "United Kingdom"},
    { value:"UA", label: "Ukraine"},
    { value:"AE", label: "United Arab Emirates"},
    { value:"UM", label: "United States Minor Outlying Islands"},
    { value:"UY", label: "Uruguay"},
    { value:"UZ", label: "Uzbekistan"},
    { value:"VU", label: "Vanuatu"},
    { value:"VE", label: "Venezuela"},
    { value:"VN", label: "Vietnam"},
    { value:"VG", label: "Virgin Islands, British"},
    { value:"VI", label: "Virgin Islands, U.S."},
    { value:"WF", label: "Wallis and Futuna"},
    { value:"EH", label: "Western Sahara"},
    { value:"YE", label: "Yemen"},
    { value:"ZM", label: "Zambia"},
    { value:"ZW", label: "Zimbabwe"},
 ]

const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        subjectDN: state.subjectDN,
        eeOrganization: state.eeOrganization,
        eeCountry: state.eeCountry,
        eeLocality: state.eeLocality,
        eeState: state.eeState,
        emptyEEOrganizationNameError: state.emptyEEOrganizationNameError,
        emptyEESubjectDnNameError: state.emptyEESubjectDnNameError,
        caFormType: state.caFormType,
        eePostalCode: state.eePostalCode,
        eeUnit: state.eeUnit,
        eeEmail: state.eeEmail,
        eeStreet: state.eeStreet,
        eeCertProfile: state.eeCertProfile,
        eeEndEntityProfile: state.eeEndEntityProfile,
        form2Validation: state.form2Validation,
        importedCSR: state.importedCSR,
        csrError: state.csrError,
    };
}

const mapDispatchToProps = {
    updateSubjectDn: (input) => {
        return { type: "SUBJECT_DN", subjectDN: input  }
    },
    updateEeOrganization: (input) => {
        return { type: "EE_ORGANIZATION", eeOrganization: input }
    },
    updateEeCountry: (input) => {
        return { type: "EE_COUNTRY", eeCountry: input }
    }, 
    updateEeLocality: (input) => {
        return { type: "EE_LOCALITY", eeLocality: input }
    },
    updateEeState: (input) => {
        return { type: "EE_STATE", eeState: input }
    },
    updateEEOrganizationNameError: data => {
        return { type: "EMPTY_EE_ORGANIZATION_NAME_ERROR", emptyEEOrganizationNameError: data  }
    },
    updateEESubjectDnNameError: data => {
        return { type: "EMPTY_EE_SUBJECT_DN_NAME_ERROR", emptyEESubjectDnNameError: data }
    },
    updatePostalCode: data => {
        return { type: "UPDATE_POSTAL_CODE", eePostalCode: data }
    },
    updateUnit: data => {
        return { type: "UPDATE_UNIT", eeUnit: data }
    },
    updateStreet: data => {
        return { type: "UPDATE_STREET", eeStreet: data }
    },
    updateEmail: data => {
        return { type: "UPDATE_EMAIL", eeEmail: data }
    },
    updateCertificateProfile: data => {
        return { type: "UPDATE_CERTIFICATE_PROFILE", eeCertProfile: data }
    },
    updateEndEntityProfile: data => {
        return { type: "UPDATE_END_ENTITY_PROFILE", eeEndEntityProfile: data }
    },
    updateForm2Validation: data => {
        return { type: "UPDATE_FORM2_VALIDATION", form2Validation: data }  
    },
    updateCsrError: data => {
        return { type: "CSR_ERROR", csrError: data }
    },
    updateImportedCSR: data => {
        return { type: "IMPORTED_CSR", importedCSR: data }
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateImportedPubKey: data => {
        return { type: "IMPORTED_PUB_KEY", importedPubKey: data}
    },
    updateDomainName: input => {
        return { type: "UPDATE_DOMAIN_NAME", domainName: input }
    },
    updateDomains: input => {
        return { type: "UPDATE_DOMAIN_ARRAY", inputDomains: input }
    },
    updateNextStepAllowForm2: allow => {
        return { type: "ALLOW_NEXT_STEP_FORM2", allowNextStep: allow }
    },
    updateValidDomain: domain => {
        return { type: "UPDATE_VALID_DOMAIN", domainValidation: domain}
    },
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep1 extends Component {

    handleSubjectDn = (event) => {
        event.preventDefault();
        let subjectDN = event.target.value 
        this.props.updateSubjectDn(subjectDN)
        this.props.updateEESubjectDnNameError(false)
        this.validate_all('subjectDN', subjectDN)
    }

    handleCSRTextArea = (e) => {
        this.props.updateCsrError(false)
        let csr = e.target.value
        if (csr) {
            try {   
                let base64 = csr.replace(/-{5}(BEGIN|END) .*-{5}/gm, "").replace(/\s/gm, "");
                let csrraw = utils.Convert.FromBase64(base64);
                const pkcs10 = pkijs.CertificationRequest.fromBER(csrraw);
                if (pkcs10 != 'undefined') {
                    this.extractSubjectDN(pkcs10)
                    let seq =   pkcs10.attributes[0].values[0];  
                    let exts = pkijs.Extensions.fromBER(seq.toBER(false));
                    var san = this.getExtentionsForSANFromExtensions(exts);
                    if (san != undefined) {
                        this.extractAlternativeNames(san)
                    }

                    let publicKeyType = 'unknown'
                    switch (pkcs10.subjectPublicKeyInfo.algorithm.algorithmId) {
                      case '1.2.840.10045.2.1': // ecPublicKey OID
                        publicKeyType='ECC_SslServerProfile';
                        break;
                      case '1.2.840.113549.1.1.1': // rsaEncryption OID
                        publicKeyType='RSA_SslServerProfile';
                        break;
                    }
                    
                    this.props.updateImportedPubKey(publicKeyType)
                    this.props.updateImportedCSR(csr)
                    this.props.updateNotification({status: 'success', text: 'Your CSR imported'})
                }
            } catch (error) {
                this.props.updateCsrError(true)
                this.props.updateImportedCSR(false)
                this.props.updateImportedPubKey(false)
            }
        } else {
            this.props.updateCsrError(false)
        }
    }

    extractSubjectDN(pkcs10) {
        //#region Parse and display information about "subject"
                  const typemap = {
                    "2.5.4.6": "C", //countryName
                    "2.5.4.11": "OU", //organizationalUnitName
                    "2.5.4.10": "O", //organizationName
                    "2.5.4.3": "CN", //CommonName (subjectDN)
                    "2.5.4.7": "L", //Locality
                    "2.5.4.8": "ST", //State
                    "2.5.4.12": "T",
                    "2.5.4.42": "GN",
                    "2.5.4.43": "I",
                    "2.5.4.4": "SN",
                    "1.2.840.113549.1.9.1": "E-mail",
                    "2.5.4.9" : "Address",
                    "2.5.4.17" : "ZIP"
                  };

                for (let i = 0; i < pkcs10.subject.typesAndValues.length; i++) {
                    let typeval = typemap[pkcs10.subject.typesAndValues[i].type];
                    if (typeof typeval === "undefined")
                      typeval = pkcs10.subject.typesAndValues[i].type;

                    const value = pkcs10.subject.typesAndValues[i].value.valueBlock.value;
                    switch (typeval) {
                                case 'C':
                                    let country = options.find(e => e.value === value)
                                    if (typeof(country) != 'undefined') {
                                        this.props.updateEeCountry(country)
                                        this.validate_all('eeCountry', country)
                                    }
                                break
                                case 'ST':
                                    this.props.updateEeState(value)
                                    this.validate_all('eeState', value)
                                break
                                case 'L':
                                    this.props.updateEeLocality(value)
                                    this.validate_all('eeLocality', value)
                                break
                                case 'O':
                                    this.props.updateEeOrganization(value)
                                    this.props.updateEEOrganizationNameError(false)
                                    this.validate_all('eeOrganization', value)
                                break
                                case 'OU':
                                    this.props.updateUnit(value);
                                    this.validate_all('eeUnit', value)
                                break
                                case 'CN':
                                    this.props.updateSubjectDn(value)
                                    this.props.updateEESubjectDnNameError(false)
                                    this.validate_all('subjectDN', value)
                                break
                    }
                }

    }

    extractAlternativeNames(san) {
        let domains = []
        let result = ''


        san.names.forEach(element => {
            if (element.type == 2) {
                //DNS Name
                let validation = this.props.validation(element.value)
                if (validation === 'domain') {
                    domains.push(element.value)
                    result = result + 'dNSName=' + element.value + ','
                    this.props.updateValidDomain('domain')
                } else if (validation !== 'ip') {
                    //notify:
                    this.props.updateNotification({status: 'error', text: 'You have a not valid entry in your alternative names data:'+element.value})
                }
            } if (element.type ==7) {
                //IP Address
                let validation = this.props.validation(element.value)
                if (validation === 'ip') {
                    var ipAddress = element.value.valueBlock.valueHexView.join('.')
                    domains.push(element.value)
                    result = result + 'ipaddress=' + element.value + ','
                    this.props.updateValidDomain('ip')
                } else if (validation !== 'domain') {
                    //notify:
                    this.props.updateNotification({status: 'error', text: 'You have a not valid entry in your alternative names data:'+element.value})
                }
            } 
        });

        if (result) {
            result = result.substring(0, result.length-1)
            this.props.updateDomains(domains)
            this.props.updateDomainName(result)
        } 
    }

    getExtentionsForSANFromExtensions(exts) {
      for (var i = 0 ; i< exts.extensions.length; i++) {
        var ext = exts.extensions[i];
        if(ext.extnID == '2.5.29.17') {
          var octetString = asn1js.fromBER(ext.extnValue.toBER(false)).result; 
          return pkijs.GeneralNames.fromBER(octetString.getValue());
        }         
      }
    }

    handleOrganization = (event) => {
        event.preventDefault()
        this.props.updateEeOrganization(event.target.value)
        this.props.updateEEOrganizationNameError(false)
        this.validate_all('eeOrganization', event.target.value)
    }

    handleCountry = (event) => {
        event.preventDefault()
        let countryName = event.target.value
        let country = options.find(e => e.label === countryName)
        this.props.updateEeCountry(country)
        this.validate_all('eeCountry', country)
    }

    handleLocality = (event) => {
        event.preventDefault()
        this.props.updateEeLocality(event.target.value)
        this.validate_all('eeLocality', event.target.value)
    }

    handleState = (event) => {
        event.preventDefault()
        this.props.updateEeState(event.target.value)
        this.validate_all('eeState', event.target.value)
    }

    handleUnit = (e) => {
        e.preventDefault
        let unit = e.target.value
        this.props.updateUnit(unit);
        this.validate_all('eeUnit', unit)
    }

    handleStreetAddress = (e) => {
        e.preventDefault
        let street = e.target.value
        this.props.updateStreet(street);
        this.validate_all('eeStreet', street)
    }

    handlePostalCode = (e) => {
        e.preventDefault
        let code = e.target.value
        this.props.updatePostalCode(code);
        this.validate_all('eePostalCode', code)
    }

    handleEmail = (e) => {
        e.preventDefault
        let mail = e.target.value
        this.props.updateEmail(mail);
        this.validate_all('eeEmail', mail)
    }

    validate_all = (type, value) => {
        let res = this.props.form2Validation
        let regexp = '';
        switch (type) {
            case 'subjectDN':
            if (this.props.caFormType === 'oauth') {
                regexp = /^[a-z,A-Z,0-9,\.\-\ ]+$/ 
                res[type] = (regexp.test(value) && value.length <= 64) ? 'ok' : 0
            } else {
                //domain check:
                regexp = /^(^\*\.|[a-z,0-9,\-])+\.([a-z\-]+\.[a-z\-]+\.[a-z]+|[a-z\-]+\.[a-z]+|[a-z]+)$/
                let validation = regexp.test(value) ? 1 : 0
                let checkmore = /^[^\-]+/.test(value)
                if (validation && checkmore && value.length <=64) {
                    res[type] = 'ok'
                } else {
                    //ip check:
                    regexp = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/ 
                    res[type] = (regexp.test(value) && value.length <= 64) ? 'ok' : 0
                }
            }
            break; 

            case 'eeOrganization':
            regexp = /^[a-z,A-Z,0-9,\.\- ]+$/ 
            res[type] = (regexp.test(value) && value.length <= 64) ? 'ok' : 0
            break;

            case 'eeCountry':
            regexp = /^([A-Z][A-Z])$/ 
            res[type] = (regexp.test(value.value) && value.value.length == 2) ? 'ok' : 0
            break;

            case 'eeState':
            regexp = /^[a-z,A-Z ]+$/
            res[type] = (regexp.test(value) && value.length <= 128) ? 'ok' : 0
            break;

            case 'eeLocality':
            regexp = /^[a-z,A-Z,0-9 ]+$/ 
            res[type] = (regexp.test(value) && value.length <= 128) ? 'ok' : 0
            break;

            /*case 'eeStreet':
            regexp = /^(.*)+$/ 
            res[type] = regexp.test(value) ? 'ok' : 0
            break;

            case 'eeUnit':
            regexp = /^(.*)+$/ 
            res[type] = regexp.test(value) ? 'ok' : 0
            break;

            case 'eePostalCode':
            regexp = /^(.*)+$/ 
            res[type] = regexp.test(value) ? 'ok' : 0
            break;

            case 'eeEmail':
            regexp = /^(.*)+$/ 
            res[type] = regexp.test(value) ? 'ok' : 0
            break;*/
        }
        
        this.props.updateForm2Validation(res)
    } 

    validation_message(error) {
        return (
            <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                    <div className="bg-gray-300 border-t-4 border-blue-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-full" role="alert"> 
                        <div className="flex"> 
                        <div className="py-1"><svg className="fill-current h-8 w-8 text-blue-500 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                        <div>
                        <p className="font-bold text-blue-500">Error</p>
                            <p className="text-sm">{error}</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    render() {
        
        const stepNumberForm2 = this.props.stepNumberForm2
        if (stepNumberForm2 !== 1) {// Prop: The current step
            return null
        }

        let cert_type = 'TLS'
        const caFormType = this.props.caFormType
        if (caFormType == 'oauth') {
            cert_type = 'client auth'
        }

        const csrError = this.props.csrError
        const emptyEESubjectDnNameError = this.props.emptyEESubjectDnNameError
        const emptyEEOrganizationNameError = this.props.emptyEEOrganizationNameError
        const form2Validation = this.props.form2Validation
        const subjectDN = this.props.subjectDN
        const eeOrganization = this.props.eeOrganization
        const eeCountry = this.props.eeCountry
        const eeState = this.props.eeState
        const eePostalCode = this.props.eePostalCode
        const eeStreet = this.props.eeStreet
        const eeLocality = this.props.eeLocality
        const eeEmail = this.props.eeEmail
        //const eeUnit = this.props.eeUnit
        return (

            <div>
                  <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Request a {cert_type} certificate</p>
                      <hr className="ml-4"/>
                      <p className="text-base sm:text-xl text-gray-600 italic mt-6 mb-3">You're trying to request and create private certificate from your organization's certificate authority</p>
                  </div>
                  
                  <div className="max-w-5xl mt-10 px-2"> 
                            <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                                CSR import (optional)
                                </label>
                            </div>
                            <div className="md:w-2/3">
                               <textarea role="csr_input" onChange={this.handleCSRTextArea} className="px-2 resize mt-3 textarea-height border resize w-full bg-gray-200 appearance-none border-2 border-gray-200 text-gray-700 font-sm rounded" placeholder="Paste your CSR here"/>
                            </div>
                            
                            </div>    
                            <div>
                            {csrError && <div>{this.validation_message('Your CSR has invalid format')}</div>}
                            </div> 
                            <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                              <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                              Common Name 
                              <span className="text-xl text-red-400"> *</span>
                              </label>
                            </div>
                            <div className="md:w-2/3">
                                <input 
                                    className={emptyEESubjectDnNameError === true || emptyEEOrganizationNameError['subjectDN'] === true ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2"}
                                    name="subjectDN"
                                    type="text"
                                    placeholder={emptyEESubjectDnNameError === true || emptyEEOrganizationNameError['subjectDN'] === true  ? "Subject DN name cannot be empty!" : "Please type your subject DN for EE certificate"}
                                    value={subjectDN}
                                    onChange={this.handleSubjectDn}
                                />
                            </div>
                            
                            </div>
                            {subjectDN && <div>
                            {form2Validation['subjectDN'] == 'ok' ? <div></div> : 
                                <div>{this.validation_message('Common name is invalid')}</div>
                            }</div>}
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Organization
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input 
                                        className={emptyEEOrganizationNameError['eeOrganization'] === true ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2"}
                                        name="organization"
                                        type="text"
                                        placeholder="Please type your organization name for EE certificate"
                                        value={eeOrganization}
                                        onChange={this.handleOrganization}
                                    />
                                </div>
                            </div>
                             {eeOrganization && <div>
                            {form2Validation['eeOrganization'] == 'ok' ? <div></div> : 
                                <div>{this.validation_message('Organization is invalid')}</div>
                            }</div>}
                            {/*caFormType === 'oauth' && 
                                <div>
                                <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                                        Organizational Unit:
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input 
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                                            name="unit"
                                            type="text"
                                            placeholder="Please type your Organizational Unit"
                                            value={eeUnit}
                                            onChange={this.handleUnit}
                                        />
                                    </div>
                                </div>
                                {eeUnit && <div>
                                {form2Validation['eeUnit'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('Organization unit is invalid')}</div>
                                }</div>}
                                </div>

                            */}
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                                    Country Name
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select role="country" className="px-2 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" value={eeCountry.label} onChange={this.handleCountry}>
                                        {options.map((country, i) => (
                                        <option key={country.value}>{country.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    State/Province
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input 
                                        className={emptyEEOrganizationNameError['eeState'] === true ? "px-2 bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2"}
                                        name="state"
                                        type="text"
                                        placeholder="Please type your State or Province"
                                        value={eeState}
                                        onChange={this.handleState}
                                    />
                                </div>
                            </div>
                            {eeState && <div>
                                {form2Validation['eeState'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('State is invalid')}</div>
                                }</div>}
                            

                             {/*caFormType === 'oauth' && 
                                <div>
                                <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                                        Postal Code:
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input 
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                                            name="postalcode"
                                            type="text"
                                            placeholder="Please type your Postal Code"
                                            value={eePostalCode}
                                            onChange={this.handlePostalCode}
                                        />
                                    </div>
                                </div>
                                {eePostalCode && <div>
                                {form2Validation['eePostalCode'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('Postal Code is invalid')}</div>
                                }</div>}
                                </div>
                            */}

                            {/*caFormType === 'oauth' && 
                                <div>
                                <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                                        Street address:
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input 
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                                            name="street"
                                            type="text"
                                            placeholder="Please type your Street"
                                            value={eeStreet}
                                            onChange={this.handleStreetAddress}
                                        />
                                    </div>
                                </div>
                                {eeStreet && <div>
                                {form2Validation['eeStreet'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('Street is invalid')}</div>
                                }</div>}
                                </div>
                            */}
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                                    Locality
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input 
                                        className={emptyEEOrganizationNameError['eeLocality'] === true ? "px-2 bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2"}
                                        name="locality"
                                        type="text"
                                        placeholder="Please type your locality"
                                        value={eeLocality}
                                        onChange={this.handleLocality}
                                    />
                                </div>
                            </div>
                            {eeLocality && <div>
                                {form2Validation['eeLocality'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('Locality is invalid')}</div>
                                }</div>}

                            {/*caFormType === 'oauth' && 
                                <div>
                                <div className="md:flex md:items-center mb-6">
                                    <div className="md:w-1/3">
                                        <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4" >
                                        E-mail address:
                                        </label>
                                    </div>
                                    <div className="md:w-2/3">
                                        <input 
                                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                                            name="email"
                                            type="text"
                                            placeholder="Please type your E-mail address"
                                            value={eeEmail}
                                            onChange={this.handleEmail}
                                        />
                                    </div>
                                </div>
                                {eeEmail && <div>
                                {form2Validation['eeEmail'] == 'ok' ? <div></div> : 
                                    <div>{this.validation_message('Email is invalid')}</div>
                                }</div>}
                                </div>
                            */}

                           

                            
                            
                        </div>
                        
              </div> 
        );
    }
}

)
