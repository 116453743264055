import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application.scss"
import "controllers"
import "private_pki"


Rails.start()
ActiveStorage.start()

const render_flash_messages = () => {
  const flash_messages = document.querySelectorAll(".flash-message")
	flash_messages.forEach(flash_message => {
    show_flash_message(flash_message)
	})
}

const show_flash_message = (element) => {
  setTimeout(() => {
    const trigger = element.querySelectorAll(".close")[0]
    trigger.addEventListener("click", (event) => {
      close_flash_message(element)
    })
  }, 100)

  setTimeout(() => {
    close_flash_message(element)
  }, 5000)
}

const close_flash_message = (element) => {
  element.style = "transition: 0.25s; transform:translate(420px, 0);"

  setTimeout(() => {
    element.remove()
  }, 500)
}

document.addEventListener("ajax:success", (event) => {
  console.log("ajax:success")
  const [data, status, xhr] = event.detail
  render_flash_messages()
})

document.addEventListener("turbo:load", function() {
  console.log("turbo:load")
  render_flash_messages()
})
    