import React, { Component } from "react"

import { connect } from "react-redux"
import { FiEdit } from "react-icons/fi";
import { MdDataSaverOn } from "react-icons/md";



  // Redux where we import state
  const mapStateToProps = state => {
      return {
        stepNumber: state.stepNumber,
        default_crl_distribution_point_checkbox: state.default_crl_distribution_point_checkbox,
        enforce_unique_public_key_checkbox: state.enforce_unique_public_key_checkbox,
        customCRLName: state.customCRLName
      };
    };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateDefaultCrlDistributionPointCheckbox: selectedValue => {
      return { type: "DEFAULT_CRL_DISTRIBUTION_POINT_CHECKBOX", default_crl_distribution_point_checkbox: selectedValue}
    },
    updateEnforceUniquePublicKeyCheckbox: selectedValue => {
      return { type: "ENFORCE_UNIQUE_PUBLIC_KEY_CHECKBOX", enforce_unique_public_key_checkbox: selectedValue}
    },
    updateCustomCRLName: (customCRL) => {
      return { type: "UPDATE_CUSTOM_CRL_NAME", customCRLName: customCRL}
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step6 extends Component {
  
  // This function pickup value and call mapDispatch redux metod
    

    handleDefaultCRL = () => {
      let value = !this.props.default_crl_distribution_point_checkbox
      this.props.updateDefaultCrlDistributionPointCheckbox(value)
    }

    handleUniquePublicKey = () => {
      let value = !this.props.enforce_unique_public_key_checkbox
      this.props.updateEnforceUniquePublicKeyCheckbox(value)
    }
    
    handleEditCRL = (e) => {
      let value = e.target.value
      //let regexp = /^$|^[a-z,A-Z,0-9,\.\- \_]+$/
      //value = (regexp.test(value) && value.length <= 64) ? value : false
      //if (value !== false) {
        this.props.updateCustomCRLName(value)
      //}
    }

        render() {  
            const stepNumber = this.props.stepNumber
            if (stepNumber !== 6) { // Prop: The current step
              return null
            }

            const default_crl_distribution_point_checkbox = this.props.default_crl_distribution_point_checkbox
            const enforce_unique_public_key_checkbox = this.props.enforce_unique_public_key_checkbox
            const customCRLName = this.props.customCRLName
            
            return (
              <div>
                  <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Configure your CA </p>
                      <hr className="ml-4"/>
                      <p className="text-base sm:text-xl text-gray-600 italic mt-3 px-2">Please fill out the following form</p>
                  </div> 
                  <div>
                    <p className="text-sm sm:text-base text-gray-600 italic mt-3 px-4 text-justify md:text-base"> A CRL distribution point (CDP) is a location on a Web server where a CA publishes CRLs. The system downloads CRL information from the CDP at the interval specified in the CRL, at the interval that you specify during CRL configuration, and when you manually download the CRL.</p>
                    <input role="distr_point_check" type="checkbox" onChange={ (e) => {} } className="form-checkbox h-6 w-6 text-palette-5 my-5 ml-5" onClick={(e) => this.handleDefaultCRL(e)} value={default_crl_distribution_point_checkbox} checked={default_crl_distribution_point_checkbox === true}/>
                    <label className="ml-2 text-base sm:text-xl text-gray-600 italic">Default CRL distribution point</label>
                  </div>
                  {default_crl_distribution_point_checkbox === true && 
                    <div className="md:flex md:items-center mt-6 mb-6" >
                        <div className="md:w-1/3"> 
                          <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Custom CRL Name
                          </label>
                        </div>
                        <div className="md:w-2/3">

                          <input
                              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"
                              name="CRL"
                              type="text"
                              placeholder="Keep blank if you want auto generated url"
                              value={customCRLName} // Prop: The email input data
                              onChange={(e) => this.handleEditCRL(e)} // Prop: Puts data into state
                              disabled={!default_crl_distribution_point_checkbox}
                          />
                        </div>
                    </div>}
                  <div>
                    <p className="text-sm sm:text-base text-gray-600 italic mt-3 px-4 text-justify md:text-base">Enforce unique public keys guarantees that certificates with the same public key can only be issued to the same user from this CA. This means that a user is allowed to have multiple certificates (e.g. due to renewal) with the same key as long as the same username is used, but two users cannot share the same public key.</p>
                    <input role="unique" type="checkbox" onChange={ (e) => {} } className="form-checkbox h-6 w-6 text-palette-5 my-5 ml-5" onClick={this.handleUniquePublicKey} value={enforce_unique_public_key_checkbox} checked={enforce_unique_public_key_checkbox === true}/>
                    <label className="ml-2 text-base sm:text-xl text-gray-600 italic">Enforce unique public key</label>
                  </div>
              </div>
            )
            }
        }
      
      )
      
