import { createStore, compose, applyMiddleware } from 'redux'
import reducer from "../reducers/reducer"
import ReduxThunk from "redux-thunk";
import { loadState, saveState } from "../localStorage/localStorage"
 
const team = loadState('team', '');
const subCaEEId = loadState('subCaEEId', '');
const stateCASelection = loadState('stateCASelection', 'all' );
const eeSelect = loadState('eeSelect', 'active')
const eeSelectType = loadState('eeSelectType', 'all')
//const checkboxes = loadState('checkboxes', [])
const initalState = {
    stepNumber: 2,
    keyAlgorithmWindow: true,
    viewCaType: "ROOTCA",
    organization: "",
    keyHashLength: 256,
    alertPopup: false,
    alertConfirm: false,
    tagsBuf: [],
    checkboxes: [],
    form2Validation: [],
    notification: false,
    organizationUnit: "",
    countryName: "US",
    stateName: "",
    tagEditCaId: false,
    p7b: '',
    localityName: "",
    spinnerModal: false,
    commonName: "",
    keyAlgorithm: "RSA2048",
    signingAlgorithm: "SHA256WithRSA",
    acmInfo: "Authorize",
    tagName: "",
    tagNameValue: "",
    disableForm: false,
    revocationList: true,
    customCRLName: "",
    validFor: 10950,
    validFor1: 30,
    username: "",
    password: "",
    passwordConfirmation: "",
    currentUser: {},
    errorMessage: "",
    inputValidity: "year",
    tab: "status",
    createdCA: 1,
    cas: {},
    selectedCa: [],
    idFromSelectedCa: "1",
    loadingScreen: false,
    certificateData: "",
    stepNumberForm2: 1,
    mainNavBar: "privateCa",
    buttonWindowOnDashboardPage: false,
    selectedCaObject: "",
    domainValidation: "",
    domainName: "",
    certDayDuration: "",
    certDateDuration: "",
    eePublicKey: 0,
    endEntityArray: [],
    selectedEndEntity: '',
    cmPageTabs: "info",
    EeName: "",
    messageWindow: false,
    crlValidFor: 7,
    modalWindow: false,
    showErrorMessageWindow: false,
    chainCertificate: "",
    csrCertificate: "",
    privateKey: "",
    subjectDN: "",
    downloadDropdown: false,
    caSelectedTeam: [],
    eeSelectedTeam: [],
    inputDomains: [],
    maxDomains: 5,
    inputTags: [],
    maxTags: 5,
    tagNameBuf: '',
    tagValBuf: '',
    tagEditForm: false,
    domainBuf: '',
    dropdownEeTeam: "",
    team: team,
    passwordModal: false,
    pfxPassword: "",
    spinner: true,
    selectedNestedSubCa: [],
    showNestedCa: false,
    renderSubCaWindow: false,
    pathCa: [],
    arrayWithSubCas: [],
    subCaEEId: subCaEEId,
    certificateManagerSpinner: true,
    importedCSR: "",
    csrError: false,
    eeOrganization: "",
    eeCountry: "US",
    eeLocality: "",
    eeState: "",
    emptyOrganizationNameError: false,
    emptyCommonNameError: false,
    emptyEEOrganizationNameError: false,
    emptyEESubjectDnNameError: false,
    optionButtonCertificateManagerPage: false,
    optionButtonDropDownManagerPage: false,
    requestButtonDropDownManagerPage: false,
    eeSelect: eeSelect,
    eeSelectType: eeSelectType,
    default_crl_distribution_point_checkbox: false,
    ocsp_service_default_url_checkbox: false,
    enforce_unique_public_key_checkbox: false,
    default_crl_distribution_point_input: "",
    ocsp_service_default_url_input: "",
    crl_edit_input: "",
    crl_edit_form: false,
    crl_checkbox_CRL: false,
    tabNumberDatePickForm2: 3,
    certYearDuration: 0,
    emptyEEDaysError: false, 
    allowNextStepForm2: true,
    domainValidationError: false,
    caFormType: 'tls',
    orgUnit: '',
    postalCode: '',
    streetAddress: '',
    eePostalCode: '',
    eeUnit: '',
    eeEmail: '',
    eeStreet: '',
    eeCertProfile: '',
    eeEndEntityProfile: '',
    stateCASelection: stateCASelection,
    pagination: null,
    page: 1,
    adminOption: null,
    perPage: 20,
    alertOption: 'disable',
};

const middleware = compose(
    applyMiddleware(ReduxThunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  
  const store = createStore(reducer, initalState, middleware, team, stateCASelection, subCaEEId, eeSelect, eeSelectType);

  store.subscribe(() => {
    saveState(store.getState().team, 'team') //selected team
    saveState(store.getState().subCaEEId, 'subCaEEId') //selected subca
    saveState(store.getState().stateCASelection, 'stateCASelection') //subca selected state
    saveState(store.getState().eeSelect, 'eeSelect') //filter state of the subca table
    saveState(store.getState().eeSelectType, 'eeSelectType') //filter state of the subca table
    //saveState(store.getState().eeSelectType, 'checkboxes') //selected checkboxes
  });

export default store;
