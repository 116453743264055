import React from "react"

/*export function getHelperSubjectDNdata(selectedCa) { //Render SubjectDN:
        let res = ''
        selectedCa.common_name ? res += 'CN='+selectedCa.common_name+', ' : null
        selectedCa.organization ? res += 'O='+selectedCa.organization+', ': null
        selectedCa.locality_name ? res += 'L='+selectedCa.locality_name+', ': null
        selectedCa.state_name ? res += 'ST='+selectedCa.state_name+', ': null
        selectedCa.country_name ? res += 'C='+selectedCa.country_name+', ': null
        selectedCa.organization_unit ? res += 'OU='+selectedCa.organization_unit+', ' : null
        res = res.substring(0, res.length - 2)
        return res
}*/

export function getHelperIsExpired(ca) { //Calculate if CA is expired or not:
    if (typeof(ca) != 'undefined') {
          let today = new Date()
          let d1 = new Date(ca.created_at); // created at
          let d2 = new Date(d1.getTime() + ca.valid_for*3600*24*1000); //will be expired at
          
          
            let diff = d2.getTime() - today.getTime();      
            let daydiff = diff / (3600 * 24 * 1000);
            daydiff = parseInt(daydiff)
            if (daydiff > 0) {
              return false
            } else {
              return true
            }
    } else {
            return false
    }
}

export function getHelpersExpiredData(ca) { //Calculates the expiration amount of days and the expiration date:
    if (typeof(ca) != 'undefined') {
        let today = new Date()
        let d1 = new Date(ca.created_at); // created at
        let d2 = new Date(d1.getTime() + ca.valid_for*3600*24*1000); //will be expired at
      
        let diff = d2.getTime() - today.getTime();      
        let daydiff = diff / (3600 * 24 * 1000);
        daydiff = parseInt(daydiff)
        let day = d2.getDate() 
        let month = d2.getMonth() + 1;
        let year = d2.getFullYear()
        let res = []
        res['days'] = daydiff
        res['expired_at'] = d2
        return res
    } else {
        return false;
    }
}

export function getHelpersCalculateTags(selected_ca) {
    const tagName = selected_ca.tag_name
    const tagValue = selected_ca.tag_name_value
    let tags = []
    if ( (typeof(tagValue) != 'undefined') && /\[\{(.*)+\}\]/.test(tagValue) ) {
        tags = JSON.parse(tagValue)
    }
    return tags
}

export function getHelperDownloadClick(cert, prefix) {

    let data = false

    switch(prefix) {
        case '_SubCA':
        case '_CA':
        case '_CA_ISSUER':
        case '_SubCA_ISSUER':
            if (typeof(cert.cert_chain) != 'undefined') {
                data = cert.cert_chain
            }
            
           /* if (typeof(cert.certificate_data) != 'undefined') {
                data = cert.certificate_data
            }*/
        break;

        case '_EE':
            if (typeof(cert.cert) != 'undefined') {
                data = cert.cert
            }
            /* if (typeof(cert.ee_cert) != 'undefined') {
                data = cert.ee_cert
            }*/
        break; 
    }

    if (data) {
        const element = document.createElement("a");
        const file = new Blob([data], {type: 'application/x-x509-ca-cert'});
        element.href = URL.createObjectURL(file);
        let filename = cert.common_name.replace(/^CN=|L=,|ST=,/g, '').replace(/[^0-9a-zA-Z]+/g, '_')
        filename = filename + prefix + '.pem'
        element.download = filename
        document.body.appendChild(element);
        element.click();
    }
}

export function getHelpersPathCaClick(pathCa, id) {
    let ind = 99999999999999
    let filtered = []

    pathCa.map( (pa, index) => {
        if (pa.ca_api_id === id) {
            filtered.push(pa)
            ind = index
        } else if (index < ind) {
            filtered.push(pa)
        }
    })

    return filtered
}