import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-date-picker';
var moment = require('moment');





 // Redux where we import state
const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        certDayDuration: state.certDayDuration,
        certDateDuration: state.certDateDuration,
        certYearDuration: state.certYearDuration,
        tabNumberDatePickForm2: state.tabNumberDatePickForm2,
        emptyEEDaysError: state.emptyEEDaysError,
        selectedCaObject: state.selectedCaObject,
        subCaEEId: state.subCaEEId
    };
}

  // Redux where we dispatch state
const mapDispatchToProps = {
    updateCertDayDuration: days => {
        return { type: "UPDATE_CERT_DAY_DURATION", certDayDuration: days }
    },
    updateCertDateDuration: total => {
        return { type: "UPDATE_CERT_DATE_DURATION", certDateDuration: total }
    },
    updateCertYearDuration: years => {
        return { type: "UPDATE_CERT_YEAR_DURATION", certYearDuration: years }
    },
    updateTabNumberDatePickForm2: tab => {
        return { type: "UPDATE_NUMBER_DATEPICKER_FORM2", tab: tab }  
    },
    updateEEemptyDaysError: data => {
        return { type: "EMPTY_EE_DAYS_ERROR", emptyEEDaysError: data }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep4 extends Component {

    handleDateInput = (e) => {
        let today = new Date()

        let dataPicker = e
        
        let total = Math.ceil((dataPicker - today ) / (24*60*60*1000))
        this.props.updateCertDayDuration(total)
        if (total > 0) {
            this.props.updateEEemptyDaysError(false)
        } 

        total = total/365;
        total.toFixed(2);
        this.props.updateCertYearDuration(total)

        this.props.updateCertDateDuration(dataPicker)
        
    }

    handleDaysInput = async(e) => {
       
        let total = e.target.value
        total = parseInt(total)
        let limit = await this.takeLimit()
        limit = limit['days']
        if (total > limit) {
            total = limit
        }

        if (total < 0) {
            total = 0;
        }
        this.props.updateCertDayDuration(total)

        if (total > 0) {
            this.props.updateEEemptyDaysError(false)
        } 
       
        let date = new Date()
        let date1 = new Date(date.getTime() + (total * 60 * 60 * 24 * 1000))

        this.props.updateCertDateDuration(date1)

        total = total/365
        total.toFixed(2)
        this.props.updateCertYearDuration(total)      
    }

    handleYearInput = async(e) => {
       
        let total = e.target.value
        let limit = await this.takeLimit()
        limit = limit['years']
        if (total > limit) {
            total = limit
        }

        if (total < 0) {
            total = 0
        }
        total = parseInt(total)
        this.props.updateCertYearDuration(total)

        total = total*365;
        total = parseInt(total)
        
        this.props.updateCertDayDuration(total)
        
        if (total > 0) {
            this.props.updateEEemptyDaysError(false)
        } 
       
        let date = new Date()
        let date1 = new Date(date.getTime() + (total * 60 * 60 * 24 * 1000))

        this.props.updateCertDateDuration(date1)
    }

    handleTabSwitch = (e) => {
        e.preventDefault();
        let tab = e.target.value;
        if (typeof(e) != 'undefined') {
            this.props.updateTabNumberDatePickForm2(tab);  
        }
    }

    takeLimit = async() => {
        let cert = this.props.selectedCaObject
        if (typeof(cert.created_at) != 'undefined' && typeof(cert.valid_for) != 'undefined') {
            let d1 = new Date(cert.created_at); // cert created at
            let d2 = new Date(d1.getTime() + cert.valid_for*3600*24*1000); //cert will be expired at
            let today = new Date() //today
            let result = await this.calculateDayDiff(today, d2)
            if (result['years'] > 25) {
                d2 = new Date(today.getTime() +  3600*24*1000*365*25) //25 years from today
                result = await this.calculateDayDiff(today, d2)
            }

            return result
        }
    }

    calculateDayDiff = async(d1, d2) => {
        let diff = d2.getTime() - d1.getTime(); //time diff
        let daydiff = diff/ (3600*24*1000) //day diff
        let result = []
        result['days'] = Math.floor(daydiff)
        result['years'] = Math.fround(daydiff/365)
        result['date'] = d2
        return result
    }



    render() {

        if (this.props.stepNumberForm2 !== 4) {// Prop: The current step
            return null
        }

        const tabNum = this.props.tabNumberDatePickForm2
        const thedate = this.props.certDateDuration;
        const certDateDuration = this.props.certDateDuration
        const certDayDuration = this.props.certDayDuration
        const emptyEEDaysError = this.props.emptyEEDaysError
        const certYearDuration = this.props.certYearDuration

        let limit = this.takeLimit();
        return (
                <div className="mb-3">
                    <div>
                        <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Expiration Date</p>
                        <hr className="ml-4"/>
                        <p className="text-base sm:text-xl text-gray-600 italic mt-3">Set the expiration date, days amount or years amount for your certificate.</p>
                    </div>
                    <div className="tab-switcher">
                        <ul>
                            <li role="tab_switch_datepicker" key="tab_switch_1" className={tabNum === 1 ? 'selected bg-black hover:palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1 text-xs md:text-xs lg:text-base' : 'bg-black hover:palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1 text-xs md:text-xs lg:text-base'} onClick={this.handleTabSwitch} value="1">Expiration Date</li>
                            <li role="tab_switch_manual" key="tab_switch_2" className={(tabNum == 2 || tabNum == 3) ? 'selected bg-black hover:palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1 text-xs md:text-xs lg:text-base' : 'bg-black hover:palette-5 text-white border-none font-bold py-2 px-4 rounded mr-1 text-xs md:text-xs lg:text-base'} onClick={this.handleTabSwitch} value="2">Expiration Days/Years Amount</li>
                           
                        </ul>
                    </div>
                    <div className="flex flex-col"> 
                        {tabNum == 1 && <div className="lg:flex lg:items-center mb-6 mt-12">
                        <div className="lg:w-1/4">
                            <label className="block font-bold lg:text-left mb-1 md:mb-0 pr-4" >
                                Date when your certificate will expire:
                            </label>
                        </div>
                        <div className="lg:w-1/4">
                            <DatePicker
                                onChange={this.handleDateInput}
                                value={certDateDuration}
                                format="M/dd/y"
                                minDate={new Date()}
                                maxDate={limit['date']}
                                className={emptyEEDaysError ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-black rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"}
                            />     
                        </div>
                        </div>}
                        {(tabNum == 2 || tabNum == 3) && <div className="durationSelect">
                            <div className="lg:flex lg:items-center mb-6 px-2">
                                <div className="lg:w-1/4">
                                    <label className="block font-bold md:text-left mb-1 md:mb-0 pr-4" >
                                    Validity:
                                    </label>
                                </div>
                                <div className="lg:w-1/4">
                                    <select role="tab_switch_days_years" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" defaultValue={tabNum} onChange={this.handleTabSwitch}>
                                        <option value="2">Days</option>
                                        <option value="3">Years</option>
                                    </select>
                                </div>
                            </div>
                        </div>} 
                        {tabNum == 2 && <div className="lg:flex lg:items-center mb-6 mt-12 px-2">
                        <div className="lg:w-1/4">
                            <label className="block font-bold lg:text-left mb-1 lg:mb-0 pr-4" >
                                Certificate duration in days:
                            </label>
                        </div>
                        <div className="lg:w-1/4">
                            <input
                                type="number"
                                value={certDayDuration}
                                onChange={this.handleDaysInput}
                                name="days"
                                min="0"
                                max={limit['days']}
                                className={emptyEEDaysError ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-black rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"}
                            />     
                        </div>
                        </div>}

                        {tabNum == 3 && <div className="lg:flex lg:items-center mb-6 mt-12 px-2">
                        <div className="lg:w-1/4">
                            <label className="block font-bold lg:text-left mb-1 lg:mb-0 pr-4" >
                                Certificate duration in years:
                            </label>
                        </div>
                        <div className="lg:w-1/4">
                            <input
                                type="number"
                                value={certYearDuration}
                                onChange={this.handleYearInput}
                                name="years"
                                min="0"
                                max={limit['years']}
                                className={emptyEEDaysError ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-black rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"}
                            />     
                        </div>
                        </div>}

                        <div>
                            {certDayDuration <= 0 && <div className="bg-gray-300 border-t-4 border-palette-5 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert"> 
                            <div className="flex"> 
                                <div className="py-1"><svg className="fill-current h-8 w-8 text-palette-5 mr-4 md:ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                    <div>
                                        <p className="font-bold text-palette-5">Message</p>
                                        <p className="text-sm">You need to select amount of days</p>
                                    </div>
                                </div>
                            </div>}
                            {certDayDuration > 0 && <strong className="results-days px-2 text-sm lg:text-base">Your certificate will be valid for {certDayDuration} days {thedate && <span>, until {moment(thedate).format('MM-DD-YYYY, h:mm A')}</span>}</strong>}
                        </div>
                </div>
                </div>
        );
    }
}

)