import React, { Component } from "react"
import { connect } from "react-redux"
import { FaEdit } from "react-icons/fa";
import image from "../../../images/gif.gif"
import history from "../../../history/history"

// Redux where we import state
const mapStateToProps = state => {
    return {
      stepNumber: state.stepNumber,
      viewCaType: state.viewCaType,
      organization: state.organization,
      organizationUnit: state.organizationUnit,
      countryName: state.countryName,
      stateName: state.stateName,
      localityName: state.localityName,
      commonName: state.commonName,
      keyAlgorithm: state.keyAlgorithm,
      signingAlgorithm: state.signingAlgorithm,
      acmInfo: state.acmInfo,
      tagName: state.tagName,
      tagNameValue: state.tagNameValue,
      customCRLName: state.customCRLName,
      validFor: state.validFor,
      createdCA: state.createdCA,
      cas: state.cas,
      idFromSelectedCa: state.idFromSelectedCa,
      loadingScreen: state.loadingScreen,
      certificateData: state.certificateData,
      team: state.team,
      default_crl_distribution_point_checkbox: state.default_crl_distribution_point_checkbox,
      ocsp_service_default_url_checkbox: state.ocsp_service_default_url_checkbox,
      enforce_unique_public_key_checkbox: state.enforce_unique_public_key_checkbox,
      default_crl_distribution_point_input: state.default_crl_distribution_point_input,
      ocsp_service_default_url_input: state.ocsp_service_default_url_input,
      inputTags: state.inputTags,
      selectedCa: state.selectedCa
    };
  };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateCurrentStep: editStep => {
      return { type: "CURRENT_COMPONENT_INFO", stepNumber: editStep}
    },
    updateCreateCa: data => {
      return { type: "CREATE_CA", createdCA: data }
    },
    updateCas: data => {
      return { type: "UPDATE_CAS", cas: data }
    },
    updateLoadingScreen: data => {
      return { type: "UPDATE_LOADING_SCREEN", loadingScreen: data }
    },
    updateCertificateData: certificate => {
      return { type: "UPDATE_CERTIFICATE_DATA", certificateData: certificate }
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
  }

  
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step7 extends Component {
      

  
  // This function pickup value and call mapDispatch redux metod
    handleStepState = (event) => {
        let editStep = 1
        this.props.updateCurrentStep(editStep)
    }

  // This function pickup value and call mapDispatch redux metod
    handleStepState1 = (event) => {
      let editStep = 2
      this.props.updateCurrentStep(editStep)
    }

  // This function pickup value and call mapDispatch redux metod
    handleStepState2 = (event) => {
      let editStep = 3
      this.props.updateCurrentStep(editStep)
    }  

  // This function pickup value and call mapDispatch redux metod
    handleStepState3 = (event) => {
      let editStep = 4
      this.props.updateCurrentStep(editStep)
    }

  // This function pickup value and call mapDispatch redux metod
    handleStepState4 = (event) => {
    let editStep = 5
    this.props.updateCurrentStep(editStep)
    } 
     
  // This function pickup value and call mapDispatch redux metod
    handleStepState5 = (event) => {
      let editStep = 6
      this.props.updateCurrentStep(editStep)
    } 

  // This is Fetch method where we doing API call with API SERVER 
  handleSubmit = async (e) => { //this will be a query to create the cert
    let data = true
    let crl_input = null
    let ocsp_input = null
    if (this.props.default_crl_distribution_point_checkbox) {
      crl_input = this.props.default_crl_distribution_point_input.trim()
    }
    if (this.props.ocsp_service_default_url_checkbox) {
      ocsp_input = this.props.ocsp_service_default_url_input.trim()
    }

    let common_name = this.props.commonName.trim()
    let organization = this.props.organization.trim()
    let country = this.props.countryName.trim()
    let locality = this.props.localityName.trim()
    let unit = this.props.organizationUnit.trim()
    let state = this.props.stateName.trim()

    const cas = this.props.cas
    const team = this.props.team
    let team_filter = cas.filter( ee => ee.id == team)

    this.props.updateLoadingScreen(data)
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
       await fetch("/cas", {
        method: "POST",
        credentials: 'same-origin',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrf
        },
        body: JSON.stringify(
          {
            "ca": {
              "key_algorithm":this.props.keyAlgorithm,
              "organization_unit": unit,
              "country_name": country,
              "locality_name": locality,
              "state_name": state,
              "common_name": common_name,
              "organization": organization,
              "crl_input": crl_input,
              "acm_info": this.props.acmInfo,
              "tag_name": this.props.tagName,
              "tag_name_value": this.props.tagNameValue,
              "custom_crl_name": encodeURI(this.props.customCRLName),
              "valid_for": this.props.validFor,
              "account_status": true,
              "team": team_filter[0].acct_number,
              "signed_by": this.props.idFromSelectedCa,
              "signature_algorithm": this.props.signingAlgorithm,
              "ca_type": this.props.viewCaType,
              "default_crl": this.props.default_crl_distribution_point_checkbox,
              "unique_public_key": this.props.enforce_unique_public_key_checkbox
            }
        })
      })
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          //throw new TypeError("Oops, we haven't got JSON!");
          this.props.updateNotification({status: 'error', text: 'Oops, we haven\'t got JSON!'})
        }
        return response.json();
     })
      .then( response => {
          if (typeof(response.code) != 'undefined') {
            switch(response.code) {
              case 1000:
                this.props.updateNotification({status: 'notify', text: 'Please add common name. Common name cannot be empty.'})
              break;

              case 1015:
                this.props.updateNotification({status: 'notify', text: 'Please change common name, we already have CA with that name.'})
              break;

              case 401:
                this.props.updateNotification({status: 'notify', text: 'Refresh token expired!'})
              break;

              case 1002:
                this.props.updateNotification({status: 'notify', text: 'Crypto token error, we already have CA/SubCA with that organization name. Please change your organization name'})
              break;

              default:
                this.props.updateNotification({status: 'notify', text: 'Cert creation issue with status code '+response.code})
                console.log(response)
              break; 
            }
            this.props.updateLoadingScreen(false)
          } else {
            if (typeof(response.ca_api_id) != 'undefined') {
              let url = "/?cert="+response.ca_api_id.toString()             
              window.location.href = url
            } else {
              this.props.updateNotification({status: 'notify', text: 'Certificate is created, but not saved to the database'})
              console.log(response)
            }
          }
      }).catch((error) => {
        console.log(error)
        let error_text = 'Certificate has not created! '+error.message
        this.props.updateNotification({status: 'error', text: error_text})
      })
      
    }
  
  render() {  

          const stepNumber = this.props.stepNumber
          if (stepNumber !== 7) {// Prop: The current step 
            return null
          }

          const tags = this.props.inputTags
          const stateName = this.props.stateName;
          const countryName = this.props.countryName;
          const localityName = this.props.localityName;
          const organization = this.props.organization;
          const organizationUnit = this.props.organizationUnit;
          const commonName = this.props.commonName;
          const keyAlgorithm = this.props.keyAlgorithm;
          const customCRLName = this.props.customCRLName;
          const validFor = this.props.validFor;
          const tagName = this.props.tagName;
          const tagNameValue = this.props.tagNameValue;
          const viewCaType = this.props.viewCaType;
          const public_key_checkbox = this.props.enforce_unique_public_key_checkbox;
          const distribution_point_checkbox =this.props.default_crl_distribution_point_checkbox;
          const loadingScreen = this.props.loadingScreen
          let issuerSubjectDN = ''
          
          if (viewCaType === 'SUBCA') {
            issuerSubjectDN = this.props.selectedCa.subject_dn
          }
            return (
              
              
                <div className="text-center ">
                  {loadingScreen === false
                ? 
                <div className="form-area px-2">
                    <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Review and create</p>
                      <hr className="ml-4"/>
                      <p className="text-base sm:text-xl text-gray-600 italic mt-3">
                          Review your choices.
                      </p>
                    </div>

                    <table className="custom-table-view">
                    <tbody>
                    <tr className="header-tbl">
                      <td>CA type</td>
                      <td>
                            <button className="ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState} >  
                              <FaEdit/>
                            </button>
                      </td>
                    </tr>
                      
                    {viewCaType && <tr><td>CA type:</td><td> {viewCaType}</td></tr>}
                    {viewCaType === 'SUBCA' && <tr><td>Issuer:</td><td> {issuerSubjectDN}</td></tr>}  
                    
                      <tr className="header-tbl">
                      <td>CA subject name</td>
                      <td>
                            <button className=" ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState1}>
                              <FaEdit/>
                            </button>
                      </td>
                      </tr>
                      {organization && <tr><td>Organization (O):</td><td> {organization}</td></tr>}
                      {organizationUnit && <tr><td>Organization Unit (OU):</td><td> {organizationUnit}</td></tr>}
                      {countryName && <tr><td>Country Name (C):</td><td> {countryName}</td></tr>}
                      {stateName && <tr><td>State or Province Name:</td><td> {stateName}</td></tr>}
                      {localityName && <tr><td>Locality Name:</td><td> {localityName}</td></tr>}
                      {commonName && <tr><td>Common Name (CN):</td><td> {commonName}</td></tr>}
                     
                      <tr className="header-tbl">
                      <td>Key algorithm</td>
                      <td>
                        <button className="ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState2}> 
                          <FaEdit/>
                        </button>
                      </td>
                      </tr>
                      {keyAlgorithm && <tr><td>Key Algorithm:</td><td> {keyAlgorithm}</td></tr>}
                      
                      <tr className="header-tbl">
                      <td>Revocation</td>
                      <td>
                          <button className="ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState3} > 
                            <FaEdit/>
                          </button>
                      </td>
                      </tr>
                      {distribution_point_checkbox && <tr><td>CRL URL:</td><td> enabled </td></tr>}
                      {customCRLName && <tr><td>Custom CRL Name:</td><td> {customCRLName}</td></tr>}
                      {validFor && <tr><td>Valid for:</td><td> {validFor} days</td></tr>}
                    
                    <tr className="header-tbl">
                      <td>Tags</td>
                      <td> 
                          <button className=" ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState4} > 
                            <FaEdit/>
                          </button>
                      </td>
                    </tr>
                      {tags && tags.length > 0 && <tr className="header-tbl"><td>Name:</td><td>Value</td></tr>}
                      {tags && tags.length > 0 && tags.map( (ee, index) => {
                        return ( <tr>
                          <td>{ee.name}</td>
                          <td>{ee.value}</td>
                          </tr>)
                      })}
                       
                    <tr className="header-tbl">
                      <td>CA configuration</td>
                      <td>
                          <button className="ml-3 bg-transparent hover:bg-palette-4 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" onClick={this.handleStepState5} >
                            <FaEdit/>
                          </button>
                      </td>
                    </tr>
                      <tr><td>Enforce unique public key:</td><td> {public_key_checkbox === true ? "Active" : "Not Active"}</td></tr>
                      <tr><td>CRL distribution point:</td><td> {distribution_point_checkbox === true ? "Active" : "Not Active"}</td></tr>
                    </tbody>
                    </table>
                    <hr className="horizontal-line1"/> 
                    
                    <button role="create_ca" className="transition duration-500 ease-in-out bg-palette-5 hover:palette-5 transform hover:-translate-y-1 hover:scale-110 bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4" onClick={this.handleSubmit}>Create CA</button>
                </div>
                : <div>
                  <img className="m-auto" src={image} alt="edit"/>
                  <p className="text-gray-600 italic text-xl mt-4">Generating your CA</p>
                  </div>}
                </div>
                
            )
            }
        }
      
      )
      
