import React, { Component } from "react"
import Step5 from "./cas/Form1/Step5"
import { connect } from "react-redux"
import {getHelperIsExpired, getHelpersExpiredData} from '../helpers.js'
var moment = require('moment');

  // Redux where we import state
  const mapStateToProps = state => {
      return {
        selectedCa: state.selectedCa,
        tab: state.tab,
        tagEditForm: state.tagEditForm,
        inputTags: state.inputTags,
        alertConfirm: state.alertConfirm,
        spinner: state.spinner,
        crl_edit_input: state.crl_edit_input,
        crl_edit_form: state.crl_edit_form,
        alertOption: state.alertOption
      };
    };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    showAlert: (data) => {
      return { type: "UPDATE_ALERT", alertPopup: data }
    },
    updateTagEditForm: data => {
      return { type: "TAG_EDIT_FORM", tagEditForm: data}
    },
    updateCurrentStep: currentStep => { //needed to show the tag edit form (step5 form 1 form needs to have a step)
      return { type: "CURRENT_COMPONENT_INFO", stepNumber: currentStep}
    },
    updateTagEditId: data => {
      return { type: "UPDATE_ID_TAG_EDIT_CA", tagEditCaId: data }
    },
    updateCurrentTab: currentTab => {
      return { type: "UPDATE_TAB", tab: currentTab }
    },
    updateAlertConfirm: (data) => {
      return { type: "UPDATE_ALERT_CONFIRM", alertConfirm: data }
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateSelectedCa: filltered_array => {
        return { type: "UPDATE_SELECTED_CA", selectedCa: filltered_array[0] }
    },
    updateCurrentTab: currentTab => {
        return { type: "UPDATE_TAB", tab: currentTab }
    },
    updateCrlEditInput: crl => {
      return { type: 'CRL_EDIT_INPUT', crl_edit_input : crl}
    },
    updateAlertOption: option => {
      return { type: 'UPDATE_ALERT_OPTION', alertOption : option}
    },
    updateDomainError: data => {
      return { type: "NOT_VALID_DOMAIN_ERROR", domainValidationError: data } 
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(

    class Info extends Component {

      componentDidMount() {
        this.props.updateAlertConfirm(false)
      }

      componentDidUpdate() {
        const alertConfirm = this.props.alertConfirm
        const alertOption = this.props.alertOption
        const crl_value = this.props.crl_edit_input
        if (alertConfirm) {
            if (alertConfirm === 'delete_crl' || alertConfirm === 'enable_crl') {
              switch (alertOption) {
                case 'disable':
                  this.saveCrlAPI(false)
                break;

                case 'enable':    
                  this.saveCrlAPI(crl_value)
                break;

                case 'random':
                  this.saveCrlAPI('')
                break; 
              }
            }
        }
      }

      is_expired(ca) {
        let res = getHelperIsExpired(ca)
        return res
      }

      getExpired() {
        let ca = this.props.selectedCa
        let res = getHelpersExpiredData(ca)
        return res
      }

      handleTabs = (e) => {
        e.preventDefault()
        let currentTab = e.target.value
        this.props.updateCurrentTab(currentTab)
      }

      handleTagEdit = (e) => {
        e.preventDefault()
        const tagEdit = this.props.tagEditForm
        this.props.updateTagEditForm(!tagEdit)
        let currentStep = 'tag_edit'
        this.props.updateTagEditId(this.props.selectedCa.id)
        this.props.updateCurrentStep(currentStep)
      }

      handleEditCRL = () => {
        let selectedCa = this.props.selectedCa
        let action = 'enable_crl'
        if (selectedCa.default_crl == 1) {
          action = 'delete_crl'
        } 

        switch(action) {
          case 'enable_crl':
            this.props.updateAlertOption('random')
          break;
          case 'delete_crl':
            this.props.updateAlertOption('disable')
          break;
        }
        this.props.updateCrlEditInput('')
        this.props.updateDomainError(false)        
        this.props.showAlert({action: action, text: '', header: 'Edit CRL distribution'})
      }

      saveCrlAPI = async (crl) => {
        this.props.updateAlertConfirm(false)
        this.props.changeSpinner(true)
        let cert = this.props.selectedCa
        let json = {}
        
        switch (crl) {
          case '': //random
            json = {"ca": { "custom_crl_name": '', "default_crl": true }}
          break;

          case false: //disable
            json = {"ca": { "custom_crl_name": '', "default_crl": false }}
          break;

          default: //any value
            json = {"ca": { "custom_crl_name": encodeURI(crl), "default_crl": true }}
          break;
        }

        let bodyJson = JSON.stringify(json)
        
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        let fetch_url = "/cas/"+cert.id 
        if (this.props.adminFetch === "true") {
          fetch_url = "/admin/cas/"+cert.id
        }
        await fetch(fetch_url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
          body: bodyJson,
        })
        .then(res => res.json(res))
        .then(res => {
            this.props.changeSpinner(false)
            if (res.error) {
                this.props.updateNotification({status: 'error', text: res.message})
            } else {
                if (res.ca.default_crl) {
                  cert.crl_input = res.ca.crl_input
                }
                cert.default_crl = res.ca.default_crl
                this.props.updateSelectedCa([cert])
                this.props.updateCurrentTab('tags') //doesn't work as expected without it
                this.props.updateCurrentTab('status') //doesn't work as expected without it
                this.props.changeSpinner(false)
                this.props.updateNotification({status: 'success', text: 'Certificate CRL is updated'})
            }
        }).catch(error => {
            console.log(error)
            this.props.changeSpinner(false)
            this.props.updateNotification({status: 'error', text: 'Certificate CRL is not updated'})
        })
      }

      render() { 
        const expired = this.getExpired()
        const selectedCa = this.props.selectedCa
        const tab = this.props.tab
        const tagEdit = this.props.tagEditForm
        const tags = this.props.inputTags
        const crl_form = this.props.crl_edit_form
        //const crl_input = this.props.crl_edit_input

        return (
          <div className=" pb-6 sm:pb-0 px-6 md:px-2 lg:px-10 mt-12 w-full">
                        <ul className="flex border-b mt-10 ">
                          <li key="tab_switch_1" className=" mr-0 sm:mr-1 ">
                            <button role="status_tab_btn" className={tab === "status" ? "bg-palette-5 inline-block  border-l border-t border-r rounded-t py-2 px-1 sm:px-4  text-white font-bold text-xs sm:text-base lg:text-lg" : "bg-palette-4 inline-block  border-l border-t border-r rounded-t py-2 px-1 sm:px-4  text-white font-bold font-semibold text-xs sm:text-base lg:text-lg"} value="status" onClick={this.handleTabs}>Status</button>
                          </li>
                          <li key="tab_switch_2" className="mr-0 sm:mr-1">
                            <button role="tags_tab_btn" className={tab === "tags" ? "bg-palette-5 inline-block  border-l border-t border-r rounded-t py-2 px-1 sm:px-4  text-white font-bold text-xs sm:text-base lg:text-lg" : "bg-palette-4 inline-block  border-l border-t border-r rounded-t py-2 px-1 sm:px-4  text-white font-bold font-semibold text-xs sm:text-base lg:text-lg"} value="tags" onClick={this.handleTabs}>Tags</button>
                          </li>
                         
                        </ul>
                          
                          
                        <div className="py-6 bg-palatte-2 text-base">
                            <ul className={tab === "status" ? "text-left ml-6 sm:ml-12 text-xs sm:text-base lg:text-lg" : "hidden"}>
                                <li key="tab_1_li_1" className="ml-2 font-bold text-gray-600">Status: Active</li>
                                <li key="tab_1_li_2" className="ml-2 font-bold text-gray-600">Detailed Status: Able to issue private certificate</li>
                                <p className="text-lg sm:text-xl mt-2 font-bold text-gray-700">Subject</p>
                                <hr className="ml-4 mb-2 font-bold text-gray-600"/>
                                <li key="tab_1_li_3" className="ml-2 font-bold text-gray-600">Type: {selectedCa.ca_type}</li>
                                <li key="tab_1_li_4" className="ml-2 font-bold text-gray-600">Created: {moment(selectedCa.created_at).format('MM-DD-YYYY, h:mm A')}</li>
                                <li key="tab_1_li_5" className="ml-2 font-bold text-gray-600">Valid for: {selectedCa.valid_for} days</li>
                                <li key="tab_1_li_6" className="ml-2 font-bold text-gray-600">Expired: {this.is_expired(selectedCa) === false ? <span>will be expired from {expired['days']} days, on {moment(expired['expired_at']).format('MM-DD-YYYY, h:mm A')}</span> : <span>expired from {moment(expired['expired_at']).format('MM-DD-YYYY, h:mm A')}</span>}</li>                                
                                <li key="tab_1_li_7" className="ml-2 font-bold text-gray-600">SubjectDN: {selectedCa.subject_dn}</li>
                                <li key="tab_1_li_8" className="ml-2 font-bold text-gray-600">Key algorithm: {selectedCa.key_algorithm}</li>
                                <li key="tab_1_li_9" className="ml-2 font-bold text-gray-600">Signature algorithm: {selectedCa.signature_algorithm}</li>
                                {selectedCa.created_at && <li key="tab_1_li_10" className="ml-2 font-bold text-gray-600">Created By: {selectedCa.created_by}</li>}
                                <li key="tab_1_li_12" className="ml-2 font-bold text-gray-600">Default CRL: {selectedCa.default_crl == 1  ? <span>Enabled</span> : <span>Disabled</span>} <button role="enable_crl" onClick={this.handleEditCRL} className="ml-2 bg-palette-5 p-1 text-white text-sm rounded-full">Edit</button></li>
                                {selectedCa.default_crl == 1 && <li key="tab_1_li_14" className="ml-2 font-bold text-gray-600">CRL URL: {decodeURI(selectedCa.crl_input)}</li>}
                            </ul>

                            

                            <ul className={tab === "tags" ? "text-left ml-6 sm:ml-12 text-xs sm:text-base lg:text-lg" : "hidden"}>
                              <p className="text-lg sm:text-xl font-bold text-gray-700">Tags</p>
                              <hr className="ml-4 mb-2 font-bold text-gray-600"/>
                              <li key="tab_2_li_1" className="ml-2  text-gray-600 italic">To help you manage your certificate authorities you can optionally assign your own metadata to each resource in the form of tags.</li>
                              {tags && tags.length > 0 && tags.map( (tag, index) => {
                                return (<li key={"tab_2_li_tag_"+index} className="ml-2 font-bold text-gray-600">{tag.name}: {tag.value}</li>)
                              })}
                              <button role="add_tag" className="bg-palette-5 inline-block py-1 px-2 rounded text-white font-bold text-sm mt-6" onClick={this.handleTagEdit}>Add Tag</button>
                            </ul>
                            <div className="w-full pt-12 px-8">
                            {tagEdit && tab === "tags" && <Step5/>}
                            </div>
                          </div> 
                      </div>
        )
             
      }
    
    })