const reducer = ( state, action ) => {
    state = currentStepInfo(state, action)
    state = infoAboutKeyWindow(state, action)
    state = CheckboxWindow(state, action)
    state = SetOrganizationName(state, action)
    state = SetOrganizationUnitName(state, action)
    state = SetCountryName(state, action)
    state = SetStateName(state, action)
    state = SetLocalityName(state, action)
    state = SetCommonName(state, action)
    state = SetKeyAlgorithm(state, action)
    state = SetKeyHashLength(state, action)
    state = UpdateAcmInfo(state, action)
    state = UpdateTagName(state, action)
    state = UpdateTagNameValue(state, action)
    state = UpdateDisableForm(state, action)
    state = UpdateRevocationWindow(state, action)
    state = UpdateCustomCRLName(state, action)
    state = UpdateValidFor(state, action)
    state = Username(state, action)
    state = Password(state, action)
    state = PasswordConfirmation(state,action)
    state = CurrentUser(state, action)
    state = updateTagEditId(state, action)
    state = ShowErrorMessage(state, action)
    state = UpdateInputValidity(state, action)
    state = UpdateTab(state, action)
    state = CreateCA(state, action)
    state = UpdateCas(state, action)
    state = SelectedCa(state, action)
    state = UpdateIdFromSelectedCa(state, action)
    state = UpdateLoadingScreen(state, action)
    state = UpdateCertificateData(state, action)
    state = CurrentStepForm2Info(state,action)
    state = UpdateMainNavBar(state, action)
    state = UpdateButtonWindowOnDashboardPage(state, action)
    state = UpdateSelectedCaObject(state, action)
    state = UpdateValidDomain(state, action)
    state = UpdateDomainName(state, action)
    state = UpdateCertDayDuration(state, action)
    state = UpdateCertYearDuration(state, action)
    state = UpdateCertDateDuration(state, action)
    state = UpdateEePublicKey(state, action)
    state = UpdateEndEntityArray(state, action)
    state = UpdateSelectedEndEntity(state, action)
    state = UpdateCmPageTab(state, action)
    state = UpdateEeName(state, action)
    state = UpdateMessageWindow(state, action)
    state = UpdateCrlValidFor(state, action)
    state = UpdateModalWindow(state, action)
    state = ShowErrorMessageWindow(state, action)
    state = ChainCertificate(state, action)
    state = CsrCertificate(state, action)
    state = PrivateKey(state, action)
    state = SubjectDn(state, action)
    state = DownloadDropdown(state, action)
    state = CaSelectedTeam(state, action)
    state = EeSelectedTeam(state, action)
    state = DropdownEeTeam(state, action)
    state = Team(state, action)
    state = PasswordModal(state, action)
    state = PfxPassword(state, action)
    state = Spinner(state, action)
    state = SelectedNestedSubCa(state, action)
    state = ShowNestedCa(state, action)
    state = RenderSubCaWindow(state, action)
    state = PathCa(state, action)
    state = CleanPathCa(state, action)
    state = ArrayWithSubCas(state, action)
    state = SubCaEEId(state, action)
    state = CertificateManagerSpinner(state, action)
    state = ImportedCSR(state, action)
    state = updateCsrError(state, action)
    state = SigningAlgorithm(state, action)
    state = UpdateValidFor1(state, action)
    state = UpdateEeOrganization(state, action)
    state = UpdateEeLocality(state, action)
    state = UpdateEeState(state, action)
    state = UpdateEeCountry(state, action)
    state = UpdateEmptyOrganizationNameError(state, action)
    state = UpdateEmptyCommonNameError(state, action)
    state = UpdateEEEmptyOrganizationNameError(state, action)
    state = UpdateEEEmptySubjectDnError(state, action)
    state = UpdateOptionButtonCertificateManagerPage(state, action)
    state = UpdateOptionButtonDropDownManagerPage(state, action)
    state = UpdateRequestButtonDropDownManagerPage(state, action)
    state = UpdateEeSelect(state, action)
    state = UpdateDefaultCrlDistributionPointCheckbox(state, action)
    state = UpdateOcspServiceDefaultUrlCheckbox(state, action)
    state = UpdateEnforceUniquePublicKeyCheckbox(state, action)
    state = UpdateDefaultCrlDistributionPointInput(state, action)
    state = UpdateOcspServiceDefaultUrlInput(state, action)
    state = UpdateCrlEditInput(state, action)
    state = UpdateCrlEditForm(state, action)
    state = UpdateCrlCheckboxCrl(state, action)
    state = updateTabNumberDatePickForm2(state, action)
    state = updateEEemptyDaysError(state, action)
    state = updateNextStepAllowForm2(state, action)
    state = updateDomainError(state, action)
    state = updateStateCASelection(state, action)
    state = UpdateDomainBuf(state, action)
    state = updateDomains(state, action)
    state = updateCaFormType(state, action)
    state = updatePostalCode(state, action)
    state = updateUnit(state, action)
    state = updateStreet(state, action)
    state = updateEmail(state, action)
    state = updateCertificateProfile(state, action)
    state = updateEndEntityProfile(state, action)
    state = updateForm2Validation(state, action)
    state = updateEESelectType(state, action)
    state = UpdateTagArray(state, action)
    state = UpdateTagResults(state, action)
    state = UpdateTagNumResults(state, action)
    state = updateTagEditForm(state, action)
    state = updateTagsBuf(state, action)
    state = updateNotification(state, action)
    state = updateCheckboxes(state, action)
    state = showAlert(state, action)
    state = updateAlertConfirm(state, action)
    state = updateImportedPubKey(state, action)
    state = updateModalSpinner(state, action)
    state = updateP7bData(state, action)
    state = updatePagination(state, action)
    state = changePage(state, action)
    state = updateAdminOption(state, action)
    state = updatePerPage(state, action)
    state = updateAlertOption(state, action)

    return state
}

const changePage = (state, action) => {
    switch (action.type) {
        case "UPDATE_PAGE_NUMBER":
            return {
                ...state,
                page: action.page
            };
            break
        }
        return state;
}

const updateAlertOption = (state, action) => {
    switch (action.type) {
        case "UPDATE_ALERT_OPTION":
            return {
                ...state,
                alertOption: action.alertOption
            };
            break
        }
        return state;
}

const updatePerPage = (state, action) => {
    switch (action.type) {
        case "UPDATE_PER_PAGE":
            return {
                ...state,
                perPage: action.perPage
            };
            break
        }
        return state;
}

const updateAdminOption = (state, action) => {
    switch (action.type) {
        case "ADMIN_OPTION":
            return {
                ...state,
                adminOption: action.adminOption
            };
            break
        }
        return state;
}

const updateModalSpinner = (state, action) => {
    switch (action.type) {
        case "SPINNER_MODAL":
            return {
                ...state,
                spinnerModal: action.spinnerModal
            };
            break
        }
        return state;
}

const updatePagination = (state, action) => {
    switch (action.type) {
        case "UPDATE_PAGINATION":
            return {
                ...state,
                pagination: action.pagination
            };
            break
        }
        return state;
}

const updateP7bData = (state, action) => {
    switch (action.type) {
        case "UPDATE_P7B_DATA":
            return {
                ...state,
                p7b: action.p7b
            };
            break
        }
        return state;
}

const updateNotification = (state, action) => {
    switch (action.type) {
        case "UPDATE_NOTIFICATION":
            return {
                ...state,
                notification: action.notification
            };
            break
        }
        return state;
}

const showAlert = (state, action) => {
    switch (action.type) {
        case "UPDATE_ALERT":
            return {
                ...state,
                alertPopup: action.alertPopup
            };
            break
        }
        return state;
}

const updateAlertConfirm = (state, action) => {
    switch (action.type) {
        case "UPDATE_ALERT_CONFIRM":
            return {
                ...state,
                alertConfirm: action.alertConfirm
            };
            break
        }
        return state;
}


const updateCheckboxes = (state, action) => {
    switch (action.type) {
        case "UPDATE_CHECKBOXES":
            return {
                ...state,
                checkboxes: action.checkboxes
            };
            break
        }
        return state;
}

const updateEESelectType = (state, action) => {
    switch (action.type) {
        case "EE_SELECT_TYPE":
            return {
                ...state,
                eeSelectType: action.eeSelectType
            };
            break
        }
        return state;
}

const updateTagsBuf = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAGS_BUF":
            return {
                ...state,
                tagsBuf: action.tagsBuf
            };
            break
        }
        return state;
}

const updateTagEditForm = (state, action) => {
    switch (action.type) {
        case "TAG_EDIT_FORM":
            return {
                ...state,
                tagEditForm: action.tagEditForm
            };
            break
        }
        return state;
}


const updateForm2Validation = (state, action) => {
    switch (action.type) {
        case "UPDATE_FORM2_VALIDATION":
            return {
                ...state,
                form2Validation: action.form2Validation
            };
            break
        }
        return state;
    }

const updateStateCASelection = (state, action) => {
    switch (action.type) {
        case "UPDATE_STATE_SELECTION":
            return {
                ...state,
                stateCASelection: action.stateCASelection
            };
            break
        }
        return state;
    }

const updatePostalCode = (state, action) => {
    switch (action.type) {
        case "UPDATE_POSTAL_CODE":
            return {
                ...state,
                eePostalCode: action.eePostalCode
            };
            break
        }
        return state;
    }

const updateUnit = (state, action) => {
    switch (action.type) {
        case "UPDATE_UNIT":
            return {
                ...state,
                eeUnit: action.eeUnit
            };
            break
        }
        return state;
    }

const updateStreet = (state, action) => {
    switch (action.type) {
        case "UPDATE_STREET":
            return {
                ...state,
                eeStreet: action.eeStreet
            };
            break
        }
        return state;
    }

const updateEndEntityProfile = (state, action) => {
    switch (action.type) {
        case "UPDATE_END_ENTITY_PROFILE":
            return {
                ...state,
                eeEndEntityProfile: action.eeEndEntityProfile
            };
            break
        }
        return state;
    }

const updateCertificateProfile = (state, action) => {
    switch (action.type) {
        case "UPDATE_CERTIFICATE_PROFILE":
            return {
                ...state,
                eeCertProfile: action.eeCertProfile
            };
            break
        }
        return state;
    }

const updateEmail = (state, action) => {
    switch (action.type) {
        case "UPDATE_EMAIL":
            return {
                ...state,
                eeEmail: action.eeEmail
            };
            break
        }
        return state;
    }

const updateCaFormType = (state, action) => {
    switch (action.type) {
        case "UPDATE_CA_FORM_TYPE":
            return {
                ...state,
                caFormType: action.caFormType
            };
            break
        }
        return state;
    }

const updateEEemptyDaysError = (state, action) => {
    switch (action.type) {
        case "EMPTY_EE_DAYS_ERROR":
            return {
                ...state,
                emptyEEDaysError: action.emptyEEDaysError
            };
            break
        }
        return state;
    }

const updateTabNumberDatePickForm2 = (state, action) => {
    switch (action.type) {
        case "UPDATE_NUMBER_DATEPICKER_FORM2":
            return {
                ...state,
                tabNumberDatePickForm2: action.tab
            };
            break;
    }
  return state
}
const updateNextStepAllowForm2 = (state, action) => {
    switch (action.type) {
        case "ALLOW_NEXT_STEP_FORM2":
            return {
                ...state,
                allowNextStepForm2: action.allowNextStep
            };
            break
        }
        return state;
    }

const currentStepInfo = (state, action) => {
    switch (action.type) {
        case "CURRENT_COMPONENT_INFO":
            return {
                ...state,
                stepNumber: action.stepNumber
            };
            break
        }
        return state;
    }

const infoAboutKeyWindow = (state, action) => {
    switch (action.type) {
        case "INFO_ABOUT_WINDOW":
            return {
                ...state,
                keyAlgorithmWindow: action.keyAlgorithmWindow
            };
            break
        }
        return state;
    } 

const CheckboxWindow = (state, action) => {
    switch (action.type) {
        case "CA_TYPE":
            return {
                ...state,
                viewCaType: action.viewCaType
            };
            break
        }
        return state;
    }

const SetOrganizationName = (state, action) => {
    switch (action.type) {
        case "SET_ORGANIZATION":
            return {
                ...state,
                organization: action.organization
            };
            break
        }
        return state;
    }
    
const SetOrganizationUnitName = (state, action) => {
    switch (action.type) {
        case "SET_ORGANIZATION_UNIT":
            return {
                ...state,
                organizationUnit: action.organizationUnit
            };
            break
        }
        return state;
    }  
    
const SetCountryName = (state, action) => {
    switch (action.type) {
        case "SET_COUNTRY_NAME":
            return {
                ...state,
                countryName: action.countryName
            };
            break
        }
        return state;
    } 

const updateTagEditId = (state, action) => {
    switch (action.type) {
        case "UPDATE_ID_TAG_EDIT_CA":
            return {
                ...state,
                tagEditCaId: action.tagEditCaId
            };
            break
        }
        return state;
    } 
    
const SetStateName = (state, action) => {
    switch (action.type) {
        case "SET_STATE_NAME":
            return {
                ...state,
                stateName: action.stateName
            };
            break
        }
        return state;
    } 
    
const SetLocalityName = (state, action) => {
    switch (action.type) {
        case "SET_LOCALITY_NAME":
            return {
                ...state,
                localityName: action.localityName
            };
            break
        }
        return state;
    }

const SetCommonName = (state, action) => {
    switch (action.type) {
        case "SET_COMMON_NAME":
            return {
                ...state,
                commonName: action.commonName
            };
            break
        }
        return state;
    }

const SetKeyAlgorithm = (state, action) => {
    switch (action.type) {
        case "SET_KEY_ALGORITHM":
            return {
                ...state,
                keyAlgorithm: action.keyAlgorithm
            };
            break
        }
        return state;
    }

const SetKeyHashLength = (state, action) => {
    switch (action.type) {
        case "SET_KEY_HASH":
            return {
                ...state,
                keyHashLength: action.keyHashLength
            };
            break
        }
        return state;
    }    

const UpdateAcmInfo = (state, action) => {
    switch (action.type) {
        case "UPDATE_ACM":
            return {
                ...state,
                acmInfo: action.acmInfo
            };
            break
        }
        return state;
    }

const UpdateTagName = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAG_NAME":
            return {
                ...state,
                tagNameBuf: action.tagName
            };
            break
        }
        return state;
    }

const UpdateTagResults = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAG_RESULTS":
            return {
                ...state,
                tagNameValue: action.tagNameValue
            };
            break
        }
        return state;
    }

const UpdateTagNumResults = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAG_RESULTS_NUMBER":
            return {
                ...state,
                tagName: action.tagName
            };
            break
        }
        return state;
    }

const UpdateTagArray = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAG_ARRAY":
            return {
                ...state,
                inputTags: action.inputTags
            };
            break
        }
        return state;
    }

const UpdateTagNameValue = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAG_NAME_VALUE":
            return {
                ...state,
                tagValBuf: action.tagNameValue
            };
            break
        }
        return state;
    }

const UpdateDisableForm = (state, action) => {
    switch (action.type) {
        case "UPDATE_DISABLE_FORM":
            return {
                ...state,
                disableForm: action.disableForm
            };
            break
        }
        return state;
    }

const UpdateRevocationWindow = (state, action) => {
    switch (action.type) {
        case "UPDATE_REVOCATION_WINDOW":
            return {
                ...state,
                revocationList: action.revocationList
            };
            break
        }
        return state;
    }

const UpdateCustomCRLName = (state, action) => {
    switch (action.type) {
        case "UPDATE_CUSTOM_CRL_NAME":
            return {
                ...state,
                customCRLName: action.customCRLName
            };
            break
        }
        return state;
    }

const UpdateValidFor = (state, action) => {
    switch (action.type) {
        case "UPDATE_VALID_FOR":
            return {
                ...state,
                validFor: action.validFor
            };
            break
        }
        return state;
    }

const Username = (state, action) => {
    switch (action.type) {
        case "UPDATE_USERNAME":
            return {
                ...state,
                username: action.username
            };
            break
        }
        return state;
    }

const Password = (state, action) => {
    switch (action.type) {
        case "UPDATE_PASSWORD":
            return {
                ...state,
                password: action.password
            };
            break
        }
        return state;
    }

const PasswordConfirmation = (state, action) => {
    switch (action.type) {
        case "UPDATE_PASSWORD_CONFIRMATION":
            return {
                ...state,
                passwordConfirmation: action.passwordConfirmation
            };
            break
        }
        return state;
    }

const CurrentUser = (state, action) => {
    switch (action.type) {
        case "UPDATE_CURRENT_USER":
            return {
                ...state,
                currentUser: action.currentUser
            };
            break
        }
        return state;
    }

const ShowErrorMessage = (state, action) => {
    switch (action.type) {
        case "SHOW_ERROR_MESSAGE":
            return {
                ...state,
                errorMessage: action.errorMessage
            };
            break
        }
        return state;
    }

const UpdateInputValidity = (state, action) => {
    switch (action.type) {
        case "UPDATE_INPUT_VALIDITY":
            return {
                ...state,
                inputValidity: action.inputValidity
            };
            break
        }
        return state;
    } 

const UpdateTab = (state, action) => {
    switch (action.type) {
        case "UPDATE_TAB":
            return {
                ...state,
                tab: action.tab
            };
            break
        }
        return state;
    }

    const CreateCA = (state, action) => {
        switch (action.type) {
            case "CREATE_CA":
                return {
                    ...state,
                    createdCA: action.createdCA
                };
                break
            }
            return state;
        }

    const UpdateCas = (state, action) => {
        switch (action.type) {
            case "UPDATE_CAS":
                return {
                    ...state,
                    cas: action.cas
                };
                break
            }
            return state;
        } 
        
        
    const SelectedCa = (state, action) => {
        switch (action.type) {
            case "UPDATE_SELECTED_CA":
                return {
                    ...state,
                    selectedCa: action.selectedCa
                };
                break
            }
            return state;
        }   
        
        
    const UpdateIdFromSelectedCa = (state, action) => {
        switch (action.type) {
            case "UPDATE_ID_FROM_SELECTED_CA":
                return {
                    ...state,
                    idFromSelectedCa: action.idFromSelectedCa
                };
                break
            }
            return state;
        } 



    const UpdateLoadingScreen = (state, action) => {
        switch (action.type) {
            case "UPDATE_LOADING_SCREEN":
                return {
                    ...state,
                    loadingScreen: action.loadingScreen
                };
                break
            }
            return state;
        }     
        
       
    const UpdateCertificateData = (state, action) => {
        switch (action.type) {
            case "UPDATE_CERTIFICATE_DATA":
                return {
                    ...state,
                    certificateData: action.certificateData
                };
                break
            }
            return state;
        } 
        
    const CurrentStepForm2Info = (state, action) => {
        switch (action.type) {
            case "CURRENT_COMPONENT_INFO_FORM_2":
                return {
                    ...state,
                    stepNumberForm2: action.stepNumberForm2
                };
                break
            }
            return state;
        }

    const UpdateMainNavBar = (state, action) => {
        switch (action.type) {
            case "UPDATE_MAIN_NAV_BAR":
                return {
                    ...state,
                    mainNavBar: action.mainNavBar
                };
                break
            }
            return state;
        } 
        
    const UpdateButtonWindowOnDashboardPage = (state, action) => {
        switch (action.type) {
            case "UPDATE_BUTTON_WINDOW_ON_DASHBOARD_PAGE":
                return {
                    ...state,
                    buttonWindowOnDashboardPage: action.buttonWindowOnDashboardPage
                };
                break
            }
            return state;
        } 

    const UpdateSelectedCaObject = (state, action) => {
        switch (action.type) {
            case "UPDATE_SELECTED_CA_OBJECT":
                return {
                    ...state,
                    selectedCaObject: action.selectedCaObject
                };
                break
            }
            return state;
        }
        
    const UpdateValidDomain = (state, action) => {
        switch (action.type) {
            case "UPDATE_VALID_DOMAIN":
                return {
                    ...state,
                    domainValidation: action.domainValidation
                };
                break
            }
            return state;
        }  
        
    const UpdateDomainName = (state, action) => {
        switch (action.type) {
            case "UPDATE_DOMAIN_NAME":
                return {
                    ...state,
                    domainName: action.domainName
                };
                break
            }
            return state;
        } 

    const UpdateDomainBuf = (state, action) => {
        switch (action.type) {
            case "UPDATE_DOMAIN_BUFFER":
                return {
                    ...state,
                    domainBuf: action.domainBuf
                };
                break
            }
            return state;
        } 

    const updateDomains = (state, action) => {
        switch (action.type) {
            case "UPDATE_DOMAIN_ARRAY":
                return {
                    ...state,
                    inputDomains: action.inputDomains
                };
                break
            }
            return state;
        } 
        
    const UpdateCertDayDuration = (state, action) => {
        switch (action.type) {
            case "UPDATE_CERT_DAY_DURATION":
                return {
                    ...state,
                    certDayDuration: action.certDayDuration
                };
                break
            }
            return state;
        }

    const UpdateCertYearDuration = (state, action) => {
        switch (action.type) {
            case "UPDATE_CERT_YEAR_DURATION":
                return {
                    ...state,
                    certYearDuration: action.certYearDuration
                };
                break
            }
            return state;
        }
        
    const UpdateCertDateDuration = (state, action) => {
        switch (action.type) {
            case "UPDATE_CERT_DATE_DURATION":
                return {
                    ...state,
                    certDateDuration: action.certDateDuration
                };
                break
            }
            return state;
        } 

    const UpdateEePublicKey = (state, action) => {
        switch (action.type) {
            case "UPDATE_EE_PUBLIC_KEY":
                return {
                    ...state,
                    eePublicKey: action.eePublicKey
                };
                break
            }
            return state;
        }
        
    const UpdateEndEntityArray = (state, action) => {
        switch (action.type) {
            case "UPDATE_END_ENTITY_ARRAY":
                return {
                    ...state,
                    endEntityArray: action.endEntityArray
                };
                break
            }
            return state;
        } 
     
    const UpdateSelectedEndEntity = (state, action) => {
        switch (action.type) {
            case "UPDATE_SELECTED_END_ENTITY":
                return {
                    ...state,
                    selectedEndEntity: action.selectedEndEntity
                };
                break
            }
            return state;
        }  
        
    const UpdateCmPageTab = (state, action) => {
        switch (action.type) {
            case "UPDATE_CM_PAGE_TAB":
                return {
                    ...state,
                    cmPageTabs: action.cmPageTabs
                };
                break
            }
            return state;
        }
        
    const UpdateEeName = (state, action) => {
        switch (action.type) {
            case "UPDATE_EE_NAME":
                return {
                    ...state,
                    EeName: action.EeName
                };
                break
            }
            return state;
        }


    const UpdateMessageWindow = (state, action) => {
        switch (action.type) {
            case "UPDATE_MESSAGE_WINDOW":
                return {
                    ...state,
                    messageWindow: action.messageWindow
                };
                break
            }
            return state;
        }

    const UpdateCrlValidFor = (state, action) => {
        switch (action.type) {
            case "UPDATE_CRL_VALID_FOR":
                return {
                    ...state,
                    crlValidFor: action.crlValidFor
                };
                break
            }
            return state;
        }   
        

    const UpdateModalWindow = (state, action) => {
        switch (action.type) {
            case "UPDATE_MODAL_WINDOW":
                return {
                    ...state,
                    modalWindow: action.modalWindow
                };
                break
            }
            return state;
        }

    const ShowErrorMessageWindow = (state, action) => {
        switch (action.type) {
            case "SHOW_ERROR_MESSAGE_WINDOW":
                return {
                    ...state,
                    showErrorMessageWindow: action.showErrorMessageWindow
                };
                break
            }
            return state;
        }

    const ChainCertificate = (state, action) => {
        switch (action.type) {
            case "CHAIN_CERTIFICATE":
                return {
                    ...state,
                    chainCertificate: action.chainCertificate
                };
                break
            }
            return state;
        }

    const CsrCertificate = (state, action) => {
        switch (action.type) {
            case "CSR_CERTIFICATE":
                return {
                    ...state,
                    csrCertificate: action.csrCertificate
                };
                break
            }
            return state;
        }

    const SubjectDn = (state, action) => {
        switch (action.type) {
            case "SUBJECT_DN":
                return {
                    ...state,
                    subjectDN: action.subjectDN
                };
                break
            }
            return state;
        }

    const PrivateKey = (state, action) => {
        switch (action.type) {
            case "PRIVATE_KEY":
                return {
                    ...state,
                    privateKey: action.privateKey
                };
                break
            }
            return state;
        }

    
        const DownloadDropdown = (state, action) => {
            switch (action.type) {
                case "DOWNLOAD_DROPDOWN":
                     return {
                         ...state,
                         downloadDropdown: action.downloadDropdown
                     };
                     break
                 }
            return state;
        }

        const CaSelectedTeam = (state, action) => {
            switch (action.type) {
                case "CA_SELECTED_TEAM":
                    return {
                        ...state,
                        caSelectedTeam: action.caSelectedTeam
                    };
                    break
                }
                return state;
            }

        const EeSelectedTeam = (state, action) => {
            switch (action.type) {
                case "EE_SELECTED_TEAM":
                    return {
                        ...state,
                        eeSelectedTeam: action.eeSelectedTeam
                    };
                    break
                }
                return state;
            }

        const DropdownEeTeam = (state, action) => {
            switch (action.type) {
                case "DROPDOWN_EE_TEAM":
                    return {
                        ...state,
                        dropdownEeTeam: action.dropdownEeTeam
                    };
                    break
                }
                return state;
            }

        const Team = (state, action) => {
            switch (action.type) {
                case "TEAM":
                    return {
                        ...state,
                        team: action.team
                    };
                    break
                }
                return state;
            }


        const PasswordModal = (state, action) => {
            switch (action.type) {
                case "PASSWORD_MODAL":
                    return {
                        ...state,
                        passwordModal: action.passwordModal
                    };
                    break
                }
                return state;
            }

        const PfxPassword = (state, action) => {
            switch (action.type) {
                case "PFX_PASSWORD":
                    return {
                        ...state,
                        pfxPassword: action.pfxPassword
                    };
                    break
                }
                return state;
            }


        const Spinner = (state, action) => {
            switch (action.type) {
                case "SPINNER":
                    return {
                        ...state,
                        spinner: action.spinner
                    };
                    break
                }
                return state;
            }


        const SelectedNestedSubCa = (state, action) => {
            switch (action.type) {
                case "SELECTED_NESTED_SUB_CA":
                    return {
                        ...state,
                        selectedNestedSubCa: action.selectedNestedSubCa
                    };
                    break
                }
                return state;
            }

        const ShowNestedCa = (state, action) => {
            switch (action.type) {
                case "SHOW_NESTED_CA":
                    return {
                        ...state,
                        showNestedCa: action.showNestedCa
                    };
                    break
                }
                return state;
            }

        const RenderSubCaWindow = (state, action) => {
        switch (action.type) {
            case "RENDER_SUB_CA_WINDOW":
                return {
                    ...state,
                    renderSubCaWindow: action.renderSubCaWindow
                };
                break
            }
            return state;
        }  

        const PathCa = (state, action) => {
        switch (action.type) {
            case "PATH_CA":
                return {
                    ...state,
                    pathCa: [...state.pathCa, action.pathCa]
                };
                break
            }
            return state;
        } 
        
        const CleanPathCa = (state, action) => {
            switch (action.type) {
                case "CLEAN_PATH_CA":
                    return {
                        ...state,
                        pathCa: action.pathCa
                    };
                    break
                }
                return state;
            }

        const ArrayWithSubCas = (state, action) => {
            switch (action.type) {
                case "ARRAY_WITH_SUB_CAS":
                    return {
                        ...state,
                        arrayWithSubCas: action.arrayWithSubCas
                    };
                    break
                }
                return state;
            }

        const SubCaEEId = (state, action) => {
            switch (action.type) {
                case "SUB_CA_EE_ID":
                    return {
                        ...state,
                        subCaEEId: action.subCaEEId
                    }
                    break
            }
            return state;
        }


        const CertificateManagerSpinner = (state, action) => {
            switch (action.type) {
                case "CERTIFICATE_MANAGER_SPINNER":
                    return {
                        ...state,
                        certificateManagerSpinner: action.certificateManagerSpinner
                    };
                    break
                }
                return state;
            }

        const ImportedCSR = (state, action) => {
            switch (action.type) {
                case "IMPORTED_CSR":
                    return {
                        ...state,
                        importedCSR: action.importedCSR
                    };
                    break
                }
                return state;
            }

        const updateImportedPubKey = (state, action) => {
            switch (action.type) {
                case "IMPORTED_PUB_KEY":
                    return {
                        ...state,
                        importedPubKey: action.importedPubKey
                    };
                    break
                }
                return state;
            }

        const updateCsrError = (state, action) => {
            switch (action.type) {
                case "CSR_ERROR":
                    return {
                        ...state,
                        csrError: action.csrError
                    };
                    break
                }
                return state;
            }

        const SigningAlgorithm = (state, action) => {
            switch (action.type) {
                case "SIGNING_ALGORITHM":
                    return {
                        ...state,
                        signingAlgorithm: action.signingAlgorithm
                    };
                    break
                }
                return state;
            }


        const UpdateValidFor1 = (state, action) => {
            switch (action.type) {
                case "UPDATE_VALID_FOR_NEW":
                    return {
                        ...state,
                        validFor1: action.validFor1
                    };
                    break
                }
                return state;
            }


        const UpdateEeOrganization = (state, action) => {
            switch (action.type) {
                case "EE_ORGANIZATION":
                    return {
                        ...state,
                        eeOrganization: action.eeOrganization
                    };
                    break
                }
                return state;
            }

        const UpdateEeCountry = (state, action) => {
            switch (action.type) {
                case "EE_COUNTRY":
                    return {
                        ...state,
                        eeCountry: action.eeCountry
                    };
                    break
                }
                return state;
            }

        const  UpdateEeLocality = (state, action) => {
            switch (action.type) {
                case "EE_LOCALITY":
                    return {
                        ...state,
                        eeLocality: action.eeLocality
                    };
                    break
                }
                return state;
            }


        const  UpdateEeState = (state, action) => {
            switch (action.type) {
                case "EE_STATE":
                    return {
                        ...state,
                        eeState: action.eeState
                    };
                    break
                }
                return state;
            }

        const  UpdateEmptyOrganizationNameError = (state, action) => {
            switch (action.type) {
                case "EMPTY_ORGANIZATION_NAME_ERROR":
                    return {
                        ...state,
                        emptyOrganizationNameError: action.emptyOrganizationNameError
                    };
                    break
                }
                return state;
            }
        
        const  UpdateEmptyCommonNameError = (state, action) => {
            switch (action.type) {
                case "EMPTY_COMMON_NAME_ERROR":
                    return {
                        ...state,
                        emptyCommonNameError: action.emptyCommonNameError
                    };
                    break
                }
                return state;
            }


        const  updateDomainError = (state, action) => {
            switch (action.type) {
                case "NOT_VALID_DOMAIN_ERROR":
                    return {
                        ...state,
                        domainValidationError: action.domainValidationError
                    };
                    break
                }
                return state;
            }

        const  UpdateEEEmptyOrganizationNameError = (state, action) => {
            switch (action.type) {
                case "EMPTY_EE_ORGANIZATION_NAME_ERROR":
                    return {
                        ...state,
                        emptyEEOrganizationNameError: action.emptyEEOrganizationNameError
                    };
                    break
                }
                return state;
            }
        
        const  UpdateEEEmptySubjectDnError = (state, action) => {
            switch (action.type) {
                case "EMPTY_EE_SUBJECT_DN_NAME_ERROR":
                    return {
                        ...state,
                        emptyEESubjectDnNameError: action.emptyEESubjectDnNameError
                    };
                    break
                }
                return state;
            }

        const UpdateOptionButtonCertificateManagerPage = (state, action) => {
            switch (action.type) {
                case "OPTION_BUTTON_CERTIFICATE_MANAGER_PAGE":
                    return {
                        ...state,
                        optionButtonCertificateManagerPage: action.optionButtonCertificateManagerPage
                    };
                    break
            }
            return state;
        }

        const UpdateOptionButtonDropDownManagerPage = (state, action) => {
            switch (action.type) {
                case "OPTION_BUTTON_DROPDOWN_MANAGER_PAGE":
                    return {
                        ...state,
                        optionButtonDropDownManagerPage: action.optionButtonDropDownManagerPage
                    };
                    break
            }
            return state;
        }

        const UpdateRequestButtonDropDownManagerPage = (state, action) => {
            switch (action.type) {
                case "REQUEST_BUTTON_DROPDOWN_MANAGER_PAGE":
                    return {
                        ...state,
                        requestButtonDropDownManagerPage: action.requestButtonDropDownManagerPage
                    };
                    break
            }
            return state;
        }

        const  UpdateEeSelect = (state, action) => {
            switch (action.type) {
                case "EE_SELECT":
                    return {
                        ...state,
                        eeSelect: action.eeSelect
                    };
                    break
                }
                return state;
            }


        const  UpdateDefaultCrlDistributionPointCheckbox = (state, action) => {
            switch (action.type) {
                case "DEFAULT_CRL_DISTRIBUTION_POINT_CHECKBOX":
                    return {
                        ...state,
                        default_crl_distribution_point_checkbox: action.default_crl_distribution_point_checkbox
                    };
                    break
                }
                return state;
            }


        const  UpdateOcspServiceDefaultUrlCheckbox = (state, action) => {
            switch (action.type) {
                case "OCSP_SERVICE_DEFAULT_URL_CHECKBOX":
                    return {
                        ...state,
                        ocsp_service_default_url_checkbox: action.ocsp_service_default_url_checkbox
                    };
                    break
                }
                return state;
            }


        const  UpdateEnforceUniquePublicKeyCheckbox = (state, action) => {
            switch (action.type) {
                case "ENFORCE_UNIQUE_PUBLIC_KEY_CHECKBOX":
                    return {
                        ...state,
                        enforce_unique_public_key_checkbox: action.enforce_unique_public_key_checkbox
                    };
                    break
                }
                return state;
            }


        const  UpdateDefaultCrlDistributionPointInput = (state, action) => {
            switch (action.type) {
                case "DEFAULT_CRL_DISTRIBUTION_POINT_INPUT":
                    return {
                        ...state,
                        default_crl_distribution_point_input: action.default_crl_distribution_point_input
                    };
                    break
                }
                return state;
            }


        const  UpdateOcspServiceDefaultUrlInput = (state, action) => {
            switch (action.type) {
                case "OCSP_SERVICE_DEFAULT_URL_INPUT":
                    return {
                        ...state,
                        ocsp_service_default_url_input: action.ocsp_service_default_url_input
                    };
                    break
                }
                return state;
            }


        const  UpdateCrlEditInput = (state, action) => {
            switch (action.type) {
                case "CRL_EDIT_INPUT":
                    return {
                        ...state,
                        crl_edit_input: action.crl_edit_input
                    };
                    break
                }
                return state;
            }

        
        const  UpdateCrlEditForm = (state, action) => {
            switch (action.type) {
                case "CRL_EDIT_FORM":
                    return {
                        ...state,
                        crl_edit_form: action.crl_edit_form
                    };
                    break
                }
                return state;
            }


        const  UpdateCrlCheckboxCrl = (state, action) => {
            switch (action.type) {
                case "CRL_CHECKBOX_CRL":
                    return {
                        ...state,
                        crl_checkbox_CRL: action.crl_checkbox_CRL
                    };
                    break
                }
                return state;
            }

 export default reducer   
 
