import React, { Component } from "react"

import { connect } from "react-redux"
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Select from 'react-select';

// Redux where we import state
const mapStateToProps = state => {
    return {
      stepNumber: state.stepNumber,
      keyAlgorithmWindow: state.keyAlgorithmWindow,
      keyAlgorithm: state.keyAlgorithm,
      signingAlgorithm: state.signingAlgorithm
    };
  };

// Redux where we dispatch state
const mapDispatchToProps = {
  updateCurrentWindow: infoWindow => {
    return { type: "INFO_ABOUT_WINDOW", keyAlgorithmWindow: infoWindow}
  },
  updateKeyAlgorithm: selectedKey => {
    return { type: "SET_KEY_ALGORITHM", keyAlgorithm: selectedKey }
  },
  updateSigningAlgorith: data => {
    return { type: "SIGNING_ALGORITHM", signingAlgorithm: data }
  }
}

const options = [
  { value: 'SHA256WithRSA', label: 'SHA256WithRSA' },
  { value: 'SHA384WithRSA', label: 'SHA384WithRSA' },
  { value: 'SHA512WithRSA', label: 'SHA512WithRSA' },
  { value: 'SHA256withECDSA', label: 'SHA256withECDSA' },
  { value: 'SHA384withECDSA', label: 'SHA384withECDSA' },
];

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Step3 extends Component {

    // This function pickup value and call mapDispatch redux metod
      handleKey = (e) => {
        let selectedKey = e.target.value
        this.props.updateKeyAlgorithm(selectedKey)
      }

      handleChange = (e) => {
        this.props.updateSigningAlgorith(e.value)
        if (e.value.includes("RSA")) {
            this.props.updateKeyAlgorithm("RSA2048")
        } else if (e.value === "SHA384withECDSA") {
          this.props.updateKeyAlgorithm("P-384")
        } else {
          this.props.updateKeyAlgorithm("P-256")
        }
      }
  

        render() {  
            const stepNumber = this.props.stepNumber
            if (stepNumber !== 3) { // Prop: The current step
                return null
            }
            const signingAlgorithm = this.props.signingAlgorithm
            const keyAlgorithmWindow = this.props.keyAlgorithmWindow
            const keyAlgorithm = this.props.keyAlgorithm
            
            return (
              <div>
                  <div>
                      <p className="text-2xl sm:text-3xl ml-1 sm:ml-2 px-2">Configure the certificate authority signing and key algorithm</p>
                      <hr className="ml-4"/>
                      <p className="text-base px-2 sm:text-xl text-gray-600 italic mt-3">Choose what asymetric encryption algorithm you want to use for signing your CA.</p>
                      <div className="w-2/4 m-auto mt-6">  
                        <Select
                          onChange={this.handleChange}
                          options={options}
                          defaultValue={{ value: 'SHA256WithRSA', label: 'SHA256WithRSA' }}
                        />
                      </div>  
                  </div>
                   {signingAlgorithm.includes("RSA")
                     ?
                      <div>
                        <p className="text-2xl sm:text-3xl ml-2 mt-8 mb-3 text-center md:text-left">Key Algorithm:{keyAlgorithmWindow}</p>                                
                        <div>
                          <p className="text-base sm:text-xl text-gray-600 italic mt-3 px-4 text-justify md:text-base">Choose the key algorithm for your CA.</p>
                          <p className="text-base my-3 px-4 text-justify md:text-base">RSA 2048 The 2048-bit RSA key algorithm is widely supported by browsers and other clients. The 2048-bit size provides a good balance between security and efficiency.</p>
                          <div className="flex ml-3 my-8 items-center">
                            <div className="w-1/4 mx-4 text-sm sm:text-2xl">
                              <label>
                                <input role="rsa2048" className="mr-2" type="radio" onChange={ (e) => {} } name="key" id="1" onChange={this.handleKey} value="RSA2048" checked={keyAlgorithm === "RSA2048"} />
                                    RSA 2048 
                              </label>
                            </div>
                            <p className="w-3/4 text-gray-700 text-sm sm:text-xl ml-4 text-left px-4 text-justify md:text-base"> The 2048-bit RSA key algorithm is widely supported by browsers and other clients. The 2048-bit size provides a good balance between security and efficiency.  </p>
                          </div> 
                          <div className="flex ml-3 mb-5 items-center">
                            <div className="w-1/4 mx-4 text-sm sm:text-2xl">
                              <label >
                                <input role="rsa4096" className="mr-2" type="radio" onChange={ (e) => {} } name="key" id="2" onChange={this.handleKey} value="RSA4096" checked={keyAlgorithm === "RSA4096"} />
                                    RSA 4096 
                              </label>
                            </div>
                            <p className="w-3/4 text-gray-700 text-sm sm:text-xl ml-4 text-left px-4 text-justify md:text-base"> RSA 4096 is less efficient than RSA 2048 and typically used only when required for specific applications. For example, some root CAs use RSA 4096. </p>
                          </div> 
                        </div>           
                      </div>
                  : 
                    <div className="mt-6">
                      <p className="text-sm sm:text-base text-gray-600 italic mt-3 px-4 text-justify md:text-base" >
                        If you select SHA256withECDSA signing algorith Private PKI app will use ECDSA P256 key algorithm. Or if you select SHA384withECDSA Private PKI app will use ECDSA P384 key algorithm.
                      </p>
                    </div>
                  }      
                </div>
            )
            }
        }
      
      )
      


      