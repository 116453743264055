import React, { Component } from "react"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

import image5 from "../../images/info.png"
import image6 from "../../images/noData.jpeg"
import CaTable from "./CaTable"
import Pagination from "../Pagination"
import Info from "../Info"
import {getHelperIsExpired, getHelpersExpiredData, getHelpersCalculateTags, getHelperDownloadClick} from '../../helpers.js'
var moment = require('moment');


// Redux where we import state
const mapStateToProps = state => {
    return {
      stepNumber: state.stepNumber,
      viewCaType: state.viewCaType,
      organization: state.organization,
      organizationUnit: state.organizationUnit,
      countryName: state.countryName,
      stateName: state.stateName,
      localityName: state.localityName,
      commonName: state.commonName,
      keyAlgorithm: state.keyAlgorithm,
      acmInfo: state.acmInfo,
      tagName: state.tagName,
      tagNameValue: state.tagNameValue,
      customCRLName: state.customCRLName,
      validFor: state.validFor,
      tab: state.tab,
      createdCA: state.createdCA,
      cas: state.cas,
      selectedCa: state.selectedCa,
      idFromSelectedCa: state.idFromSelectedCa,
      mainNavBar: state.mainNavBar,
      buttonWindowOnDashboardPage: state.buttonWindowOnDashboardPage,
      messageWindow: state.messageWindow,
      errorMessage: state.errorMessage,
      showErrorMessageWindow: state.showErrorMessageWindow,
      caSelectedTeam: state.caSelectedTeam,
      team: state.team,
      spinner: state.spinner,
      selectedNestedSubCa: state.selectedNestedSubCa,
      showNestedCa: state.showNestedCa,
      renderSubCaWindow: state.renderSubCaWindow,
      pathCa: state.pathCa,
      signingAlgorithm: state.signingAlgorithm,
      crl_edit_form: state.crl_edit_form,
      crl_edit_input: state.crl_edit_input,
      default_crl_distribution_point_input: state.default_crl_distribution_point_input,
      tagEditForm: state.tagEditForm, //show tag edit handler
      inputTags: state.inputTags, //array of tags
      tagName: state.tagName, //count of tags (because of the old database for multiple tags functionality)
      tagsBuf: state.tagsBuf,
      alertConfirm: state.alertConfirm,
      pagination: state.pagination,
      page: state.page,
      perPage: state.perPage
    };
  };

    
  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateCurrentTab: currentTab => {
      return { type: "UPDATE_TAB", tab: currentTab }
    },
    updateCas: data => {
      return { type: "UPDATE_CAS", cas: data }
    },
    updateSelectedCa: filltered_array => {
      return { type: "UPDATE_SELECTED_CA", selectedCa: filltered_array[0] }
    },
    updateIdFromSelectedCa: data => {
      return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: data }
    },
    updateMainNavBar: data => {
      return { type: "UPDATE_MAIN_NAV_BAR", mainNavBar: data }
    },
    updateButtonWindowOnDashboardPage: data => {
      return { type: "UPDATE_BUTTON_WINDOW_ON_DASHBOARD_PAGE", buttonWindowOnDashboardPage: data }
    },
    restartOrganization: data => {
      return { type: "SET_ORGANIZATION", organization: data }
    },
    restartOrganizationUnit: data => {
      return { type: "SET_ORGANIZATION_UNIT", organizationUnit: data }
    },
    restartStateName: data => {
      return { type: "SET_STATE_NAME", stateName: data }
    },
    restartLocalityName: data => {
      return { type: "SET_LOCALITY_NAME", localityName: data }
    },
    restartCommonName: data => {
      return { type: "SET_COMMON_NAME", commonName: data }
    },
    restartTagName: data => {
      return { type: "UPDATE_TAG_NAME", tagName: data }
    },
    restartTagNameValue: data => {
      return { type: "UPDATE_TAG_NAME_VALUE", tagNameValue: data }
    },
    restartCustomCrlName: data => {
      return { type: "UPDATE_CUSTOM_CRL_NAME", customCRLName: data }
    },
    restartDisableForm: disableForm => {
      return { type: "UPDATE_DISABLE_FORM", disableForm: disableForm }
    },
    restartViewCaType: caType => {
      return { type: "CA_TYPE", viewCaType: caType }
    },
    restartIdFromSelectedCa: id => {
      return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: id }
    },
    restartValidFor: validFor => {
      return { type: "UPDATE_VALID_FOR", validFor: validFor }
    },
    restartAcmInfo: acmInfo => {
      return { type: "UPDATE_ACM", acmInfo: acmInfo }
    },
    restartCountryName: countryName => {
      return { type: "SET_COUNTRY_NAME", countryName: countryName }
    },
    restartKeyAlgorith: keyAlgorithm => {
      return { type: "SET_KEY_ALGORITHM", keyAlgorithm: keyAlgorithm }
    },
    updateMessageWindow: hover => {
      return { type: "UPDATE_MESSAGE_WINDOW", messageWindow: hover }
    },
    restartCrlValidFor: days => {
      return { type: "UPDATE_CRL_VALID_FOR", crlValidFor: days }
    },
    restartCertDateDuration: total => {
      return { type: "UPDATE_CERT_DATE_DURATION", certDateDuration: total }
    },
    restartSelectedCa: filltered_array => {
      return { type: "UPDATE_SELECTED_CA", selectedCa: filltered_array }
    },
    updateCaSelectedTeam: data => {
      return {type: "CA_SELECTED_TEAM", caSelectedTeam: data}
    },
    updateTeam: data => {
      return {type: "TEAM", team: data}
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    },
    updateSelectedNestedSubCa: data => {
      return {type: "SELECTED_NESTED_SUB_CA", selectedNestedSubCa: data}
    },
    updateShowNestedCa: data => {
      return {type: "SHOW_NESTED_CA", showNestedCa: data}
    },
    updateRenderSubCaWindow: data => {
      return {type: "RENDER_SUB_CA_WINDOW", renderSubCaWindow: data}
    },
    updatePathCa: data => {
      return {type: "PATH_CA", pathCa: data}
    }, 
    cleanPathCa: data => {
      return {type: "CLEAN_PATH_CA", pathCa: data}
    },
    updateSubCaEEId: data => {
      return { type: "SUB_CA_EE_ID", subCaEEId: data}
    },
    updateCaType: data => {
      return { type: "CA_TYPE", viewCaType: data}
    },
    updateSigningAlgorith: data => {
      return { type: "SIGNING_ALGORITHM", signingAlgorithm: data }
    },
    updateCrlEditForm: data => {
      return { type: "CRL_EDIT_FORM", crl_edit_form: data}
    },
    updateCrlEditInput: data => {
      return { type: "CRL_EDIT_INPUT", crl_edit_input: data}
    },
    updateDefaultCrlDistributionPointInput: selectedValue => {
      return { type: "DEFAULT_CRL_DISTRIBUTION_POINT_INPUT", default_crl_distribution_point_input: selectedValue}
    },
    updateTagArray: (data) => { //needed to show the tag edit form with the tags array
      return { type: "UPDATE_TAG_ARRAY", inputTags: data}
    },
    updateTagNumResults: (data) => { //needed to update the tags amount
      return { type: "UPDATE_TAG_RESULTS_NUMBER", tagName: data}
    },
    updateTagsBuf: (data) => {
      return { type: "UPDATE_TAGS_BUF", tagsBuf: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    showAlert: (data) => {
      return { type: "UPDATE_ALERT", alertPopup: data }
    },
    updatePagination: pagination => {
      return { type: "UPDATE_PAGINATION", pagination: pagination}
    },
    changePage: page => {
        return { type: "UPDATE_PAGE_NUMBER", page: page }
    },
  }
  
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Dashboard extends Component {

  // This is Fetch method where we doing GET request with Rails Server 
  componentDidMount() {
    this.props.updateMainNavBar('privateCa')
    this.cleanStateFunction()
    this.detectNewCreated()
  }

  detectNewCreated = () => {
    let url_param = window.location.search
    let created_cert = false
    if (url_param.match(/\?cert=/i)) {
      created_cert = url_param.split('?cert=')
      if (created_cert.length === 2) {
        created_cert = created_cert[1]
      }
    }

    if (created_cert) {
        //search created ca:
        this.fetchTeams(created_cert)
    } else {
        this.fetchTeams(false) 
    }
  }

  fetchTeams = (to_search) => {
        //fetch teams:
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/teams", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
        }).then(res => res.json())
        .then(teams => {
            if (teams.error) {
              this.props.updateNotification({status: 'error', text: teams.message})
              this.props.changeSpinner(false)
            } else {
              if (typeof(teams) != 'undefined' && teams.length > 0) {
                this.props.updateCas(teams)
                this.props.updateTeam(teams[0].id)
                if (to_search) {
                  this.searchSubCa(to_search, false)
                } else {
                  this.fetchCas(teams[0].id, 1, false, 'rootca')
                }
              } else {
                this.props.changeSpinner(false)
              }
            }
        });     
  }

  fetchCas = (team_id, page, perPage=false, type, id=false) => {
    this.props.changeSpinner(true)
    let url = "/cas?q[ssl_account_id_eq]="+team_id+"&page="+page+"&q[ca_type_eq]="+type
    if (id) {
      url += '&q[signed_by_eq]='+id
    }

    if (!perPage) {
      perPage = this.props.perPage
    }
    url += '&per_page='+perPage
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrf
      },
    })
    .then(res => res.json())
    .then(date => {
        if (date.error) {
          this.props.updateNotification({status: 'error', text: date.message})
        } else {
          if (typeof(date.pagy) != 'undefined' && typeof(date.cas) != 'undefined') {
            if (id) {
              this.props.updatePagination(date.pagy)
              this.props.changePage(page)
              this.props.restartIdFromSelectedCa(id)
              this.props.updateSelectedNestedSubCa(date.cas)
              this.props.updateShowNestedCa(!this.props.showNestedCa)
              this.props.updateButtonWindowOnDashboardPage(false)
              this.props.history.push("/subca")
            } else {
              this.props.updateCaSelectedTeam(date.cas)
              this.props.updatePagination(date.pagy)
              this.props.changePage(page)
            }
          }
        }
        this.props.changeSpinner(false)
    })
  }

 // I invoke this function in componenDIdMount and this will restart redux state
  cleanStateFunction = () => {
    let data = ""
    let disableForm = false
    let caType = "ROOTCA"
    let id = "1"
    let validFor = "10950"
    let acmInfo = "Authorize"
    let countryName = "US"
    let keyAlgorithm = "RSA2048"
    let signingAlgo = "SHA256WithRSA"
    let crlValidFor = "7"
    let filltered_array = []
    this.props.restartOrganization(data)
    this.props.restartOrganizationUnit(data)
    this.props.restartStateName(data)
    this.props.restartLocalityName(data)
    this.props.restartCommonName(data)
    this.props.restartTagName(data)
    this.props.restartTagNameValue(data)
    this.props.restartCustomCrlName(data)
    this.props.restartDisableForm(disableForm)
    this.props.restartViewCaType(caType)
    this.props.restartIdFromSelectedCa(id)
    this.props.restartValidFor(validFor)
    this.props.restartAcmInfo(acmInfo)
    this.props.restartCountryName(countryName)
    this.props.restartKeyAlgorith(keyAlgorithm)
    this.props.restartCertDateDuration(data)
    this.props.restartCrlValidFor(crlValidFor)
    this.props.restartSelectedCa(filltered_array)
    this.props.cleanPathCa(data)
    this.props.updateButtonWindowOnDashboardPage(disableForm)
    this.props.updateSubCaEEId(data)
    this.props.updateSigningAlgorith(signingAlgo)
    this.props.updateCrlEditForm(disableForm)
    this.props.updateDefaultCrlDistributionPointInput(data)
    this.props.updateTagsBuf([])
  }

    handleRootButton = () => {
      this.props.updateIdFromSelectedCa("1")
      this.props.updateCaType("ROOTCA")
      this.props.history.push("/cas/new")
    }

    calculateTags = (selected_ca) => {
      let tags = getHelpersCalculateTags(selected_ca)
      this.props.updateTagArray(tags) //tags array
      this.props.updateTagNumResults(tags.length) //amount of tags
    }

    // This function handle radio button 
    handleRadioButton = (selected_ca) => {
        this.props.updateIdFromSelectedCa(selected_ca)
        let ca_array = this.props.caSelectedTeam
        let filtered_array = ca_array.filter(ca => ca.ca_api_id == selected_ca)

        this.props.updateButtonWindowOnDashboardPage(false)
        this.props.updateSelectedCa(filtered_array)
        this.calculateTags(filtered_array[0])
    }

    searchSubCa = (cert_id, created_ca_root_id=false) => {
      this.props.changeSpinner(true)
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      fetch("/cas/"+cert_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': csrf
        }
      }).then(res => {
        return res.json()
      }).then(res => {
        if (res.signed_by != 1) {
          //switch to subca:
          let root_id = res.signed_by
          
          fetch("/cas/"+root_id, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrf
            }
          }).then(res => {
            return res.json()
          }).then(res => {
            if (!created_ca_root_id) {
              created_ca_root_id = root_id
            } 
            this.props.updatePathCa(res)
            if (res.signed_by === 1) {
              let team = res.ssl_account_id;
              this.fetchCas(team, 1, false, 'subca', created_ca_root_id) //show CaTable with fetched results
            } else {
              this.searchSubCa(res.ca_api_id, created_ca_root_id) //recursion to fillup pathCa
            }
          })
        } else {
          //switch team:
          let team = res.ssl_account_id;
          this.props.updateTeam(team)
          this.fetchCas(team, 1, false, 'rootca')
        }
      })
    }

    handleSubCaClick = (id) => {
      let team = this.props.team
      let ca_array = this.props.caSelectedTeam
      let filtered = ca_array.find( ca => ca.ca_api_id === id)
      this.props.updatePathCa(filtered)
      this.fetchCas(team, 1, false, 'subca', id)
    }

    //This function pick up ca.ca_api_id and set up that in state and then redirect to form1 page
    handleIcon = () => {
        
        if (this.is_expired(this.props.selectedCa)) {
          this.props.updateNotification({status: 'notify', text: 'Selected certificate is expired'})
        } else {

        let data = this.props.idFromSelectedCa
        
        let ca_array = this.props.caSelectedTeam
        
        let filtered_array = ca_array.filter(ca => ca.ca_api_id == data)
        if (filtered_array[0].account_status === true) {
        this.props.updateCaType("SUBCA")
        this.props.history.replace("/cas/new");
        } else {
          this.props.updateNotification({status: 'notify', text: 'You\'re trying to issue subca from unactive root/sub ca. Please activate your ca first'})
        }}
    }

    // This function download certificate and if Ca is not selected it will show error message
    downloadTxtFile = () => {
      if (this.props.selectedCa.length === 0 ){
        this.props.updateNotification({status: 'notify', text: 'If you trying to download root/sub ca you will need to select your ca first in table bellow. Or if you don\'t have any ca please go on create ca first.'})        
      } else {

        let cert = this.props.selectedCa
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/cas/"+cert.ca_api_id, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': csrf
              }
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.error) {
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'error', text: res.message})
                } else {
                    getHelperDownloadClick(res, '_CA')
                    this.props.updateButtonWindowOnDashboardPage(false)
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'success', text: 'End Entity downloaded'})
                }
            })
      }
    }

    //This function handle option button 
    handleOptionButton = (event) => {
        event.preventDefault()
        let data = !this.props.buttonWindowOnDashboardPage
        this.props.updateButtonWindowOnDashboardPage(data)
    }

    //This function is handle onBlur and close dropdown
    handleDropdownsOut = (event) => {
      if (this.props.buttonWindowOnDashboardPage) {
        this.props.updateButtonWindowOnDashboardPage(false)
      }
    }

    //This function handling info handle icon and update state to show message on screen
    handleHover = () => {
        let hover = true
        this.props.updateMessageWindow(hover)
    }

    //This function handling info handle icon and update state to show message on screen
    handleHover1 = () => {
        let hover = false
        this.props.updateMessageWindow(hover)
    }

    handleTeam = (event) => {
      event.preventDefault()
      let team = event.target.value
      this.props.updateTeam(team)
      this.fetchCas(team, 1, false, 'rootca')
    }

    ///This function call backend and update (deactivate) CA status
    activateDeactivateStatus = (status) => {
      this.props.changeSpinner(true)
      let cert = this.props.selectedCa
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      fetch("/cas/"+cert.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'X-CSRF-Token': csrf
        },
        body: JSON.stringify(
          {
           "ca": { 
            account_status: status
           }
        })
      })
      .then(res => {
        if (res.status != 200) {
            //throw new TypeError("Error!")
            this.props.updateNotification({status: 'error', text: 'Certificate status change failed'})
            this.props.changeSpinner(false)
        }
        return res.json()
    })
      .then(res => {
        this.props.updateButtonWindowOnDashboardPage(!this.props.buttonWindowOnDashboardPage)
        if (res.error === false) {
          cert.account_status = status
          this.props.updateSelectedCa([cert])
          this.props.changeSpinner(false)
          this.props.updateNotification({status: 'success', text: 'Certificate status changed'})
        } else {
          this.props.updateNotification({status: 'error', text: 'Certificate status change failed'})
          this.props.changeSpinner(false)
        }
      }
      )
    }

    // This function show error on page is CA is not selected and also call activate/deactivate fetch function
    handleActivateOption = () => {
      if (this.props.selectedCa.length === 0 ){
          this.props.updateNotification({status: 'notify', text: 'If you trying to active/deactive root/sub ca you will need to select your ca first in table bellow. Or if you don\'t have any ca please go on create ca first'})
      } else {
          let status = this.props.selectedCa.account_status
          if ( status === true ) {
            this.activateDeactivateStatus(false)
          } else if (status === false) {
            this.activateDeactivateStatus(true)
          } else {
            this.props.updateNotification({status: 'notify', text: 'Selected CA is expired. Please create new CA'})
          }
      }
    }

    is_expired(ca) {
      let res = getHelperIsExpired(ca)
      return res
    }

    fetchCallback = (page, perPage) => {
      let team = this.props.team
      this.fetchCas(team, page, perPage, 'rootca')
    }

    render() { 
            const count = this.props.tagName
            const spinner = this.props.spinner
            const showErrorMessageWindow = this.props.showErrorMessageWindow
            const messageWindow = this.props.messageWindow
            const errorMessage = this.props.errorMessage
            const selectedCa = this.props.selectedCa
            const team = this.props.team
            const cas = this.props.cas 
            const buttonWindowOnDashboardPage = this.props.buttonWindowOnDashboardPage
            const caSelectedTeam = this.props.caSelectedTeam
            const pagination = this.props.pagination
            const page = this.props.page
            const perPage = this.props.perPage

            return (
              <div className=" bg-palette-2 h-screen" onClick={this.handleDropdownsOut}>
                <div className="flex"> 
                  {spinner === true //rendering spinner until get CA fetch is done
                  ?
                  <div className="justify-center items-center m-auto pt-20">
                    <div className="animate-spin rounded-full h-36 w-36 border-b-2 border-gray-900"></div>
                  </div>
                  :
                  <div className="pt-2 sm:px-2 lg:px-8 pb-4 sm:py-10 h-75 text-center sm:text-left bg-palette-2 w-full">
                    <div >
                      <div> 
                        <img role="show_info" className="w-8 h-8 flex float-right mr-4 " src={image5} alt="cert1" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover1}/>                     
                        <p className=" text-xl sm:text-3xl ml-1 sm:ml-2 font-sans text-gray-700">ROOT CA </p>
                      </div>
                    </div>
                    <hr className="ml-4 mb-4 sm:mb-8 mt-2 sm:mt-0"/>

                    { showErrorMessageWindow === false // This will show error on screen if is activated
                    ? <div></div>
                    : <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md border-palette-5" role="alert"> 
                        <div className="flex"> 
                            <div className="py-1"><svg className="fill-current h-8 w-8 text-palette-5 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                <div>
                                    <p className="font-bold text-palette-5">Info</p>
                                    <p className="text-sm">{errorMessage}</p>
                                </div>
                            </div>
                        </div>
                      }

                    { messageWindow === false // This will show hover message on screen if user hover info icon
                    ? <div></div>
                    : <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md border-palette-5" role="alert"> 
                        <div className="flex"> 
                            <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                <div>
                                    <p className="font-bold text-palette-5" >Info</p>
                                    <p className="text-sm">SSL Private CA—This service is for enterprise customers building a public key infrastructure (PKI) inside the Private PKI app and intended for private use within an organization. With SSL Private CA, you can create your own certificate authority (CA) hierarchy and issue certificates with it for authenticating users, computers, applications, services, servers, and other devices. Certificates issued by a private CA cannot be used on the internet. For more information, see the Private PKI guide.</p>
                                </div>
                            </div>
                        </div>
                      }

                    <div className="flex mx-2 pl-2 sm:pl-2 md:pl-2 lg:pl-8 md:px-2 lg:px-10">
                           
                          <div className="relative text-xs sm:text-sm flex w-full" > 
                            <div className="flex-col">
                            <button role="create_root_btn" onClick={this.handleRootButton} className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-palette-2 font-bold py-2 px-4 rounded my-4">
                                Create Root
                            </button>
                            </div>
                            
                            {selectedCa && selectedCa.length !== 0 && <div className="flex-col">
                              <button role="create_subca_btn" onClick={this.handleIcon} className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mx-4 mt-4">
                                  Create SubCA
                              </button>
                              <button role="options_btn" onClick={this.handleOptionButton} className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mt-4">
                                  Options
                              </button>
                            </div>}

                            <div className="hidden lg:block">
                            <select role="team_select" className="relative bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5  py-2 px-2 rounded m-4 mt-4 pr-8" value={team} onChange={this.handleTeam}>
                            {cas && cas.length > 0 && cas.map((teammap, i) => (
                            <option key={i} className="pr-6" value={teammap.id}>{teammap.acct_number}</option>
                            ))}
                            </select>
                            </div>
                            
                          </div>
               
                    </div>

                    <div className="block lg:hidden">
                        <select role="team_select" className="relative bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5  py-2 px-2 rounded m-4 mt-4 pr-8 w-11/12" value={team} onChange={this.handleTeam}>
                            {cas && cas.length > 0 && cas.map((teammap, i) => (
                            <option key={i} className="pr-6" value={teammap.id}>{teammap.acct_number}</option>
                            ))}
                        </select>
                    </div>
                    <div className="flex pl-24 sm:pl-0  md:px-2 lg:px-10">
                            {buttonWindowOnDashboardPage !== false && 
                            <div className="z-index dropdown-options bg-palette-4 ml-36 mt-14 rounded" >
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 text-center hover:bg-palette-5">
                                <button role="activate_btn" className={selectedCa.account_status === true ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === true} onMouseDown={this.handleActivateOption}  >Activate selected CA</button>
                              </div>}
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 text-center hover:bg-palette-5">  
                                <button role="deactivate_btn" className={selectedCa.account_status === false ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === false} onMouseDown={this.handleActivateOption} >Deactivate selected CA</button>
                              </div>}
                              <div className="bg-palette-4 text-center hover:bg-palette-5">  
                                <button role="issue_subca_btn" className="mx-3 my-1 text-white font-bold focus:outline-none" onMouseDown={this.handleIcon} >Issue SUBCA</button>
                              </div> 
                              <div className="bg-palette-4 text-center hover:bg-palette-5">  
                                <button role="download_options_btn" className="mx-3 my-1 text-white font-bold focus:outline-none" onMouseDown={this.downloadTxtFile} >Download selected CA</button>
                              </div>  
                            </div>}
                    </div>
                    {caSelectedTeam.length === 0
                    ? 
                    <div className="justify-center, items-center">
                      <img src={image6} className="h-2/5 w-3/5 m-auto "/> 
                      <p className="text-center my-4 mx-4 text-xl italic">You don't have any Ca/Sub Ca created. Please switch team or click on create CA button and create your first Certificate authority!</p>
                    </div>
                    :             

                     <div>
                     <div className="hidden lg:block table h-300 mt-4 sm:mt-10 w-full px-0 md:px-2 lg:px-10">
                            <div className="flex flex-col">
                            <div className="flex-grow overflow-auto">
                              <table className="relative w-full border">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tl">Selected</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center"></th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Subject DN</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Type</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tr">Status</th>
                                  </tr>
                                </thead>

                                <tbody className="divide-y ">
                                  {caSelectedTeam.map((ca, index) => ca.ca_type === "ROOTCA" &&
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>

                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                      <input role="select_ca" className="form-radio h-6 w-6 text-green-500 ml-2 my-5" type="radio" name="key" id="2" checked={(selectedCa && (selectedCa.ca_api_id == ca.ca_api_id)) ? 1 : 0} value={ca.ca_api_id} onClick={(e) => this.handleRadioButton(ca.ca_api_id)} onChange={ (e) => {} } />
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5"><button  className="px-5 py-2 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button></td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        {ca.subject_dn}
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">{ca.ca_type}</td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr>)}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>

                     <div className="visible lg:hidden w-full mt-2 mr-2 px-2">
                            <div className="flex">
                            <div className="flex-grow-2 overflow-auto overflow-x-hidden lg:overflow-x-auto">
                              <table className="relative w-full">
 
                                <tbody className="">
                                  {caSelectedTeam.map((ca, index) => ca.ca_type === "ROOTCA" &&
                                  <div className="mx-3 my-4 border-2 rounded-tl rounded-bl border-palette-5 mobile-row">
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Selected:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <input role="select_ca" className="form-radio h-5 w-5 text-green-500 my-1 mx-5" type="radio" name="key-mobile" id="2" checked={(selectedCa && (selectedCa.ca_api_id == ca.ca_api_id)) ? 1 : 0} value={ca.ca_api_id} onClick={(e) => this.handleRadioButton(ca.ca_api_id)} onChange={ (e) => {} } />
                                    </td>
                                  </tr>

                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Sub CA’s:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <button className="px-5 py-1 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button>
                                    </td>
                                  </tr>
                                  
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">SubjectDN:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        {ca.common_name && <span>CN={ca.common_name}</span>} 
                                        {ca.organization && <span>, O={ca.organization}</span>} 
                                        {ca.organization_unit && <span>, OU={ca.organization_unit}</span>}
                                    </td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>
                                  <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Type:</td>
                                  <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">{ca.ca_type}</td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleRadioButton(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Status:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle w-full">{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr></div>)}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>
 
                     <Pagination fetchCallback={this.fetchCallback} />

                     </div>

                    }
                      
                          
                    {caSelectedTeam && caSelectedTeam.length > 0 && selectedCa && selectedCa.length !== 0 && <Info />}
                    </div>
                  }
                    
                </div>
                
              </div>           
               
            )
              }
        }
      
      )
