import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaEdit } from "react-icons/fa";
import { RiFileCopy2Line } from "react-icons/ri";
import { Link } from "react-router-dom"
import { FiMoreVertical } from "react-icons/fi";
import image from "../../../images/gif.gif"
import DownloadPfxModal from "./DownloadPfxModal"

var moment = require('moment');
const ECKey = require('ec-key');
//const forge = require('node-forge')

const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        selectedCaObject: state.selectedCaObject,
        domainName: state.domainName,
        certDateDuration: state.certDateDuration,
        certDayDuration: state.certDayDuration,
        eePublicKey: state.eePublicKey,
        modalWindow: state.modalWindow,
        csrCertificate: state.csrCertificate,
        chainCertificate: state.chainCertificate,
        privateKey: state.privateKey,
        subjectDN: state.subjectDN,
        downloadDropdown: state.downloadDropdown,
        loadingScreen: state.loadingScreen,
        passwordModal: state.passwordModal,
        pfxPassword: state.pfxPassword,
        importedCSR: state.importedCSR,
        importedPubKey: state.importedPubKey,
        eeOrganization: state.eeOrganization,
        eeCountry: state.eeCountry,
        eeLocality: state.eeLocality,
        eeState: state.eeState,
        csrError: state.csrError,
        caFormType: state.caFormType,
        eePostalCode: state.eePostalCode,
        eeUnit: state.eeUnit,
        eeEmail: state.eeEmail,
        eeStreet: state.eeStreet,
        eeCertProfile: state.eeCertProfile,
        eeEndEntityProfile: state.eeEndEntityProfile,
        subCaEEId: state.subCaEEId,
        keyAlgorithm: state.keyAlgorithm,
        signingAlgorithm: state.signingAlgorithm,
        spinnerModal: state.spinnerModal,
        keyHashLength: state.keyHashLength,
        p7b: state.p7b
    };
}

const mapDispatchToProps = {
    updateEePublicKey: key => {
        return { type: "UPDATE_EE_PUBLIC_KEY", eePublicKey: key}
    },
    updateModalWindow: window => {
        return { type: "UPDATE_MODAL_WINDOW", modalWindow: window}
    },
    updateChainCertificate: cert => {
        return { type: "CHAIN_CERTIFICATE", chainCertificate: cert}
    },
    updateCsrCertificate: csr => {
        return { type: "CSR_CERTIFICATE", csrCertificate: csr }
    },
    updatePrivateKey: privateKey => {
        return { type: "PRIVATE_KEY", privateKey: privateKey}
    },
    updateDownloadDropdown: state => {
        return { type: "DOWNLOAD_DROPDOWN", downloadDropdown: state}
    },
    updateLoadingScreen: data => {
        return { type: "UPDATE_LOADING_SCREEN", loadingScreen: data }
    },
    updatePasswordModal: data => {
        return { type: "PASSWORD_MODAL", passwordModal: data }
    },
    updatePfxPassword: data => {
        return { type: "PFX_PASSWORD", pfxPassword: data }
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateCurrentStepForm2: currentStep => {
        return { type: "CURRENT_COMPONENT_INFO_FORM_2", stepNumberForm2: currentStep}
    },
    SetKeyAlgorithm: algo => {
      return { type: "SET_KEY_ALGORITHM", keyAlgorithm: algo}  
    },
    SigningAlgorithm: algo => {
      return { type: "SIGNING_ALGORITHM", signingAlgorithm: algo}  
    },
    SetKeyHashLength: hash => {
      return { type: "SET_KEY_HASH", keyHashLength: hash}    
    },
    updateP7bData: file => {
        return {type: "UPDATE_P7B_DATA", p7b: file}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep6 extends Component {
    
    generateClick = async() => {
        await this.props.updateLoadingScreen(true)
        this.generateFunction()
    }
    //This function is generate random keypair and csr and also set that public key in state
     generateFunction = async() => {
        if (!this.props.importedPubKey) {
            let keylength = this.props.keyAlgorithm
            let keyHashLength = this.props.keyHashLength
            if (this.ifKey(keylength) === 'RSA') {
                // RSA keypair:
                window.crypto.subtle.generateKey(
                  {
                    name: "RSA-OAEP",
                    modulusLength: keylength,
                    publicExponent: new Uint8Array([1, 0, 1]),
                    hash: "SHA-"+keyHashLength
                  },
                  true,
                  ["encrypt", "decrypt"]
                ).then(keys => {
                    return this.extractRsaKeys(keys)
                }).then(keydata => {
                    this.extractRsaPemKeys(keydata)
                });
            } else {
                // ECSDA keypair:
                let randomKey = ECKey.createECKey('P-'+keylength);
                let private_key = randomKey.toString('pem')
                let public_key = randomKey.asPublicECKey()
                public_key = public_key.toString('pem')

                await this.props.updateEePublicKey(public_key)
                this.props.updatePrivateKey(private_key)
                this.handleFatch()
            }
        } else {
            this.handleFatch()
        }
           
    }

    extractRsaPemKeys = async(keydata) => {
        //private key:
        let exportedAsString = this.ab2str(keydata.private);
        let exportedAsBase64 = window.btoa(exportedAsString);
        let pemExported = `-----BEGIN PRIVATE KEY-----\n${exportedAsBase64}\n-----END PRIVATE KEY-----`;
        this.props.updatePrivateKey(pemExported)
                    
        //public key:
        exportedAsString = this.ab2str(keydata.public);
        exportedAsBase64 = window.btoa(exportedAsString);
        pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
        await this.props.updateEePublicKey(pemExported)
        this.handleFatch()
    }

    extractRsaKeys = async(keys) => {
        let privateKey = await window.crypto.subtle.exportKey("pkcs8",keys.privateKey);
        let publicKey = await window.crypto.subtle.exportKey("spki",keys.publicKey);
        let keydata = {
            private: privateKey,
            public: publicKey
        }
        return keydata
    }

    ab2str(buf) {
      return String.fromCharCode.apply(null, new Uint8Array(buf));
    }

    ifKey (keylength) {
        return keylength <= 512 ? 'EC' : 'RSA'
    }

    getSubjectDNdata = () => {
        let res = ''
        this.props.subjectDN ? res += 'CN='+this.props.subjectDN+', ' : null
        this.props.eeOrganization ? res += 'O='+this.props.eeOrganization+', ': null
        this.props.eeLocality ? res += 'L='+this.props.eeLocality+', ': null
        this.props.eeState ? res += 'ST='+this.props.eeState+', ': null
        this.props.eeCountry ? res += 'C='+this.props.eeCountry.value+', ': null
        //this.props.eeUnit ? res += 'OU='+this.props.eeUnit+', ' : null
        res = res.substring(0, res.length - 2)
        return res
    }

    //This is fetch call with rails backend for end entity certificate
    handleFatch = async() => {
        let cert_selected = this.props.selectedCaObject
        let certificateProfile = "RSA_SslServerProfile"
        const keylength = this.props.keyAlgorithm
        if (this.ifKey(keylength) === 'EC') {
            certificateProfile = "ECC_SslServerProfile"
        }
        let request_type = 'public_key'  
        let request_data = this.props.eePublicKey
        let keyalgo = this.ifKey(keylength) === 'EC' ? 'P'+this.props.keyAlgorithm : 'RSA'+this.props.keyAlgorithm
        let keybytes = this.props.keyAlgorithm
        if (this.props.importedCSR) {
            request_type = 'csr'
            request_data = this.props.importedCSR
            certificateProfile = this.props.importedPubKey // key type (certificate profile) here only in this case (on CSR import, detected on step1)
            keyalgo = ''
            keybytes = ''
        }
        
        let bodyJson = JSON.stringify(
              {
                  "end_entity": {
                  "common_name": this.props.subjectDN, //in this prop we have common_name data actually
                  "ca_name": cert_selected.common_name,
                  "certificate_profile": this.props.caFormType == 'tls' ? certificateProfile : 'ClientAuthenticationProfile',
                  "end_entity_profile": this.props.caFormType == 'tls' ? 'SslServerProfile' : 'ClientAuthenticationProfile',
                  "subject_alt_name": this.props.domainName, //, dNSName=www.something1.com
                  "duration": this.props.certDayDuration,
                  "request_type": request_type, //key or csr
                  "request_data": request_data, //public key or csr value
                  "organization": this.props.eeOrganization,
                  "locality": this.props.eeLocality,
                  "country": this.props.eeCountry.value,
                  "state": this.props.eeState,
                  "key_algorithm": keyalgo,
                  "key_algorithm_bytes": keybytes,
                  //"streetAddress": this.props.eeStreet,
                  //"postalCode": this.props.eePostalCode,
                  //"emailAddress": this.props.eeEmail,
                  //"OU": this.props.eeUnit
                  }
              })
        
        console.log(bodyJson)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        await fetch("/end_entities", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
          body: bodyJson
        }).then(res => {
            return res.json()
        })
          .then(res => {
            if (res.error) {
                this.props.updateNotification({status: 'error', text: res.message})
            } else {
                this.props.updateChainCertificate(res.certificate_chain)
                this.props.updateP7bData(res.p7b)
                this.props.updateNotification({status: 'success', text: 'Certificate has been created successfully'})
                this.props.updateModalWindow(true)
            }
          }).catch(error => {
              this.props.updateNotification({status: 'error', text: 'err'})
              alert(error)
          })

          this.props.updateLoadingScreen(false)
            
        //   history.push("/certificate_manager")
        //   window.location.reload();
      }


    handleBeforeModalDownload = () => {
        this.props.updatePasswordModal(true)
    }  
    

    handleDownloadOption = () => {
        let state = !this.props.downloadDropdown
        this.props.updateDownloadDropdown(state)
    }

    handleModalCancel = () => {
        this.props.updateModalWindow(false)
    }

    handleDownloadOption1 = () => {
        // var blob = new Blob([this.props.chainCertificate], {type: "text/plain"});
        // var url = window.URL.createObjectURL(blob);
        // var a = document.createElement("a");
        // a.href = url;
        // a.download = filename;
        // a.click();
        


        let cert = this.props.chainCertificate
        const element = document.createElement("a");
        const file = new Blob([cert], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = 'certificate_chain.txt';
        document.body.appendChild(element);
        element.click();
        this.props.updateDownloadDropdown(false)
    }

    handleDownloadOption2 = () => {       
        let cert = this.props.chainCertificate
        const element = document.createElement("a");
        let data = this.props.p7b
        const file = new Blob([data], {type: 'application/x-pkcs7-certificates'});
        element.href = URL.createObjectURL(file);
        let filename = this.props.subjectDN.replace(/ /g, '_')
        filename += '.p7b'
        element.download = filename;
        document.body.appendChild(element);
        element.click();
        this.props.updateDownloadDropdown(false)
    }

    copyCodeToClipboard = () => {
        let el = this.textArea1
        el.select()
        document.execCommand("copy")

      }

    copyCodeToClipboard1 = () => {
        let el = this.textArea
        el.select()
        document.execCommand("copy")
    }

    copyCodeToClipboard2 = () => {
        let el = this.textArea2
        el.select()
        document.execCommand("copy")
    }


    handleGoTo = (step) => {
        if (typeof(step) != 'undefined') {
            this.props.updateCurrentStepForm2(step)
        }
    }

    handleRadioKeyAlgorithm = (e) => {
        let key = parseInt(e.target.value)
        this.props.SetKeyAlgorithm(key)
    }

    handleRadioKeyHash = (e) => {
        let hash = parseInt(e.target.value)
        this.props.SetKeyHashLength(hash)
    }
    
    render() {

    if (this.props.stepNumberForm2 !== 5) {// Prop: The current step
        return null
    }

    const subCaEEId =this.props.subCaEEId
    const selectedCaObject = this.props.selectedCaObject
    const domainName = this.props.domainName;
    const certDayDuration = this.props.certDayDuration;
    const certDateDuration = this.props.certDateDuration;
    const keylength = this.props.keyAlgorithm;
    const keyHashLength = this.props.keyHashLength;
    const loadingScreen = this.props.loadingScreen
    const modalWindow = this.props.modalWindow
    const spinnerModal = this.props.spinnerModal
    const passwordModal = this.props.passwordModal 
    const privateKey = this.props.privateKey
    const chainCertificate = this.props.chainCertificate
    const ImportedCSR = this.props.importedCSR
    const downloadDropdown = this.props.downloadDropdown
    const subjectDN = this.props.subjectDN
    const eeOrganization = this.props.eeOrganization
    const eeCountry = this.props.eeCountry
    const caFormType = this.props.caFormType
    const eeState = this.props.eeState
    const eeLocality = this.props.eeLocality
    const importedCSR = this.props.importedCSR
    const importedPubKey = this.props.importedPubKey

    //const eeUnit = this.props.eeUnit
    //const eePostalCode = this.props.eePostalCode
    //const eeStreet = this.props.eeStreet
    //const eeEmail = this.props.eeEmail
    //const eeEndEntityProfile = this.props.eeEndEntityProfile
    //const eeCertProfile = this.props.eeCertProfile
        return (
            <div className="h-full">
                {loadingScreen === true
                ? 
                <div className="h-screen">
                <img className="m-auto" src={image} alt="edit"/>
                <p className="text-gray-600 italic text-xl w-full text-center mt-4">Generating certificate...</p>
                </div>
                :
                <div>
                {modalWindow === true
                ?   <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center mid-w-full min-h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  
                  <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
              
                  
                  <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                  
                  <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:w-5/6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-centar w-full">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                         
                          <svg className="h-6 w-6 text-palette-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                        </div>
                        {spinnerModal === true ?
                            <div className="justify-center items-center m-auto pt-20">
                                <div className="animate-spin rounded-full h-36 w-36 border-b-2 border-gray-900"></div>
                            </div>
                        :
                        <div>
                        {passwordModal === false
                        ?
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center items-center justify-center">
                          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            Download Private Key
                          </h3>
                          <p className="text-justify lg:text-base text-gray-600">
                            Your private key is generated on your own computer by your web browser. Private PKI app will never see or handle your private keys. Please copy and save your private key on your computer because if you close this window and you private key will not be saved and you cannot retrieve your private key.  
                          </p>
                          <div className="mt-2">
                            {privateKey && <div> 
                                <div>
                                    <label className="text-gray-600 font-light mr-12 lg:mr-40 text-palette-5">PRIVATE KEY</label>
                                    <button className="inline-flex items-center" onClick={() => this.copyCodeToClipboard1()}>
                                        <span className="ml-5">Copy</span>
                                        <RiFileCopy2Line/>
                                    </button>
                                </div>
                                <div className="mb-3 pt-0 select-all">
                                    <textarea defaultValue={privateKey} role="key_textarea" className="resize border rounded-md w-4/5 h-24" ref={(textarea) => this.textArea = textarea}></textarea>
                                </div> 
                            </div>}
                            <div> 
                                <div>
                                    <label className="text-gray-600 font-light mr-12 lg:mr-40 text-palette-5">CERTIFICATE CHAIN</label>
                                    <button className="inline-flex items-center" onClick={() => this.copyCodeToClipboard2()}>
                                        <span >Copy</span>
                                        <RiFileCopy2Line/>
                                    </button>
                                </div>
                                <div className="mb-3 pt-0">
                                    <textarea defaultValue={chainCertificate} role="chain_textarea" className="resize border rounded-md w-4/5 h-24" ref={(textarea2) => this.textArea2 = textarea2}></textarea>
                                </div>
                            </div>
                          </div>
                        </div>
                        : < DownloadPfxModal />
                        }
                        </div>}
                      </div>
                    </div>
                    {passwordModal === false
                        ?
                        <div className="bg-white flex flex-row items-centar justify-center my-16">
                            {!spinnerModal && <div className="flex-row ml-2">
                                {!importedCSR && <button role="pfx_download" onClick={this.handleBeforeModalDownload} type="button" className="px-2 bg-palette-4 hover:bg-palette-5 text-white px-1 py-1 text-lg">
                                    Download PFX
                                </button>}
                                {!importedCSR && <button role="dropdown" onClick={this.handleDownloadOption} type="button" className="relative relative px-2 bg-palette-4 hover:bg-palette-5 text-white px-1 py-1 text-lg mr-4">
                                    <i className="fa fa-chevron-down"></i>
                                </button>}
                                {downloadDropdown === false 
                                ? <div></div>
                                :
                                <div className="absolute  ml-32 border-2 border-gray-300">
                                <div className="hover:bg-palette-5">
                                    <button role="pem_download" onClick={this.handleDownloadOption1} className="px-2 py-1 focus:outline-none text-sm justify-center" >PEM</button>
                                </div>
                                <div className="hover:bg-palette-5">  
                                    <button role="p7b_download" onClick={this.handleDownloadOption2} className="px-2 py-1 focus:outline-none text-sm justify-center">p7b</button>
                                </div>
                                    
                                </div>
                                }
                            </div>}
                            <div className="">
                                {!spinnerModal && <Link to="/certificate_manager">
                                                <button role="close_button" type="button" className="px-2 bg-palette-4 hover:bg-palette-5 text-white px-1 py-1 text-lg" onClick={this.handleModalCancel}>
                                                    Close
                                                </button>
                                </Link>}
                            </div>
                        </div>
                    : <div></div>
                    }
                  </div>
                </div>
              </div>
                    :
                    <div></div>
                }
                <div>
                    <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Review and request</p>
                    <hr/>
                    <p className="text-base text-gray-600 italic mt-1">Review your choices.</p>
                </div> 

                    <table className="custom-table-view">
                    <tbody>
                    <tr className="header-tbl">
                        <td>Certificate Details</td>
                        <td>
                            <button value="1" onClick={(e) => this.handleGoTo(1)} className="ml-3 bg-transparent hover:bg-palette-5 text-palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm" >  
                                <FaEdit/>
                            </button> 
                        </td>
                    </tr>
                    {selectedCaObject && selectedCaObject.common_name && <tr><td>CA:</td><td>{selectedCaObject.common_name}</td></tr>}
                    {subjectDN && <tr><td>Subject DN:</td><td>{this.getSubjectDNdata()}</td></tr>}
                    {subjectDN && <tr><td>Common Name:</td><td>{subjectDN}</td></tr>}
                    {eeOrganization && <tr><td>Organization:</td><td>{eeOrganization}</td></tr>}
                    {/*caFormType == 'oauth' && eeUnit && <tr><td>Organization Unit:</td><td>{eeUnit}</td></tr>*/}
                    {eeCountry && <tr><td>Country:</td><td>{eeCountry.value}</td></tr>}
                    {eeState && <tr><td>State:</td><td>{eeState}</td></tr>}
                    {/*caFormType == 'oauth' && eePostalCode && <tr><td>Postal Code:</td><td>{eePostalCode}</td></tr>*/}
                    {/*caFormType == 'oauth' && eeStreet && <tr><td>Street:</td><td>{eeStreet}</td></tr>*/}
                    {eeLocality && <tr><td>Locality:</td><td>{eeLocality}</td></tr>}
                    {/*caFormType == 'oauth' && eeEmail && <tr><td>E-mail:</td><td>{eeEmail}</td></tr>*/}
                    {/*caFormType == 'oauth' && eeCertProfile && <tr><td>Cert Profile:</td><td>{eeCertProfile}</td></tr>*/}
                    {/*caFormType == 'oauth' && eeEndEntityProfile && <tr><td>Cert End Entity Profile:</td><td>{eeEndEntityProfile}</td></tr>*/}

                    {caFormType != 'oauth' && <tr className="header-tbl">
                        <td>Domain names</td>
                        <td>
                            <button value="3" onClick={(e) => this.handleGoTo(3)} className="ml-3 bg-transparent hover:bg-palette-5 palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm"  >  
                                <FaEdit/>
                            </button>
                        </td>
                    </tr>}
                    
                    {domainName && <tr><td>Domain Name:</td><td>{domainName}</td></tr>}
                        
                    <tr className="header-tbl"><td>Certificate Duration</td><td>
                            <button value="4" onClick={(e) => this.handleGoTo(4)} className="ml-3 bg-transparent hover:bg-palette-5 palette-5 font-semibold hover:text-white py-1 px-2 border border-palette-5 hover:border-transparent rounded text-sm"  >  
                                <FaEdit/>
                            </button>
                        </td>
                    </tr>
                    
                    {certDateDuration && <tr><td>Expiration date:</td><td>{moment(certDateDuration).format('MM-DD-YYYY, h:mm A')}</td></tr>}
                    {certDayDuration && <tr><td>Certificate Duration in days:</td><td>{certDayDuration}</td></tr>}
                    {!importedPubKey && <tr><td>Key Algorithm:</td>
                        {keylength == 2048  && <td>RSA 2048</td>}
                        {keylength == 3072  && <td>RSA 3072</td>}
                        {keylength == 4096  && <td>RSA 4096</td>}
                        {keylength == 256 && <td>EC prime256v1</td>}
                        {keylength == 384 && <td>EC secp384r1</td>}
                        {keylength == 521 && <td>EC secp521r1</td>}
                    </tr>}
                    

                    <tr><td>Key Type:</td>
                        {!importedCSR ? <td>
                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="key_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 2048 ? 1 : 0} value="2048" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">RSA 2048</label>
                            </span>

                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="key_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 3072 ? 1 : 0} value="3072" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">RSA 3072</label>
                            </span>

                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="key_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 4096 ? 1 : 0} value="4096" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">RSA 4096</label>
                            </span>

                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="key_select" type="radio"  onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 256 ? 1 : 0} value="256" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">P-256</label>
                            </span>

                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="key_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 384 ? 1 : 0} value="384" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">P-384</label>
                            </span>
                            {/*<input type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={keylength == 521 ? 1 : 0} value="521" onClick={this.handleRadioKeyAlgorithm} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">P-521</label>*/}
                        </td> : <td>imported public key from CSR</td>}
                    </tr>

                    {!importedCSR && this.ifKey(keylength) === 'RSA' ?
                        <tr><td>Hash Algorithm:</td><td>
                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="hash_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={ keyHashLength == 256 ? 1 : 0} value="256" onClick={this.handleRadioKeyHash} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">SHA-256</label>
                            </span>
                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="hash_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={ keyHashLength == 384 ? 1 : 0} value="384" onClick={this.handleRadioKeyHash} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">SHA-384</label>
                            </span>
                            <span className="w-full lg:w-32 block block-float-left">
                            <input role="hash_select" type="radio" onChange={ (e) => {} } className="form-radio h-5 w-5 lg:h-6 lg:w-6 text-green-500 ml-2 my-5" checked={ keyHashLength == 512 ? 1 : 0} value="512" onClick={this.handleRadioKeyHash} />
                            <label className="ml-2 text-sm lg:text-base text-gray-600 italic mr-4 lg:mr-2">SHA-512</label>
                            </span>
                        </td></tr>
                        : null
                    }
                    </tbody>
                    </table>
                    
                <div className="text-center">
                    <button role="request_cert" className="transition duration-500 ease-in-out bg-palette-5 hover:palette-5 transform hover:-translate-y-1 hover:scale-110 bg-blue-500 text-white font-bold py-2 px-4 rounded mt-4" onClick={this.generateClick}>Confirm and request</button>
                </div> 
            </div>
            }
            </div>
        );
    }
}

)
