import React, { Component } from 'react';
import { connect } from 'react-redux';







 // Redux where we import state
const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        importCSRCheckBox: state.importCSRCheckBox,
        importedCSR: state.importedCSR,
        csrError: state.csrError
    };
}

  // Redux where we dispatch state
const mapDispatchToProps = {
    updateCertDayDuration: days => {
        return { type: "UPDATE_CERT_DAY_DURATION", certDayDuration: days }
    },
    updateImportCSRCheckBox: data => {
        return { type: "IMPORT_CSR_CHECKBOX", importCSRCheckBox: data }
    },
    updateImportedCSR: data => {
        return { type: "IMPORTED_CSR", importedCSR: data }
    },
    updateCsrError: data => {
        return { type: "CSR_ERROR", csrError: data }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep5 extends Component {

    handleCheckbox = () => {
        this.props.updateImportCSRCheckBox(!this.props.importCSRCheckBox)
    }
    
    handleTextArea = (e) => {
        this.props.updateImportedCSR(e.target.value)
    }

    


    render() {

        const stepNumberForm2 = this.props.stepNumberForm2
        if (stepNumberForm2 !== 10) {// Prop: The current step
            return null
        }

        const importCSRCheckBox = this.props.importCSRCheckBox
        const csrError = this.props.csrError

        return (
                <div>
                    <div>
                        <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">IMPORT CSR</p>
                        <hr className="ml-4"/>
                        <p className="text-base sm:text-xl text-gray-600 italic mt-3">IMPORT CSR</p>
                        <input type="checkbox" onChange={ (e) => {} } className="form-checkbox h-6 w-6 text-palette-5 my-5 ml-5" onClick={this.handleCheckbox} value={importCSRCheckBox} defaultChecked={importCSRCheckBox} />
                        <label className="ml-2 text-base sm:text-xl text-gray-600 italic">Import CSR</label>
                    </div>
                    {importCSRCheckBox === true
                    ?   
                    <div className="content-center">
                        <textarea onChange={this.handleTextArea} className="resize border rounded-md" placeholder="Paste your CSR here"/>
                        {csrError === false
                        ?
                        <div></div>
                        :
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                            <strong className="font-bold">Error!</strong>
                            <span className="block sm:inline"> Something is wrong with your CSR.</span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" onClick={() => this.props.updateCsrError(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                            </span>
                        </div>
                        }
                    </div>
                    :
                        <div></div>
                    }
                </div>
        );
    }
}

)