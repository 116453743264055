import React, { Component } from "react"

import { connect } from "react-redux"
import image5 from "../../images/info.png"
import image3 from "../../images/iconO.png"
import image4 from "../../images/iconX.png"
import { FaArrowRight } from "react-icons/fa"
import Step5 from "./Form1/Step5"
import Info from "../Info"
import Pagination from "../Pagination"
import image6 from "../../images/noData.jpeg"
import {getHelperIsExpired, getHelpersExpiredData, getHelpersPathCaClick, getHelpersCalculateTags, getHelperDownloadClick} from '../../helpers.js'
var moment = require('moment');


// Redux where we import state
const mapStateToProps = state => {
    return {
      caSelectedTeam: state.caSelectedTeam,
      selectedNestedSubCa: state.selectedNestedSubCa,
      showNestedCa: state.showNestedCa,
      selectedCa: state.selectedCa,
      buttonWindowOnDashboardPage: state.buttonWindowOnDashboardPage,
      idFromSelectedCa: state.idFromSelectedCa,
      tab: state.tab,
      messageWindow: state.messageWindow,
      errorMessage: state.errorMessage,
      showErrorMessageWindow: state.showErrorMessageWindow,
      pathCa: state.pathCa,
      subCaEEId: state.subCaEEId,
      tagEditForm: state.tagEditForm, //show tag edit handler
      inputTags: state.inputTags, //array of tags
      tagName: state.tagName, //count of tags (because of the old database for multiple tags functionality)
      tagsBuf: state.tagsBuf,
      checkboxes: state.checkboxes,
      crl_edit_form: state.crl_edit_form,
      crl_edit_input: state.crl_edit_input,
      default_crl_distribution_point_input: state.default_crl_distribution_point_input,
      spinner: state.spinner,
      alertConfirm: state.alertConfirm,
      pagination: state.pagination,
      page: state.page,
      perPage: state.perPage,
      team: state.team
    };
};

// Redux where we dispatch state
const mapDispatchToProps = {
    updateSelectedNestedSubCa: data => {
        return {type: "SELECTED_NESTED_SUB_CA", selectedNestedSubCa: data}
    },
    updateShowNestedCa: data => {
        return {type: "SHOW_NESTED_CA", showNestedCa: data}
    },
    updateIdFromSelectedCa: data => {
        return { type: "UPDATE_ID_FROM_SELECTED_CA", idFromSelectedCa: data }
    },
    updateSelectedCa: filltered_array => {
        return { type: "UPDATE_SELECTED_CA", selectedCa: filltered_array[0] }
    },
    updateCurrentTab: currentTab => {
        return { type: "UPDATE_TAB", tab: currentTab }
    },
    updateButtonWindowOnDashboardPage: data => {
        return { type: "UPDATE_BUTTON_WINDOW_ON_DASHBOARD_PAGE", buttonWindowOnDashboardPage: data }
    },
    updateMessageWindow: hover => {
        return { type: "UPDATE_MESSAGE_WINDOW", messageWindow: hover }
    },
    updatePathCa: data => {
      return {type: "PATH_CA", pathCa: data}
    },
    cleanPathCa: data => { 
      return {type: "CLEAN_PATH_CA", pathCa: data}
    },
    updateSubCaEEId: data => {
        return { type: "SUB_CA_EE_ID", subCaEEId: data}
    },
    updateCaType: data => {
      return { type: "CA_TYPE", viewCaType: data}
    },
    updateTagArray: (data) => { //needed to show the tag edit form with the tags array
      return { type: "UPDATE_TAG_ARRAY", inputTags: data}
    },
    updateTagNumResults: (data) => { //needed to update the tags amount
      return { type: "UPDATE_TAG_RESULTS_NUMBER", tagName: data}
    },
    updateTagsBuf: (data) => {
      return { type: "UPDATE_TAGS_BUF", tagsBuf: data}
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    updateCheckboxes: data => {
        return { type: "UPDATE_CHECKBOXES", checkboxes: data }
    },
    updateCrlEditForm: data => {
      return { type: "CRL_EDIT_FORM", crl_edit_form: data}
    },
    changeSpinner: data => {
      return {type: "SPINNER", spinner: data}
    },
    showAlert: (data) => {
      return { type: "UPDATE_ALERT", alertPopup: data }
    },
    updatePagination: pagination => {
      return { type: "UPDATE_PAGINATION", pagination: pagination}
    },
    changePage: page => {
        return { type: "UPDATE_PAGE_NUMBER", page: page }
    },
  }


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class CaTable extends Component {

    componentDidMount() {
        //let data = []
        //this.props.updateIdFromSelectedCa(data)
        let subCaArr = this.props.selectedNestedSubCa;
        if (subCaArr && typeof(subCaArr[0]) != 'undefined') {
            //this.props.updateIdFromSelectedCa(subCaArr[0].ca_api_id);
            this.props.updateSelectedCa([subCaArr[0]])
            this.props.updateCheckboxes([subCaArr[0].ca_api_id])
            this.calculateTags(subCaArr[0])
        }
        this.props.updateTagsBuf([])
        this.props.updateCrlEditForm(false)
    }

    fetchSubCas = (team_id, page, perPage=false, type, id) => {
        let request_page = page
        if (page === 'new') {
            request_page = 1
        }
        this.props.changeSpinner(true)
        let url = "/cas?q[ssl_account_id_eq]="+team_id+"&page="+request_page+"&q[ca_type_eq]="+type+'&q[signed_by_eq]='+id
        
        if (!perPage) {
            perPage = this.props.perPage
        }

        url += '&per_page='+perPage
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
        })
        .then(res => res.json())
        .then(date => {
            if (typeof(date.pagy) != 'undefined' && typeof(date.cas) != 'undefined') {
                this.props.updatePagination(date.pagy)
                this.props.changePage(page)
                this.props.updateSelectedNestedSubCa(date.cas)
                this.props.updateShowNestedCa(!this.props.showNestedCa)
                this.props.updateButtonWindowOnDashboardPage(false)
                if (page === 'new') {
                    if (date.cas.length > 0) {
                        this.props.updateSelectedCa([date.cas[0]])
                        this.props.updateCheckboxes([date.cas[0].ca_api_id])
                        this.calculateTags(date.cas[0])
                    } else {
                        this.props.updateSelectedCa([])
                        this.props.updateCheckboxes([])
                    }
                }
            }
            //this.props.history.push("/subca")
            this.props.changeSpinner(false)
        })
    }

    //This function handling info handle icon and update state to show message on screen
    handleHover = () => {
        let hover = true
        this.props.updateMessageWindow(hover)
    }

    downloadSelected = () => {
        this.handleDropdownsOut()
        let endEntityArray = this.props.selectedNestedSubCa
        let checkboxes = this.props.checkboxes;
        if (checkboxes.length == 0) {
            checkboxes = [this.props.selectedEndEntity[0].ca_api_id]
        }
        
        checkboxes.map ( (check) => {
            //let filteredEndEntity = endEntityArray.filter(ee => ee.ca_api_id == check) 
            this.downloadTxtFile(check)
        })
    }

    downloadIssuer = () => {
        const pathCa = this.props.pathCa
        let cert = pathCa[pathCa.length-1]
        let prefix = '_SubCA_ISSUER'
        if (pathCa.length === 1) {
            prefix = '_CA_ISSUER'
        }
        this.downloadTxtFile(cert.ca_api_id, prefix)
    }

    // This function download certificate and if Ca is not selected it will show error message


    downloadTxtFile = (cert_id, prefix=false) => {
      if (cert_id) {
        if (!prefix) {
            prefix = '_SubCA'
        }
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/cas/"+cert_id, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrf
              }
            })
            .then(res => {
                return res.json()
            })
            .then(res => {
                if (res.error) {
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'error', text: res.message})
                } else {
                    getHelperDownloadClick(res, prefix)
                    this.props.changeSpinner(false)
                    this.props.updateNotification({status: 'success', text: 'End Entity downloaded'})
                }
            })
      }
    }

    //This function handling info handle icon and update state to show message on screen
    handleHover1 = () => {
        let hover = false
        this.props.updateMessageWindow(hover)
    }

    handleBackButton = () => {
        this.props.history.replace("/");
    }

    handleEndEntityButton = (id) => {
        this.props.updateSubCaEEId(id)
        this.props.history.replace("/certificate_manager")
    }

    handlePathClick = (id ) => {
        let team = this.props.team
        let pathCa = this.props.pathCa
        let filtered = getHelpersPathCaClick(pathCa, id)
        this.props.cleanPathCa(filtered)
        this.fetchSubCas(team, 'new', false, 'subca', id) 
    }
  
    handleSubCaClick = (id) => {
        let team = this.props.team
        let ca_array = this.props.selectedNestedSubCa
        let filtered = ca_array.find( ca => ca.ca_api_id === id)
        this.props.updateIdFromSelectedCa(id)
        this.props.updatePathCa(filtered)
        this.fetchSubCas(team, 'new', false, 'subca', id)
    }


    handleCheckbox = (val) => {
        if (val) {
            
            let checkboxes = this.props.checkboxes
            let first_el = checkboxes[0]
            let checked = checkboxes.filter( ee => ee == val)
            if (checked.length > 0) {
                checked = true
            } else {
                checked = false
            }
            
            let filtered = checkboxes.filter( ee => ee != val)
            if (!checked) {
                filtered.push(val)
            }
            if (filtered.length == 0) {
                this.props.updateNotification({status: 'notify', text: 'At least one certificate should be selected'})
                filtered.push(first_el)
            }
            this.handleRadioButton(filtered[0])
            this.props.updateCheckboxes(filtered) 
            
        }
    }

    handleRadioButton = (selected_ca) => {
        let ca_array = this.props.selectedNestedSubCa
        let filtered_array = ca_array.filter(ca => ca.ca_api_id == selected_ca)
        this.props.updateSelectedCa(filtered_array)
        this.calculateTags(filtered_array[0])
    }

    calculateTags = (selected_ca) => {
        let tags = getHelpersCalculateTags(selected_ca)
        this.props.updateTagArray(tags) //tags array
        this.props.updateTagNumResults(tags.length) //amount of tags
    }

    handleEETopButton = () => {
        let id = this.props.selectedCa.id
        this.props.updateSubCaEEId(id)
        this.props.history.replace("/certificate_manager")
    }

     // This function pickup value and call mapDispatch redux metod  
    handleTabs = (e) => {
        e.preventDefault()
        let currentTab = e.target.value
        this.props.updateCurrentTab(currentTab)
    }

    //This function handle option button 
    handleOptionButton = (event) => {
        event.preventDefault()
        let data = !this.props.buttonWindowOnDashboardPage
        this.props.updateButtonWindowOnDashboardPage(data)
    }

    //This function is handle onBlur and close dropdown
    handleDropdownsOut = () => {
        if (this.props.buttonWindowOnDashboardPage) {
            this.props.updateButtonWindowOnDashboardPage(false)
        }
    }

    handleCreateSubca = () => { // new subca from the root ca
        let cert = this.props.selectedCa
        if (this.is_expired(cert)) {
            this.props.updateNotification({status: 'notify', text: 'SubCA is expired'})
        } else {
            if (cert.account_status === true) {
                this.props.updateIdFromSelectedCa(cert.ca_api_id)
                this.props.updateCaType("SUBCA")
                this.props.history.replace("/cas/new");
            } else {
                this.props.updateNotification({status: 'notify', text: 'You\'re trying to issue subca from unactive root/sub CA. Please activate your CA first'})
            }
        }        
    }
  
      activateDeactivateStatus = (cert, status) => {
        this.props.changeSpinner(true)
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        fetch("/cas/"+cert.id, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrf
          },
          body: JSON.stringify(
            {
             "ca": { 
               account_status: status
             }
          })
        })
        .then(res => {
          if (res.status != 200) {
              this.props.updateNotification({status: 'error', text: 'Certificate status change failed'})
              this.props.changeSpinner(false)
          }
          return res.json()
        })
        .then(res => {
            if (res.error) {
                this.props.updateNotification({status: 'error', text: res.message})
                this.props.changeSpinner(false)
            } else {
                this.props.updateButtonWindowOnDashboardPage(false)
                cert.account_status = status
                this.props.updateSelectedCa([cert])
                this.props.changeSpinner(false)
                this.props.updateNotification({status: 'success', text: 'Certificate status changed'})
            }
        }
        )
      }
  
      // This function show error on page is CA is not selected and also call activate/deactivate fetch function
      handleActivateOption = () => {
        if (this.props.selectedCa.length === 0 ){
            this.props.updateNotification({status: 'notify', text: 'If you trying to active/deactive root/sub ca you will need to select your ca first in table bellow. Or if you don\'t have any ca please go on create ca first.'})
        } else {
            let checkboxes = this.props.checkboxes
            let all_certs = this.props.selectedNestedSubCa
            checkboxes.map( (ca_id, index) => {
                let cert = all_certs.filter(ee => ee.ca_api_id === ca_id)
                let status = this.props.selectedCa.account_status
                if ( status === true ) {
                    this.activateDeactivateStatus(cert[0], false) // to deactivate
                } else if (status === false) {
                    this.activateDeactivateStatus(cert[0], true) // to activate
                } else {
                    this.props.updateNotification({status: 'notify', text: 'Selected CA is expired. Please create new CA'})
                }
            })
        }
      }

    is_expired(ca) {
        let res = getHelperIsExpired(ca)
        return res
    }

    fetchCallback = (page, perPage) => {
        let team = this.props.team
        let id = this.props.idFromSelectedCa
        this.fetchSubCas(team, page, perPage, 'subca', id)
    }
    
    render() {  
            const count = this.props.tagName
            const checkboxes = this.props.checkboxes
            const spinner = this.props.spinner
            const idFromSelectedCa = this.props.idFromSelectedCa
            const pathCa = this.props.pathCa
            const messageWindow = this.props.messageWindow
            const selectedCa = this.props.selectedCa
            const buttonWindowOnDashboardPage = this.props.buttonWindowOnDashboardPage
            const selectedNestedSubCa = this.props.selectedNestedSubCa
            const caSelectedTeam = this.props.caSelectedTeam
            const pagination = this.props.pagination
            const page = this.props.page
            let multiple = false;
            if (checkboxes.length > 1) {
                multiple = true;
            }

            console.log(pathCa)

            return (        
                <div className="pt-2 sm:px-2 lg:px-8 pb-4 sm:py-10 h-75 text-center sm:text-left bg-palette-2 w-full h-full" onClick={this.handleDropdownsOut}>
                        {spinner === true //rendering spinner until get CA fetch is done
                  ?
                  <div className="flex w-full"> 
                  <div className="justify-center items-center m-auto pt-20">
                    <div className="animate-spin rounded-full h-36 w-36 border-b-2 border-gray-900"></div>
                  </div>
                  </div>
                  :
                  <div>
                        <div>
                        <div className="flex w-full"> 
                                <div className="md:w-2/3">
                                <p className="float-left text-xl sm:text-3xl ml-1 sm:ml-2 font-sans text-gray-700 w-40 w-full mb-4 lg:mb-0 md:w-40 lg:w-40">Sub CA’s </p>
                                {idFromSelectedCa.length !== 0 && 
                                    <div className="bg-palette-4 hover:bg-palette-5 h-8 w-60 flex px-8 text-white rounded text-center w-full ml-6 mr-6 lg:w-64 md:w-64">
                                        <button className="w-full text-gray-700 text-sm mx-3 lg:px-2 my-1 font-bold focus:outline-none text-center md:text-left" onMouseDown={this.downloadIssuer} role="download_btn">Download Issuer CA</button>
                                    </div>}
                                </div>
                                <div className="md:w-1/3">
                                <img role="info-hover" className="w-8 h-8 flex float-right mr-4 " src={image5} alt="cert1" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover1}/>                     
                                </div>
                        </div>
                        </div>
                        <hr className="ml-4 mb-4 sm:mb-8 mt-2 sm:mt-0"/>
                            <div className="flex flex-row mb-4">
                            {pathCa && pathCa.length > 0 && pathCa.map((pathca, index) => (
                                <div key={index} className="flex mx-1 w-full">
                                    {index !== 0 && < FaArrowRight className="pt-2 mx-3"/>}
                                    <p className="cursor-pointer italic w-full text-center md:text-left md:px-8" onClick={() => this.handlePathClick(pathca.ca_api_id)}>{pathca.common_name}</p>
                                </div>
                            )
                            )}
                            </div>
                            {multiple && <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 my-6 mx-10 shadow-md border-palette-5" role="alert"> 
                                    <div className="flex"> 
                                        <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p className="font-bold text-palette-5" >Info</p>
                                                <p className="text-sm">select one checkbox only to see it's detailed information and use more actions for the selected certificate</p>
                                            </div>
                                        </div>
                            </div>}
                            { messageWindow !== false && // This will show hover message on screen if user hover info icon
                                
                                 <div className="bg-gray-300 border-t-4 rounded-b text-teal-900 px-4 py-3 shadow-md border-palette-5" role="alert"> 
                                    <div className="flex"> 
                                        <div className="py-1"><svg className="fill-current h-8 w-8 text-gray-700 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                            <div>
                                                <p className="font-bold text-palette-5" >Info</p>
                                                <p className="text-sm">SSL Private CA—This service is for enterprise customers building a public key infrastructure (PKI) inside the Private PKI app and intended for private use within an organization. With SSL Private CA, you can create your own certificate authority (CA) hierarchy and issue certificates with it for authenticating users, computers, applications, services, servers, and other devices. Certificates issued by a private CA cannot be used on the internet. For more information, see the Private PKI guide.</p>
                                            </div>
                                        </div>
                                  </div>
                            }
                        <div>
                            <div className="relative text-xs sm:text-sm flex w-full"> 
                                <div className='mb-6 ml-4'>
                                <button role="back_btn" className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mx-4 mt-4" onClick={this.handleBackButton}>Back</button>
                                
                            
                              {selectedCa && selectedCa.length !== 0 && !multiple && <button role="create_btn" onClick={this.handleCreateSubca} className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mx-4 mt-4">
                                  Create SubCA
                              </button>}
                              {selectedCa && selectedCa.length !== 0 && <button role="options_btn" onClick={this.handleOptionButton} className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mt-4 mx-4">
                                  Options
                              </button>}
                              <button  className=" transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-2 rounded mx-4 mt-4" onClick={this.handleEETopButton} role="end_entities_btn">
                                  Certificates
                              </button>
                            
                            
                            </div>
                            
                          </div>
                          <div className="flex w-full">
                            {buttonWindowOnDashboardPage !== false &&
                            <div className={!multiple ? "z-index dropdown-options dropdown-options-subca bg-palette-4 ml-60 mt-14 rounded" : "z-index dropdown-options dropdown-options-multiple bg-palette-4 ml-60 mt-14 rounded"}>
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 hover:bg-palette-5">
                                <button role="activate_btn" className={selectedCa.account_status === true ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === true} onMouseDown={this.handleActivateOption}  >Activate selected CA</button>
                              </div>}
                              {selectedCa && !this.is_expired(selectedCa) && <div className="bg-palette-4 hover:bg-palette-5">  
                                <button role="deactivate_btn" className={selectedCa.account_status === false ? "mx-3 my-1 text-gray-600 font-bold focus:outline-none cursor-not-allowed" : "mx-3 my-1 text-white font-bold focus:outline-none"} disabled={selectedCa.account_status === false} onMouseDown={this.handleActivateOption} >Deactivate selected CA</button>
                              </div>}
                              {!multiple && <div className="bg-palette-4 hover:bg-palette-5">  
                                <button role="create_subca_options_btn" className="mx-3 my-1 text-white font-bold focus:outline-none" onMouseDown={this.handleCreateSubca} >Create SubCa from this RootCa</button>
                              </div>}
                              <div className="bg-palette-4 hover:bg-palette-5">  
                                <button role="download_options_btn" className="mx-3 my-1 text-white font-bold focus:outline-none" onMouseDown={this.downloadSelected} >Download selected CA</button>
                              </div>  
                            </div>
                            }
                          </div>
                    {selectedNestedSubCa && selectedNestedSubCa.length === 0 ?
                    <div className="justify-center, items-center">
                      <img src={image6} className="h-2/5 w-3/5 m-auto "/> 
                      <p className="text-center my-4 mx-4 text-xl italic">This issuer CA doesn't have any Sub CA</p>
                    </div>
                    :
                    <div>
                     <div className=" hidden lg:block table h-300 mt-4 sm:mt-10 w-full px-0 md:px-2 lg:px-10">
                            <div className="flex flex-col">
                            <div className="flex-grow overflow-auto">
                              <table className="relative w-full border">
                                <thead>
                                  <tr>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tl">Selected</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center"></th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Subject DN</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center">Type</th>
                                    <th className="sticky top-0 px-6 py-3 text-white bg-palette-5 text-center rounded-tr">Status</th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y ">
                                  {selectedNestedSubCa && selectedNestedSubCa.length > 0 && selectedNestedSubCa.map((ca, index) => (
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/}>

                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                        <input className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ca.ca_api_id) ? 1 : 0}  value={ca.ca_api_id} onClick={(e) => this.handleCheckbox(ca.ca_api_id)} onChange={ (e) => {} } />
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5">
                                        <button className="px-5 py-2 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button>
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                        {ca.common_name && <span>CN={ca.common_name}</span>} 
                                        {ca.organization && <span>, O={ca.organization}</span>} 
                                        {ca.organization_unit && <span>, OU={ca.organization_unit}</span>}
                                    </td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>{ca.ca_type}</td>
                                    <td className="px-6 py-4 text-center text-gray-600 font-bold  text-center text-xs sm:text-base lg:text-lg border-b-2 align-middle border-palette-5" onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr>))}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>

                    <div className="visible lg:hidden w-full mt-2 mr-2 px-2">
                            <div className="flex">
                            <div className="flex-grow-2 overflow-auto overflow-x-hidden lg:overflow-x-auto">
                              <table className="relative w-full">
 
                                <tbody className="">
                                  {selectedNestedSubCa && selectedNestedSubCa.length > 0 && selectedNestedSubCa.map((ca, index) => (
                                  <div className="mx-3 my-4 border-2 rounded-tl rounded-bl border-palette-5 mobile-row">
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Selected:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <input className="form-radio h-6 w-6" type="checkbox" checked={checkboxes.includes(ca.ca_api_id) ? 1 : 0}  value={ca.ca_api_id} onClick={(e) => this.handleCheckbox(ca.ca_api_id)} onChange={ (e) => {} } />
                                    </td>
                                  </tr>

                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Sub CA’s:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                      <button className="px-5 py-1 border-blue-500 border text-blue-500 text-sm rounded transition duration-300 hover:bg-blue-700 hover:text-white focus:outline-none" onClick={() => this.handleSubCaClick(ca.ca_api_id)}>Sub CA’s</button>
                                    </td>
                                  </tr>
                                  
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">SubjectDN:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">
                                        {ca.subject_dn}
                                    </td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                  <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Type:</td>
                                  <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle border-b border-palette-3 w-full">{ca.ca_type}</td>
                                  </tr>
                                  <tr className={ca.account_status !== true ? "bg-gray-300" : ""} key={Math.random() /*because font awesome doesn't rerender icons without*/} onClick={(e) => this.handleCheckbox(ca.ca_api_id)}>
                                    <td className="px-2 py-1 h-5 text-white bg-palette-5 text-center">Status:</td>
                                    <td className="px-2 py-1 h-5 text-center text-gray-600 font-bold text-center text-xs align-middle w-full">{this.is_expired(ca) ? <p><i className="fa fa-clock text-blue-600"></i></p> : <p>{ca.account_status === true ? <i className="fa fa-circle-check text-lime-600"></i> : <i className="fa fa-circle-xmark text-red-600"></i>}</p>}</td>
                                  </tr></div>))}
                                </tbody>
                              </table>
                            </div>
                            </div>
                     </div>

                    <Pagination fetchCallback={this.fetchCallback} />
                    </div>}
                    { selectedNestedSubCa && selectedNestedSubCa.length > 0 && selectedCa && selectedCa.length !== 0 && !multiple && <Info/> }
                        </div>
                    </div>}
                    </div>
            )
            }
        })



    
