import React, { Component } from "react"

import { connect } from "react-redux"


  // Redux where we import state
  const mapStateToProps = state => {
      return {
        pagination: state.pagination,
        perPage: state.perPage,
        page: state.page
      };
    };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updatePerPage: perPage => {
      return { type: "UPDATE_PER_PAGE", perPage: perPage}
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(

    class Pagination extends Component {

      handleSelectPerPage = (perPage) => {
        this.props.updatePerPage(perPage)
        this.props.fetchCallback(1, perPage)
      }

      handlePageChange = (page) => {
        this.props.fetchCallback(page)
      }

      renderPageButtons() { //Render Pagination Buttons:
          const pagination = this.props.pagination
          const page = this.props.page
          let buttons = []
          if (page > 1) {
              buttons.push(<button role="page_change" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2" onClick={(e) => this.handlePageChange(pagination.prev)}>Previous</button>)                
              buttons.push(<button role="page_change" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2" onClick={(e) => this.handlePageChange(1)}>First</button>)
              for (let i = page-3; i< page; i++) {
                  if (i > 0) {
                      buttons.push(<button role="page_change" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2" onClick={(e) => this.handlePageChange(i)}>{i}</button>)
                  }
              }
          }
          buttons.push(<button role="page_change" className="float-left px-2 content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" onClick={(e) => this.handlePageChange(page)}>{page}</button>)
          if (page < pagination.last) {
              for (let i = page+1; i<= page+3; i++) {
                  if (i <= pagination.last) {
                      buttons.push(<button role="page_change" className={page === i ? "float-left px-2 content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button filter-selected" : "content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2"} onClick={(e) => this.handlePageChange(i)}>{i}</button>)
                  }
              }  
              buttons.push(<button role="page_change" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2" onClick={(e) => this.handlePageChange(pagination.last)}>Last</button>)
              buttons.push(<button role="page_change" className="content-center transition duration-500 ease-in-out bg-gray-500 hover:bg-gray-600 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 rounded mx-4 my-4 filter-button float-left px-2" onClick={(e) => this.handlePageChange(pagination.next)}>Next</button>)
          }

          return buttons                            
      }

      renderPerPage() {
        const perPage = this.props.perPage
        return (
                <span>
                <select className="bg-gray-200 appearance-none border-2 border-gray-200 rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5 px-2 mx-2 pr-8 text-sm" defaultValue={perPage} onChange={(e) => this.handleSelectPerPage(e.target.value)}>
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                </select>
                items per page
                </span>
                
        )
      }

      render() { 
              const pagination = this.props.pagination
              const page = this.props.page
             
              return(
                <div>
                {pagination && pagination.pages > 1 && <div className="pt-2 md:pt-4 px-4 md:px-10 mb-14 h-14 w-full"><div className="w-full w-12/12 float-left">{this.renderPageButtons()}</div><div className="w-full w-12/12 text-gray-600 text-sm text-left"><strong>Page {page} of {pagination.last}</strong>, {this.renderPerPage()}</div></div>}
                </div>
              )
            
      }
    }
      
  )