import React, { Component } from 'react';
import { connect } from 'react-redux';
var moment = require('moment');

// Redux where we import state
const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        cas: state.cas,
        selectedCaObject: state.selectedCaObject,
        dropdownEeTeam: state.dropdownEeTeam,
        arrayWithSubCas: state.arrayWithSubCas,
        subCaEEId: state.subCaEEId,
        caFormType: state.caFormType,
        eePostalCode: state.eePostalCode,
        eeUnit: state.eeUnit,
        eeEmail: state.eeEmail,
        eeStreet: state.eeStreet,
        eeCertProfile: state.eeCertProfile,
        eeEndEntityProfile: state.eeEndEntityProfile,
        csrKeyType: state.csrKeyType,
    };
}

// Redux where we dispatch state
const mapDispatchToProps = {
    updateSelectedCaObject: selectedObject => {
        return { type: "UPDATE_SELECTED_CA_OBJECT", selectedCaObject: selectedObject}
    },
    updateSubCaEEId: data => {
        return { type: "SUB_CA_EE_ID", subCaEEId: data}
    },
    updateCas: data => {
      return { type: "UPDATE_CAS", cas: data }
    },
    updateNotification: (data) => {
      return { type: "UPDATE_NOTIFICATION", notification: {...data, id: Date.now()} }
    },
    SetKeyAlgorithm: algo => {
      return { type: "SET_KEY_ALGORITHM", keyAlgorithm: algo}  
    },
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep2 extends Component {

    componentDidMount() {
        if (this.props.selectedCaObject === '' && this.props.arrayWithSubCas && this.props.arrayWithSubCas.length > 0) {
            let subca = this.props.arrayWithSubCas[0]
            this.props.updateSelectedCaObject(subca)
            this.props.updateSubCaEEId(subca.id)
            this.detectKey(subca)
        } else {
            let subca = this.props.selectedCaObject
            this.props.updateSubCaEEId(subca.id)
            this.detectKey(subca)
        }
    }

    detectKey(cert) {

        let keylength = 2048

        switch(cert.key_algorithm) {
            case 'P-256':
                keylength = 256
            break;

            case 'P-384':
                keylength = 384
            break;

            case 'RSA2048':
                keylength = 2048
            break;

            case 'RSA3072':
                keylength = 3072
            break;

            case 'RSA4096':
                keylength = 4096
            break;

            default:
                keylength = 2048
            break;
        }
        
        this.props.SetKeyAlgorithm(keylength)
    }

    
    //This function handle select dropdown
    handleSelectCa = (e) => {
        let selectedCa = e.target.value
        let selectedObject = this.props.arrayWithSubCas.filter(ca => ca.id == selectedCa) //filtering through the SubCA array to find selected CA
        this.props.updateSelectedCaObject(selectedObject[0]) //Updating state with selected CA
        this.props.updateSubCaEEId(selectedObject[0].id)
    }

     getExpired(cert) {
      if (typeof(cert) != 'undefined') {
      let today = new Date()
      let d1 = new Date(cert.created_at); // created at
      let d2 = new Date(d1.getTime() + cert.valid_for*3600*24*1000); //will be expired at
      
      
        let diff = d2.getTime() - today.getTime();      
        let daydiff = diff / (3600 * 24 * 1000);
        daydiff = parseInt(daydiff)
        let day = d2.getDate() 
        let month = d2.getMonth() + 1;
        let year = d2.getFullYear()
        let res = []
        res['days'] = daydiff
        res['expired_at'] = d2
        return res
      } else {
        return false
      }
    }


    render() {

        if (this.props.stepNumberForm2 !== 2) {// Prop: The current step
            return null
        }
        
        let selectedCaObject = this.props.selectedCaObject
        let expired = false

        if (selectedCaObject) {
            expired = this.getExpired(selectedCaObject)
        }
        
        const arrayWithSubCas = this.props.arrayWithSubCas
        const subCaEEId = this.props.subCaEEId
        const csrTypeError = this.props.csrTypeError

        return (
            <div className="">
                <div>
                    <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Select Issuing CA (ICA)</p>
                    <hr className="ml-4"/>
                    <p className="text-base sm:text-xl text-gray-600 italic mt-3">You can create your certificate by specificate by specifying a CA.</p>
                </div>
                <div>
                    <div className="md:flex md:items-center mb-6 mt-6">
                        <div className="md:w-1/3">
                            <label className="block font-bold md:text-right mb-1 md:mb-0 pr-4">Issuer:</label>
                        </div>  
                        <div className="md:w-2/3"> 
                            <select role="subca_select" defaultValue={subCaEEId} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" onChange={this.handleSelectCa}>
                            { arrayWithSubCas.map((ca, i) => (
                                <option key={i} value={ca.id}>{ca.common_name}</option>
                            ))}
                            </select>
                        </div> 
                    </div>
                    {csrTypeError && 

                        <div className="md:flex md:items-center mb-6">
                            <div className="w-full">
                                <div className="bg-gray-300 border-t-4 border-blue-500 rounded-b text-teal-900 px-4 py-3 shadow-md w-full" role="alert"> 
                                    <div className="flex"> 
                                    <div className="py-1"><svg className="fill-current h-8 w-8 text-blue-500 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                    <div>
                                    <p className="font-bold text-blue-500">Error</p>
                                        <p className="text-sm">This certificate has another key algorithm, than your exported public key from the CSR</p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {selectedCaObject && <div>
                        <table className="custom-table-view">
                        <tbody>
                            {selectedCaObject.ca_type && <tr><td>Type:</td><td>{selectedCaObject.ca_type}</td></tr>}  
                            {selectedCaObject.organization && <tr><td>Organization (O):</td><td> {selectedCaObject.organization}</td></tr>}
                            {selectedCaObject.organization_unit && <tr><td>Organization Unit (OU):</td><td> {selectedCaObject.organization_unit}</td></tr>}    
                            {selectedCaObject.country_name && <tr><td>Country name (C):</td><td> {selectedCaObject.country_name}</td></tr>}
                            {selectedCaObject.state_name && <tr><td>State or province:</td><td> {selectedCaObject.state_name}</td></tr>}
                            {selectedCaObject.locality_name && <tr><td>Locality name:</td><td> {selectedCaObject.locality_name}</td></tr>}
                            {selectedCaObject.common_name && <tr><td>Common name (CN):</td><td>{selectedCaObject.common_name}</td></tr>}
                            {selectedCaObject.created_at && <tr><td>Created:</td><td>{moment(selectedCaObject.created_at).format('MM-DD-YYYY, h:mm A')}</td></tr>}
                            {selectedCaObject.valid_for && <tr><td>Valid for:</td><td>{selectedCaObject.valid_for} days</td></tr>}
                            {selectedCaObject.key_algorithm && <tr><td>Key Algorithm:</td><td>{selectedCaObject.key_algorithm}</td></tr>}
                            {selectedCaObject.signature_algorithm && <tr><td>Signature Algorithm:</td><td>{selectedCaObject.signature_algorithm}</td></tr>}
                            {expired && <tr><td>Expired on:</td><td>{moment(expired['expired_at']).format('MM-DD-YYYY, h:mm A')}</td></tr>}                            
                        </tbody>
                        </table>
                      </div>}
                </div>  
            </div>        
        );
    }
}

)



