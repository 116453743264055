import React, { Component } from 'react';
import { connect } from 'react-redux';

 // Redux where we import state
const mapStateToProps = state => {
    return {
        stepNumberForm2: state.stepNumberForm2,
        domainValidation: state.domainValidation,
        domainName: state.domainName,
        domainValidationError: state.domainValidationError,
        inputDomains: state.inputDomains,
        maxDomains: state.maxDomains,
        domainBuf: state.domainBuf
    };
}

  // Redux where we dispatch state
const mapDispatchToProps = {
    updateValidDomain: domain => {
        return { type: "UPDATE_VALID_DOMAIN", domainValidation: domain}
    },
    updateDomainName: input => {
        return { type: "UPDATE_DOMAIN_NAME", domainName: input }
    },
    updateDomainBuf: input => {
        return { type: "UPDATE_DOMAIN_BUFFER", domainBuf: input }
    },
    updateDomains: input => {
        return { type: "UPDATE_DOMAIN_ARRAY", inputDomains: input }
    },
    updateNextStepAllowForm2: allow => {
        return { type: "ALLOW_NEXT_STEP_FORM2", allowNextStep: allow }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(
class CertStep3 extends Component {


    // this function check is domain name is in valid format and then set state
    handleDomainField = (e) => {
        
        let input = e.target.value
        let validation = this.props.validation(input)
        console.log(validation)
        
        this.props.updateValidDomain(validation)
        this.props.updateDomainBuf(input) 
        this.props.updateNextStepAllowForm2(validation)
        
    }

    addDomain = () => {
        let arr = this.props.inputDomains
        let chek = arr.filter(ee => ee == this.props.domainBuf);
        if (chek.length === 0 && this.props.domainBuf != '') {
            arr.push(this.props.domainBuf)
            this.props.updateDomains(arr)
            this.props.updateDomainBuf('') 
            this.updateResults(arr)
        }
    }

    removeDomain = (e) => {
        let index = e.target.value
        let arr = this.props.inputDomains
        let filtered = arr.filter(ee => ee != arr[index])
        this.props.updateDomains(filtered)
        this.updateResults(filtered)
    }

    updateResults = (arr) => {
        let result = ''
        let regexp = /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)$/ 
        for (let i=0; i < arr.length; i++) {
            if (regexp.test(arr[i])) {
                result = result + 'ipaddress=' + arr[i] + ','
            } else {
                result = result + 'dNSName=' + arr[i] + ','
            }
        }
        result = result.substring(0, result.length-1)
        this.props.updateDomainName(result)
    }


    render() {
        const stepNumberForm2 = this.props.stepNumberForm2
        if (stepNumberForm2 !== 3) {// Prop: The current step
            return null
        }
        const domainValidationError = this.props.domainValidationError
        const domainBuf = this.props.domainBuf
        const domainValidation = this.props.domainValidation
        const inputDomains = this.props.inputDomains
        const maxDomains = this.props.maxDomains
        return (
                <div className="mb-3 px-2">
                    <div>
                        <p className="text-2xl sm:text-3xl ml-1 sm:ml-2">Add domain names</p>
                        <hr className="ml-4"/>
                        <p className="text-base text-gray-600 italic mt-3 px-4 text-justify md:text-left">Type the fully qualified domain name of the site you want to secure with an SSL/TSL certificate (for example, www.example.com). Use an asterisk (*) to request a wildcard certificate to protect several sites in the same domain. For example: *.example.com protects www.example.com, site.example.com and images.example.com.</p>
                    </div> 
                    <div className="lg:flex lg:items-center mb-6 mt-12">
                        <div className="w-full px-2 lg:w-1/4">
                            <label className="block font-bold text-center lg:text-right mb-1 md:mb-0 pr-4 mb-6 lg:nb-0" >
                                Domain Name or IP
                            </label>
                        </div>
                        <div className="w-full px-2 lg:w-2/4 text-center lg:text-left">
                            
                            <input 
                                className={domainValidationError === true ? "bg-red-200 appearance-none border-2 border-red-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5" : "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-palette-5"} 
                                name="domain"
                                type="text"
                                placeholder="www.example.com"
                                value={domainBuf} 
                                onChange={this.handleDomainField} // Invoke handleStateName function onChange
                            />
                            
                        </div>
                        <div className="w-full px-2 lg:w-1/4 text-center lg:text-left">
                            {(domainValidation === 'ip' || domainValidation === 'domain') && inputDomains.length < maxDomains && <button className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-2 px-4 sm:px-4 rounded mx-4 my-4" onClick={this.addDomain} role="add_domain">Add</button>}
                        </div>
                    </div>
                    { domainValidation === "" //I'm showing alert that domain name cannot be empty
                    ?   <div className="bg-gray-300 border-t-4 border-palette-5 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert"> 
                            <div className="flex"> 
                                <div className="py-1"><svg className="fill-current h-8 w-8 text-palette-5 mr-4 md:ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                    <div>
                                        <p className="font-bold text-palette-5">Message</p>
                                        <p className="text-sm">At least one domain name is required!</p>
                                    </div>
                                </div>
                            </div>
                    : // if domain name is not empty then I'm doing one more if else
                    <div>  
                    { (domainValidation === 'ip' || domainValidation === 'domain') // if domain name pass validation then show empty div or show error msg
                    ? <div></div>
                    : <div className="bg-gray-300 border-t-4 border-blue-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert"> 
                        <div className="flex"> 
                            <div className="py-1"><svg className="fill-current h-8 w-8 text-blue-500 mr-4 ml-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                <div>
                                    <p className="font-bold text-blue-500">Error</p>
                                    <p className="text-sm">Domain name is in invalid form!</p>
                                </div>
                            </div>
                        </div>
                    } </div> 
                    }   

                    {inputDomains && inputDomains.length > 0 && <div className="px-2 md:flex md:items-center mb-6 mt-12">
                        <table className="custom-table-view">
                        <tbody>
                            {inputDomains.map( (el, index) => {
                               return (
                                <tr key={index}>
                                    <td>{el}</td>
                                    <td><button className="text-xs sm:text-sm transition duration-500 ease-in-out bg-palette-5 hover:bg-palette-5 transform hover:-translate-y-1 hover:scale-110 bg-gray-500 text-white font-bold py-1 px-1 sm:px-1 rounded mx-1 my-1" onClick={this.removeDomain} value={index}><i className="fa fa-trash"></i> Remove</button></td>
                                </tr>)
                            })}
                        </tbody>
                        </table>
                    </div>}


                </div>
        );
    }
}

)