import React, { Component } from "react"

import { connect } from "react-redux"


  // Redux where we import state
  const mapStateToProps = state => {
      return {
        notification: state.notification,
      };
    };

  // Redux where we dispatch state
  const mapDispatchToProps = {
    updateNotification: (data) => {
      if (data) {
        data = {...data, id: Date.now()}
      }
      return { type: "UPDATE_NOTIFICATION", notification: data }
    },
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(

    class Notify extends Component {

      componentDidUpdate() {
        let notification = this.props.notification
        if (notification) {
          if (notification.action == 'hide') {
            setTimeout(this.closeNotification, 3000)
          } else {
            setTimeout(this.closeNotification, 5000)
          }
        }
      }

      closeNotification = () => {
          let notification = this.props.notification
          if (notification && notification.action == 'hide') {
            this.props.updateNotification(false) // to cleanup states
          } else if (typeof(notification.text)!='undefined') {
            let hide = {action: 'hide', text: notification.text+'.', 'status': notification.status }
            this.props.updateNotification(hide) // to animate
          }
      }

      render() { 
              const notification = this.props.notification
              let css_main = 'fixed animate__animated animate__fadeIn animate__fast inset-x-0 top-0 flex flex-col items-end justify-right px-4 py-6 sm:p-6 justify-end z-index'
              let css = ''
              
              if (notification && notification.status) {
                switch (notification.status) {
                  case 'success':
                    css = 'flash-message max-w-sm mt-2 w-full shadow-lg rounded px-4 py-3 relative border-l-4 bg-gray-900 border-gray-500 text-gray-50 bg-red-600 border-red-900 bg-lime-600 border-lime-900 text-gray-50'
                  break;

                  case 'notify':
                    css = 'flash-message max-w-sm mt-2 w-full shadow-lg rounded px-4 py-3 relative border-l-4 bg-gray-900 border-gray-500 text-gray-50 bg-blue-600 border-blue-900 text-gray-50'
                  break;

                  case 'error':
                    css = 'flash-message max-w-sm mt-2 w-full shadow-lg rounded px-4 py-3 relative border-l-4 bg-gray-900 border-gray-500 text-gray-50 bg-red-600 border-red-900'
                  break;
                }

                if (typeof(notification.action) != 'undefined' && notification.action == 'hide') {
                  css_main = 'fixed animate__animated animate__fadeOutRight animate__slow inset-x-0 top-0 flex flex-col items-end justify-right px-4 py-6 sm:p-6 justify-end z-index'
                }
              }
              return(
                <div className="w-full">
                  
                      {notification && notification.status &&
                      <div className={css_main}>
                      <div className={css}>
                      <div className='p-2'>
                      <div className='flex items-start'>
                      <div className='flex flex-col flex-1 ml-3 w-0 pt-0.5'>
                      <div className='leading-5 font-medium uppercase'>
                      {notification.status}
                      </div>
                      <div className='mt-2 text-sm leading-5 font-light'>
                      {notification.text}
                      </div>
                      </div>
                      <div role="close" className='ml-4 flex-shrink-0 flex' onClick={this.closeNotification}>
                      <div className='close fa fa-fw fa-times-circle text-2xl inline-flex focus:outline-none transition ease-in-out duration-50 text-gray-50 cursor-pointer'></div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>}
                </div>
              )
            }
      }
      
  )